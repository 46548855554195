import { useState } from 'react';
import ContractCancellation from '../../../../../components/ContractCancellation/ContractCancellation';
import ContractFilesUpload from '../../../../../components/ContractFilesUpload/ContractFilesUpload';
import Text from '../../../../../components/Text/Text';
import { useTranslation } from 'react-i18next';
import ContractSignatures from '../../../../../components/ContractSignatures/ContractSignatures';
import { ContractSignature, ContractSignatureType } from '../../../../../generated';
import './CancelCreditApplication.scss';
import { formatDate } from '../../../../../utils/formatting.utils';

const CancelCreditApplication = () => {
  const { t } = useTranslation();

  const [signatures, setSignatures] = useState<ContractSignature[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const setSignaturesHandler = (date: Date | null, key: ContractSignatureType) => {
    const formattedDate = formatDate(date);

    setSignatures((prev) => {
      const existingSignature = prev.find((s) => s.type === key);

      if (existingSignature) {
        return prev.map((s) => (s.type === key ? { ...s, date: formattedDate } : s));
      } else {
        return [...prev, { type: key, date: formattedDate }];
      }
    });
  };

  return (
    <div className="credit-cancellation-wrapper">
      <Text as="h2" category="display">
        {t('admin.projectDetails.contracts.cancelCreditApplication')}
      </Text>
      <Text>{t('admin.projectDetails.contracts.deregisterLoanWithZekIko')}</Text>
      <div className="credit-cancellation">
        <ContractFilesUpload files={files} setFiles={setFiles} />
        <div>
          <Text as="h3" category="headline" size="large">
            {t(`admin.projectDetails.contracts.signatures`)}
          </Text>
          <ContractSignatures signatures={signatures} setSignature={setSignaturesHandler} />
        </div>
        <div>
          <Text as="h3" category="headline" size="large">
            {t(`admin.projectDetails.contracts.cancellationReason`)}
          </Text>
          <ContractCancellation isLoanCancellation />
        </div>
      </div>
    </div>
  );
};

export default CancelCreditApplication;
