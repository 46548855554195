import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useModal } from '../../hooks/useModal';
import useOnLeave from '../../hooks/useOnLeave';

import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Text from '../Text/Text';

import './PromptModal.scss';

interface PromptModalProps {
  ignoreRoutes?: string[];
  translationKey?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const PromptModal = ({
  ignoreRoutes,
  translationKey = 'leaveModal',
  onConfirm,
  onCancel
}: PromptModalProps) => {
  const { t } = useTranslation();
  const { isOpened, toggle } = useModal();
  const { blocker } = useOnLeave();

  useEffect(() => {
    if (blocker.proceed && ignoreRoutes?.some((r) => blocker.location?.pathname.includes(r))) {
      blocker.proceed();
      return;
    }
    if (blocker.state === 'blocked') toggle();
  }, [blocker]);

  const onLeavePromptHandler = () => {
    onConfirm && onConfirm();
    toggle();
    blocker.proceed && blocker.proceed();
  };

  const onCancelPromptHandler = () => {
    onCancel && onCancel();
    toggle();
    blocker.reset && blocker.reset();
  };

  return (
    <Modal title={t(`${translationKey}.title`)} isOpened={isOpened} close={onCancelPromptHandler}>
      <Text>{t(`${translationKey}.description`)}</Text>
      <div className={'leave-modal-buttons'}>
        <Button onClick={onCancelPromptHandler} size={'large'} variation={'secondary'} dataTest="">
          {t(`${translationKey}.cancel`)}
        </Button>
        <Button onClick={onLeavePromptHandler} size={'large'} variation={'primary'} dataTest="">
          {t(`${translationKey}.confirm`)}
        </Button>
      </div>
    </Modal>
  );
};

export default PromptModal;
