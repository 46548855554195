import TextInput from '../TextInput/TextInput';
import { phoneNumberValidation } from '../../../utils/validations.utils';
import { useTranslation } from 'react-i18next';

interface PhoneTextInputProps {
  value?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  onChange: (phoneNumber: string) => void;
}

const PhoneTextInput = ({
  value,
  required = true,
  className,
  disabled,
  onChange
}: PhoneTextInputProps) => {
  const { t } = useTranslation();

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    if (!value.includes('+') && !!value.length) {
      value = '+' + e.target.value;
    }
    onChange(value);
  };

  return (
    <TextInput
      required={required}
      name="phoneNumber"
      type="tel"
      placeholder={t('phoneNumber')}
      validate={phoneNumberValidation}
      value={value}
      onChange={handlePhoneNumberChange}
      className={className || ''}
      disabled={disabled}
    />
  );
};

export default PhoneTextInput;
