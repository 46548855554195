import './Snackbar.scss';
import Text from '../Text/Text';
import Icon from '../Icon/Icon';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SnackbarProps {
  type: 'info' | 'success' | 'error';
  text: string | ReactNode;
  heading?: string;
  className?: string;
  closeable?: boolean;
}

const Snackbar = ({ text, heading, type, className, closeable }: SnackbarProps) => {
  const { t } = useTranslation();
  const [closed, setClosed] = useState(false);

  const classNameProps = `snackbar-wrapper ${type} ${className ? className : ''} ${
    closed ? 'close' : ''
  }`;

  return (
    <div className={classNameProps}>
      <div className="snackbar-text-wrapper">
        <Icon name="info" />
        <div>
          {heading && (
            <Text as="h3" category="headline" size="small" variety={1}>
              {heading}
            </Text>
          )}
          <Text as="p" category="label" size="medium">
            {text}
          </Text>
        </div>
      </div>
      {closeable && (
        <div className="close-wrapper">
          <Text as="span" category="label" size="large">
            {t('close')}
          </Text>
          <Icon
            name="close"
            className="icon-close"
            onClick={() => {
              setClosed(true);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Snackbar;
