import { useTranslation } from 'react-i18next';

import Button from '../../../../../components/Button/Button';
import Price from '../../../../../components/Price/Price';
import TableHead from '../../../../../components/Tables/TableHead/TableHead';
import Text from '../../../../../components/Text/Text';

import {
  CreditOffer,
  CreditOfferTableData,
  CreditOfferTableDataName
} from '../../../../../store/fourEyeCheck/fourEyeCheck.types';
import { GetProjectById } from '../../../../../store/project/project.types';

import { format, formatDateWithDot } from '../../../../../utils/formatting.utils';
import { getValueOrPlaceholder } from '../../../../../utils/general.utils';

enum PersonalDetailsTableHeading {
  TITLE = 'title',
  DATA = 'data'
}

interface CreditOfferTableProps {
  creditOffer?: CreditOffer;
  projectDetails?: GetProjectById;
  toggle: () => void;
}
const CreditOfferTable = ({ creditOffer, projectDetails, toggle }: CreditOfferTableProps) => {
  const { t } = useTranslation();
  const tableHeadings = [...Object.values(PersonalDetailsTableHeading).map((elem) => elem)];

  const data: CreditOfferTableData[] = [
    {
      name: CreditOfferTableDataName.LOAN_AMOUNT,
      value: projectDetails?.project.loanAmount
    },
    {
      name: CreditOfferTableDataName.LOAN_TERM,
      value: projectDetails?.project.loanTerm
    },
    {
      name: CreditOfferTableDataName.MONTHLY_RATE,
      value: format(projectDetails?.projectDto.financingModel.monthlyRate)
    },
    {
      name: CreditOfferTableDataName.DECLINING_BALANCE,
      value: creditOffer?.decliningBalance
    },
    {
      name: CreditOfferTableDataName.DEPOSIT,
      value: projectDetails?.projectDto.financingModel.deposit
    },
    {
      name: CreditOfferTableDataName.INTEREST_RATE,
      value: projectDetails?.projectDto.financingModel.interestRate
    },
    {
      name: CreditOfferTableDataName.CONTRACT_START_DATE,
      value: formatDateWithDot(creditOffer?.contractStartDate)
    }
  ];

  const isMonetaryValue = (value: CreditOfferTableDataName) => {
    return (
      value === CreditOfferTableDataName.LOAN_AMOUNT ||
      value === CreditOfferTableDataName.DEPOSIT ||
      value === CreditOfferTableDataName.MONTHLY_RATE
    );
  };

  return (
    <div className="credit-offer-table-wrapper">
      <TableHead
        values={tableHeadings}
        columnWidths={[50, 50]}
        translationKey="admin.projectDetails.projectReview.creditOfferTable.table.heading">
        {data.map((elem, ind) => {
          const Comp = isMonetaryValue(elem.name) ? Price : Text;
          return (
            <tr key={ind}>
              <Text as="td" category="label" size="large">
                {t(`admin.projectDetails.projectReview.creditOfferTable.table.data.${elem.name}`)}
              </Text>
              <Comp as="td" category="label" size="large">
                {getValueOrPlaceholder(elem.value)}
              </Comp>
            </tr>
          );
        })}
      </TableHead>
      <Button variation="primary" size="large" dataTest="" onClick={toggle}>
        {t('close')}
      </Button>
    </div>
  );
};

export default CreditOfferTable;
