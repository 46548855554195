import { BuildingAddress } from '../types/sepMap.types';

export const format = (value?: number): string => {
  return !value && value !== 0
    ? ''
    : value.toLocaleString('de-CH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
};

export const toSwissFrancNotation = (amount: number | string, isCurrency = true): string => {
  const decimalSeparator = amount?.toString().includes(',') ? ',' : '.';
  const thosandsSeparator = decimalSeparator === ',' ? '.' : '';
  let amountStringCleaned = amount?.toString().replace(/[^0-9.,]/g, '');
  if (thosandsSeparator) amountStringCleaned = amountStringCleaned?.replace(/\./g, '');
  const amountAsNumber = +amountStringCleaned?.replace(decimalSeparator, '.');
  return isCurrency
    ? amountAsNumber?.toLocaleString('de-CH', {
        style: 'currency',
        currency: 'CHF',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    : amountAsNumber?.toLocaleString('de-CH', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
};

export const toSwissNumberFormat = (value: string) => {
  const numericValue = value.replace(/[^\d.,]/g, '');
  const normalizedValue = numericValue.replace(/,/g, '.');
  const decimalParts = normalizedValue.split('.');
  const integerPart = decimalParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'");

  if (decimalParts.length > 2) {
    return `${decimalParts[0]}.${decimalParts.slice(1).join('').substring(0, 2)}`;
  }

  if (decimalParts.length === 2) {
    decimalParts[1] = decimalParts[1].substring(0, 2);
    return `${integerPart}.${decimalParts[1]}`;
  }

  return integerPart;
};

export const formatAddress = (address?: BuildingAddress): string => {
  if (!address) return '';
  if (address.postalCode?.toString().length !== 4) return '';
  return `${address.street} ${address.houseNumber}, ${address.postalCode} ${address.municipality}`;
};

export const formatDate = (value?: Date | string | null): string => {
  try {
    if (!value) return '';
    const date = resetDateToMidnightUTC(new Date(value));

    return date.toISOString().slice(0, 10);
  } catch (err) {
    return '';
  }
};

export const resetDateToMidnightUTC = (date: Date) => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
};

export const formatUnixDate = (date: number) => formatDateWithTime(new Date(date * 1000));

export const formatDateWithDot = (value?: Date | string | null): string => {
  if (!value) return '';
  const date = value instanceof Date ? value : new Date(value);
  return date
    .toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
    .split('/')
    .join('.');
};

export const formatDateWithTime = (value?: Date | string): string => {
  if (!value) return '';
  const date = value instanceof Date ? value : new Date(value);
  const time = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });

  return `${formatDateWithDot(date)}, ${time}`;
};
