import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import Text from '../../../components/Text/Text';
import NumberInput from '../../../components/Inputs/NumberInput/NumberInput';
import Dropdown from '../../../components/Dropdown/Dropdown';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Radio from '../../../components/Inputs/Radio/Radio';

import {
  EmploymentStatus,
  QuestionnaireFormActionType,
  QuestionnaireFormProps,
  QuestionnaireType,
  TransportType
} from '../../../store/questionnaire/questionnaire.types';
import { RootState } from '../../../reducers/rootReducer';

import { changeHouseholdPartnerFinancialDetails } from '../../../store/questionnaire/questionnaire.actions';

import { getHighlightClass } from '../../../utils/questionnaire.utils';
import { validatePositiveNumberAllowEmpty } from '../../../utils/validations.utils';

import '../Questionnaire.scss';

const LifePartnerFinancialSituation = ({ isDataMissing }: QuestionnaireFormProps) => {
  const { t } = useTranslation();
  const questionnaire = useSelector<RootState>(
    (state) => state.questionnaireReducer
  ) as QuestionnaireType;
  const dispatch = useDispatch();

  const employmentStatus = questionnaire.creditCheckData.householdPartner?.employmentStatus ?? '';
  const financialData = questionnaire.creditCheckData.householdPartner?.financialData ?? null;

  const isTransportationCostRequired =
    financialData?.transportationType === TransportType.CAR ||
    financialData?.transportationType === TransportType.PUBLIC;

  const changeLifePartnerTransportTypeHandler = (value: string) => {
    dispatch({
      type: QuestionnaireFormActionType.CHANGE_PARTNER_TRANSPORT_TYPE,
      payload: value
    });

    if (
      value !== TransportType.CAR &&
      value !== TransportType.PUBLIC &&
      financialData?.monthlyTransportationCosts
    ) {
      dispatch({ type: QuestionnaireFormActionType.REMOVE_PARTNER_MONTHLY_TRANSPORTATION_COSTS });
    }
  };

  const handleHouseholdPartnerNumberOfMonthlyIncome = (
    field: 'numberOfMonthlyIncomeMain' | 'numberOfMonthlyIncomeAdditional'
  ) => {
    let value = financialData?.[field];

    value === 12 || !value ? (value = 13) : (value = 12);

    dispatch({
      type: QuestionnaireFormActionType.CHANGE_PARTNER_FINANCIAL_DETAILS,
      payload: { field, value }
    });
  };

  const handlePartnerAdditionalIncome = (income: number) => {
    if (income < 0) return;
    const payload = income ? [income] : [];

    dispatch({
      type: QuestionnaireFormActionType.CHANGE_PARTNER_MONTHLY_INCOME_ADDITONAL,
      payload: payload
    });
  };

  const isChurchTaxObligatory = () => financialData?.isChurchTaxObligatory ?? false;

  return (
    <form className="credit-check-form">
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.lifePartnerEmploymentStatus')}
        </Text>
        <Dropdown
          values={Object.values(EmploymentStatus).map((elem) => ({
            value: elem,
            displayValue: t(`creditCheck.employmentStatus.${elem}`)
          }))}
          initialValue={{
            value: employmentStatus,
            displayValue: t(`creditCheck.employmentStatus.${employmentStatus}`)
          }}
          noValueText={t('creditCheck.financialSituation.employmentStatus')}
          getValue={(value) => {
            dispatch({
              type: QuestionnaireFormActionType.CHANGE_PARTNER_EMPLOYMENT_STATUS,
              payload: value
            });
          }}
          required
          className={getHighlightClass(employmentStatus, isDataMissing)}
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.lifePartnerMonthlyIncomeGrossAmountMain')}
        </Text>
        <NumberInput
          value={financialData?.monthlyIncomeGrossAmountMain}
          name="monthlyIncomeGrossAmountMain"
          placeholder={`${t('currency.chf')} / ${t('month')}`}
          onChange={(amount) => {
            changeHouseholdPartnerFinancialDetails(
              amount,
              'monthlyIncomeGrossAmountMain',
              dispatch
            );
          }}
          validate={validatePositiveNumberAllowEmpty}
          required
          className={getHighlightClass(financialData?.monthlyIncomeGrossAmountMain, isDataMissing)}
        />
        <Checkbox
          checked={financialData?.numberOfMonthlyIncomeMain === 13}
          label={t('creditCheck.lifePartnerFinancialSituation.thirteenSalary')}
          onClick={() => {
            handleHouseholdPartnerNumberOfMonthlyIncome('numberOfMonthlyIncomeMain');
          }}
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.additionalIncome')}
        </Text>
        <Text as="span" category="body" size="large">
          {t('creditCheck.lifePartnerFinancialSituation.ammountOfAdditionalIncome')}
        </Text>
        <NumberInput
          value={financialData?.monthlyIncomeGrossAmountAdditional?.[0]}
          name="monthlyIncomeGrossAmountAdditional"
          placeholder={`${t('currency.chf')} / ${t('month')}`}
          onChange={handlePartnerAdditionalIncome}
        />
        <Checkbox
          checked={financialData?.numberOfMonthlyIncomeAdditional === 13}
          label={t('creditCheck.lifePartnerFinancialSituation.additionalIncomeThirteenSalary')}
          onClick={() => {
            handleHouseholdPartnerNumberOfMonthlyIncome('numberOfMonthlyIncomeAdditional');
          }}
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.workingDaysPerMonth')}
        </Text>
        <Text as="span" category="body" size="large">
          {t('creditCheck.financialSituation.workingDaysPerMonthText')}
        </Text>
        <NumberInput
          value={financialData?.workingDaysPerMonth}
          maxValue={31}
          name="workingDaysPerMonth"
          placeholder={`${t('days')} / ${t('month')}`}
          onChange={(days) => {
            changeHouseholdPartnerFinancialDetails(days, 'workingDaysPerMonth', dispatch);
          }}
          validate={validatePositiveNumberAllowEmpty}
          required
          className={getHighlightClass(financialData?.workingDaysPerMonth, isDataMissing)}
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.rentalCostMonthly')}
        </Text>
        <Text size="large">{t('creditCheck.financialSituation.rentalCostMonthlyExamples')}</Text>
        <NumberInput
          value={financialData?.monthlyHousingCost}
          name="monthlyHousingCost"
          placeholder={`${t('currency.chf')} / ${t('month')}`}
          onChange={(cost) => {
            changeHouseholdPartnerFinancialDetails(cost, 'monthlyHousingCost', dispatch);
          }}
          validate={validatePositiveNumberAllowEmpty}
          required
          className={getHighlightClass(financialData?.monthlyHousingCost, isDataMissing)}
        />
      </div>
      <div>
        <Text as="label" category="headline">
          {t('creditCheck.lifePartnerFinancialSituation.workTransportText')}
        </Text>
        <Dropdown
          values={Object.values(TransportType).map((elem) => ({
            value: elem,
            displayValue: t(`creditCheck.transportType.${elem}`)
          }))}
          initialValue={{
            value: financialData?.transportationType || '',
            displayValue: t(`creditCheck.transportType.${financialData?.transportationType}`) || ''
          }}
          noValueText={t('creditCheck.financialSituation.workTransport')}
          getValue={changeLifePartnerTransportTypeHandler}
          required
          className={getHighlightClass(financialData?.transportationType, isDataMissing)}
        />
        {isTransportationCostRequired && (
          <>
            <Text as="span" category="headline" size="medium">
              {t('creditCheck.lifePartnerFinancialSituation.monthlyTransportationCosts')}
            </Text>
            <NumberInput
              value={financialData?.monthlyTransportationCosts}
              name="monthlyTransportationCosts"
              placeholder={`${t('currency.chf')} / ${t('month')}`}
              onChange={(cost) => {
                changeHouseholdPartnerFinancialDetails(
                  cost,
                  'monthlyTransportationCosts',
                  dispatch
                );
              }}
              validate={validatePositiveNumberAllowEmpty}
              className={getHighlightClass(
                financialData?.monthlyTransportationCosts,
                isDataMissing
              )}
              required
            />
          </>
        )}
      </div>
      <div>
        <Text as="h3" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.isChurchTaxObligatory')}
        </Text>
        <div className="church-obligation-wrapper">
          <Radio
            value="yes"
            variation="secondary"
            checked={isChurchTaxObligatory()}
            label={t('yes')}
            onClick={() => {
              dispatch({
                type: QuestionnaireFormActionType.CHANGE_PARTNER_CHURCH_TAX_OBLIGATION,
                payload: true
              });
            }}
          />
          <Radio
            value="no"
            variation="secondary"
            checked={!isChurchTaxObligatory()}
            label={t('no')}
            onClick={() => {
              dispatch({
                type: QuestionnaireFormActionType.CHANGE_PARTNER_CHURCH_TAX_OBLIGATION,
                payload: false
              });
            }}
          />
        </div>
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.monthlyAlimony')}
        </Text>
        <NumberInput
          value={financialData?.monthlyExpensesAlimony}
          name="monthlyExpensesAlimony"
          placeholder={`${t('currency.chf')} / ${t('month')}`}
          onChange={(expense) => {
            changeHouseholdPartnerFinancialDetails(expense, 'monthlyExpensesAlimony', dispatch);
          }}
          validate={validatePositiveNumberAllowEmpty}
          required
          className={getHighlightClass(financialData?.monthlyExpensesAlimony, isDataMissing)}
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.monthlyEducationCosts')}
        </Text>
        <NumberInput
          value={financialData?.monthlyEducationCosts}
          name="monthlyEducationCosts"
          placeholder={`${t('currency.chf')} / ${t('month')}`}
          onChange={(cost) => {
            changeHouseholdPartnerFinancialDetails(cost, 'monthlyEducationCosts', dispatch);
          }}
          validate={validatePositiveNumberAllowEmpty}
          required
          className={getHighlightClass(financialData?.monthlyEducationCosts, isDataMissing)}
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.monthlyOtherFixExpenses')}
        </Text>
        <Text size="large">
          {t('creditCheck.financialSituation.monthlyOtherFixExpensesDescription')}
        </Text>
        <NumberInput
          value={financialData?.monthlyOtherFixExpenses}
          name="monthlyOtherFixExpenses"
          placeholder={`${t('currency.chf')} / ${t('month')}`}
          onChange={(expense) => {
            changeHouseholdPartnerFinancialDetails(expense, 'monthlyOtherFixExpenses', dispatch);
          }}
          required
          className={getHighlightClass(financialData?.monthlyOtherFixExpenses, isDataMissing)}
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.monthlyOneTimeExpenses')}
        </Text>
        <Text size="large">
          {t('creditCheck.lifePartnerFinancialSituation.majorExpensesPlanned')}
        </Text>
        <NumberInput
          value={financialData?.monthlyOneTimeExpenses}
          name="monthlyOneTimeExpenses"
          placeholder={t('currency.chf')}
          onChange={(expense) => {
            changeHouseholdPartnerFinancialDetails(expense, 'monthlyOneTimeExpenses', dispatch);
          }}
          validate={validatePositiveNumberAllowEmpty}
          required
          className={getHighlightClass(financialData?.monthlyOneTimeExpenses, isDataMissing)}
        />
      </div>
    </form>
  );
};
export default LifePartnerFinancialSituation;
