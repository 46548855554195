import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useNavigation from '../../../../hooks/useNavigation';

import Text from '../../../../components/Text/Text';
import Button from '../../../../components/Button/Button';
import ContractItem from '../../../../components/ContractItem/ContractItem';

import { getAllContracts } from '../../../../store/contracts/contract.actions';
import { ContractGet } from '../../../../generated';

import addIcon from '../../../../assets/Icons/add.svg';

import './Contracts.scss';

interface ContractsProps {
  projectUuid?: string;
}

const Contracts = ({ projectUuid }: ContractsProps) => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();

  const [contracts, setContracts] = useState<ContractGet[]>();

  useEffect(() => {
    fetchContracts();
  }, [projectUuid]);

  const fetchContracts = async () => {
    if (!projectUuid) return;
    const res = await getAllContracts(projectUuid);
    !!res.length && setContracts(res);
  };

  return (
    <div className="contracts-wrapper">
      <Text as="h3" category="headline" size="large">
        {t(`admin.projectDetails.contracts.title`)}
      </Text>
      {contracts && (
        <Text>
          {t(
            `admin.projectDetails.contracts.${!contracts?.length ? 'noContracts' : 'allContracts'}`
          )}
        </Text>
      )}
      <div className="contract-actions">
        <Button
          variation="primary"
          size="large"
          dataTest=""
          icon={addIcon}
          iconPosition="right"
          onClick={() => {
            goTo(`/admin/projects/${projectUuid}/contract`);
          }}>
          {t('admin.projectDetails.contracts.addContract')}
        </Button>
        <Button
          variation="secondary"
          size="large"
          dataTest=""
          icon={addIcon}
          iconPosition="right"
          onClick={() => {
            goTo(`/admin/projects/${projectUuid}/cancel-credit-application`);
          }}>
          {t('admin.projectDetails.contracts.cancelCreditApplication')}
        </Button>
      </div>
      {!!contracts?.length &&
        contracts.map((c) => <ContractItem key={c.id} id={c.id} status={c.status} />)}
    </div>
  );
};

export default Contracts;
