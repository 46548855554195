import { useTranslation } from 'react-i18next';
import Text from '../../../../../components/Text/Text';
import TableHead from '../../../../../components/Tables/TableHead/TableHead';
import './KremoTables.scss';
import Button from '../../../../../components/Button/Button';
import { KremoData } from '../../../../../store/fourEyeCheck/fourEyeCheck.types';
import { getValueOrPlaceholder } from '../../../../../utils/general.utils';

enum KremoTableHeading {
  APPLICANT_EXPENDITURES = 'applicantExpenditures',
  PARTNER_EXPENDITURES = 'partnerExpenditures'
}

interface KremoTablesProps {
  kremoData?: KremoData;
  toggle: () => void;
}

const KremoTables = ({ kremoData, toggle }: KremoTablesProps) => {
  const { t } = useTranslation();

  const kremoTableHeadings = [...Object.values(KremoTableHeading).map((elem) => elem)];

  const kremoTableData = [
    {
      name: 'taxAtSource',
      applicatantExp: kremoData?.generalExpenses.applicantExpenses.taxAtSource,
      partnerExp: kremoData?.generalExpenses?.partnerExpenses?.taxAtSource
    },
    {
      name: 'socialCharges',
      applicatantExp: kremoData?.generalExpenses.applicantExpenses.socialCharges,
      partnerExp: kremoData?.generalExpenses.partnerExpenses?.socialCharges
    },
    {
      name: 'externalFoodBoardingDeduction',
      applicatantExp: kremoData?.generalExpenses.applicantExpenses.externalFoodBoardingDeduction,
      partnerExp: kremoData?.generalExpenses.partnerExpenses?.externalFoodBoardingDeduction
    },
    {
      name: 'increasedFoodIntakeDeduction',
      applicatantExp: kremoData?.generalExpenses.applicantExpenses.increasedFoodIntakeDeduction,
      partnerExp: kremoData?.generalExpenses.partnerExpenses?.increasedFoodIntakeDeduction
    },
    {
      name: 'increasedClothingRequirementDeduction',
      applicatantExp:
        kremoData?.generalExpenses.applicantExpenses.increasedClothingRequirementDeduction,
      partnerExp: kremoData?.generalExpenses.partnerExpenses?.increasedClothingRequirementDeduction
    },
    {
      name: 'transportationCost',
      applicatantExp: kremoData?.generalExpenses.applicantExpenses.transportationCost,
      partnerExp: kremoData?.generalExpenses.partnerExpenses?.transportationCost
    },
    {
      name: 'openCreditAmount',
      applicatantExp: kremoData?.generalExpenses.applicantExpenses.openCreditAmount,
      partnerExp: kremoData?.generalExpenses.partnerExpenses?.openCreditAmount
    },
    {
      name: 'openLeasingRateAmount',
      applicatantExp: kremoData?.generalExpenses.applicantExpenses.openLeasingRateAmount,
      partnerExp: kremoData?.generalExpenses.partnerExpenses?.openLeasingRateAmount
    }
  ];

  const houseHoldExp = [
    {
      name: 'healthInsuranceFee',
      expense: kremoData?.kremoHouseholdExpenditures.healthInsuranceFee
    },
    {
      name: 'subsistenceMinimum',
      expense: kremoData?.kremoHouseholdExpenditures.subsistenceMinimum
    }
  ];

  return (
    <div className="kremo-table-wrapper">
      <Text as="p" category="body" size="medium">
        {t('admin.projectDetails.projectReview.kremoTables.description')}
      </Text>
      <TableHead
        values={kremoTableHeadings}
        columnWidths={[33, 33, 33]}
        translationKey="admin.projectDetails.projectReview.kremoTables.applicantTable.heading"
        emptyFirstCell>
        <>
          {kremoTableData.map((elem, ind) => (
            <tr key={ind}>
              <Text as="td" category="label" size="large">
                {t(
                  `admin.projectDetails.projectReview.kremoTables.applicantTable.data.${elem.name}`
                )}
              </Text>
              <Text as="td" category="label" size="large">
                {elem.applicatantExp}
              </Text>
              <Text as="td" category="label" size="large">
                {getValueOrPlaceholder(elem.partnerExp)}
              </Text>
            </tr>
          ))}
          {kremoData?.exemptAmountList?.map((elem, ind) => (
            <tr key={ind}>
              <Text as="td" category="label" size="large">
                {t(
                  `admin.projectDetails.projectReview.kremoTables.applicantTable.data.${elem.type}.${elem.isCorrected}`
                )}
              </Text>
              <Text as="td" category="label" size="large">
                {elem.amount}
              </Text>
              <Text as="td" category="label" size="large">
                {elem.type}
              </Text>
              <Text as="td" category="label" size="large">
                {elem.isCorrected}
              </Text>
            </tr>
          ))}
        </>
      </TableHead>
      <TableHead
        values={['houseHoldExpenditures']}
        columnWidths={[33]}
        translationKey="admin.projectDetails.projectReview.kremoTables.houseHoldTable.heading">
        {houseHoldExp.map((elem, ind) => (
          <tr key={ind}>
            <Text as="td" category="label" size="large">
              {t(`admin.projectDetails.projectReview.kremoTables.houseHoldTable.data.${elem.name}`)}
            </Text>
            <Text as="td" category="label" size="large">
              {elem.expense}
            </Text>
          </tr>
        ))}
      </TableHead>
      <Button variation="primary" size="large" dataTest="" onClick={toggle}>
        {t('close')}
      </Button>
    </div>
  );
};

export default KremoTables;
