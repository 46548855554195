import React, { FormEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import TextInput from '../Inputs/TextInput/TextInput';
import Text from '../Text/Text';
import { Profile, UpdateProfile } from '../../store/profile/profile.types';
import { DeepPartial, SetStateType } from '../../types/util.types';
import { getCityAndCanton, isAddressComplete } from '../../utils/general.utils';
import {
  emailValidation,
  nameValidation,
  phoneNumberValidation
} from '../../utils/validations.utils';
import KycStatusCard from '../KycStatusCard/KycStatusCard';
import { User, UserRoles } from '../../store/user/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { hubspotLinks } from '../../utils/hubspotLinks';
import AddressForm from '../AddressForm/AddressForm';
import { Address, Country } from '../../store/questionnaire/questionnaire.types';
import PhoneTextInput from '../Inputs/PhoneTextInput/PhoneTextInput';
import './PersonalDataForm.scss';

interface PersonalDataFormProps {
  data: DeepPartial<Profile>;
  setData: SetStateType<DeepPartial<Profile>>;
  onSubmit: (data: Partial<UpdateProfile>) => void;
}

const PersonalDataForm = ({ data, setData, onSubmit }: PersonalDataFormProps) => {
  const { t } = useTranslation();

  const user = useSelector<RootState>((state) => state.userReducer) as User;

  const [initialDisable, setInitialDisable] = useState(true);

  const { id, firstName, lastName, email, phoneNumber, address } = data.profile ?? {};

  const isUserRole = user.role?.includes(UserRoles.USER);

  const postalCode = address?.postalCode ?? '';

  const isDisabled =
    initialDisable ||
    (firstName && !!nameValidation(firstName)) ||
    (lastName && !!nameValidation(lastName)) ||
    (phoneNumber && !!phoneNumberValidation(phoneNumber)) ||
    (address && !isAddressComplete(address as Address)) ||
    (!!postalCode && postalCode.length !== 4);

  useEffect(() => {
    if (address && Object.keys(address).length === 0) {
      setData((prev) => {
        if (!prev.profile) return prev;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { address, ...rest } = prev.profile;
        return { ...prev, profile: rest };
      });
    }
  }, [address]);

  useEffect(() => {
    const fetchCityAndCanton = async () => {
      if (postalCode.length === 4) {
        const res = await getCityAndCanton(postalCode);
        res &&
          setData((prev) => ({
            ...prev,
            profile: {
              ...prev.profile,
              address: {
                ...prev.profile?.address,
                canton: res.canton,
                municipality: res.municipality,
                country: Country.SWITZERLAND
              }
            }
          }));

        if (postalCode === '') {
          setData((prev) => {
            if (!prev.profile?.address) {
              return prev;
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { canton, municipality, country, ...rest } = prev.profile.address;
            return {
              ...prev,
              profile: {
                ...prev.profile,
                address: {
                  ...rest
                }
              }
            };
          });
        }
      }
    };

    fetchCityAndCanton();
  }, [postalCode]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInitialDisable(false);
    const { value, name } = e.target;

    setData((prev) => ({
      ...prev,
      profile: {
        ...prev.profile,
        [name]: value
      }
    }));
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInitialDisable(false);
    const { value, name } = e.target;
    if (name === 'postalCode' && value.length > 4) return;

    if (!value) {
      setData((prev) => {
        const fieldName = name as keyof Address;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [fieldName]: removed, ...remainingAddress } = prev.profile?.address ?? {};
        return {
          ...prev,
          profile: {
            ...prev.profile,
            address: remainingAddress
          }
        };
      });
      return;
    }

    setData((prev) => ({
      ...prev,
      profile: {
        ...prev.profile,
        address: {
          ...prev.profile?.address,
          [name]: value
        }
      }
    }));
  };

  const showKycStatus =
    data?.profile?.kycStatus &&
    ((user.role?.includes(UserRoles.ADMIN) && data?.profile.userUuid !== user.uid) ||
      user.role?.includes(UserRoles.USER));

  const handlePhoneNumberChange = (value: string) => {
    setInitialDisable(false);

    setData((prev) => ({
      ...prev,
      profile: {
        ...prev.profile,
        phoneNumber: value ? value : undefined
      }
    }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (data) {
      onSubmit({
        firstName,
        lastName,
        phoneNumber,
        address: data.profile?.address as Address
      });
    }
  };

  return (
    <>
      <form className="personal-data-form" onSubmit={handleSubmit} noValidate>
        {isUserRole && (
          <Text>
            <Trans
              i18nKey={'myProfile.personalData.updateAccount'}
              t={t}
              components={{
                email: <a href={`mailto:${hubspotLinks.supportEmail}`} className="email-link" />
              }}
            />
          </Text>
        )}
        <TextInput
          type="text"
          name="customerNumber"
          value={id}
          placeholder={t('customerNumber')}
          disabled
        />
        <div className="user-name-wrapper">
          <TextInput
            name="firstName"
            type="text"
            placeholder={t('name')}
            value={firstName}
            onChange={handleNameChange}
            validate={(value) => nameValidation(value as string)}
            disabled={isUserRole}
          />
          <TextInput
            name="lastName"
            type="text"
            placeholder={t('lastName')}
            value={lastName}
            onChange={handleNameChange}
            validate={(value) => nameValidation(value as string)}
            disabled={isUserRole}
          />
        </div>
        <TextInput
          name="email"
          type="text"
          placeholder={t('email')}
          value={email}
          validate={emailValidation}
          disabled
        />
        <AddressForm
          address={address as Address}
          onChange={handleAddressChange}
          isDisabled={isUserRole}
        />
        <PhoneTextInput
          required
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          disabled={isUserRole}
        />
        {!isUserRole && (
          <Button type="submit" dataTest="update-profile" disabled={isDisabled}>
            {t('saveChanges')}
          </Button>
        )}
        {isUserRole && (
          <Text as="p" category="label" size="small" className="delete-account-text">
            <Trans
              i18nKey="myProfile.personalData.deleteAccount"
              t={t}
              components={{
                contactLink: (
                  <a
                    href={hubspotLinks.contact}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contact-link"
                  />
                )
              }}
            />
          </Text>
        )}
      </form>
      {showKycStatus && (
        <KycStatusCard status={data?.profile?.kycStatus} role={user.role as UserRoles[]} />
      )}
    </>
  );
};

export default PersonalDataForm;
