import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getProjects } from '../../../store/project/project.action';
import { ProjectsListItem } from '../../../store/project/project.types';
import Pagination from '../../../components/Pagination/Pagination';
import Text from '../../../components/Text/Text';
import ProjectsTable from './ProjectsTable/ProjectsTable';
import './ProjectsOverview.scss';
import SearchFilter from '../../../components/Inputs/SearchFilter/SearchFilter';
import { Sort, SortBy } from '../../../types/util.types';
import { AuthContext } from '../../../context/AuthContextProvider';

enum ProjectsTableHeading {
  PROJECT_ID = 'id',
  DATE = 'creationTime',
  OWNER = 'owner.firstName',
  PROJECT_ADDRESS = 'building.address.street',
  PARTNER = 'offer.partner.name',
  LAST_CHANGE = 'lastLogAuthor',
  STATUS = 'status'
}

const ProjectsOverview = () => {
  const auth = useContext(AuthContext);

  const { t } = useTranslation();

  const initialSort = Object.values(ProjectsTableHeading).map((elem: string) => {
    if (elem === ProjectsTableHeading.PROJECT_ID) {
      return { name: elem, sort: SortBy.ASCENDING };
    }
    return { name: elem, sort: undefined };
  });

  const [projects, setProjects] = useState<ProjectsListItem[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [sort, setSort] = useState<Sort[]>(initialSort);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    const fetchProjects = async () => {
      const res = await getProjects({
        keyword: search,
        page: currentPage - 1,
        size: 6,
        sort: sort.filter((elem: Sort) => elem.sort)
      });

      setProjects(res?.projects);
      setTotalPages(res?.totalPages);
      setLoading(false);
    };

    fetchProjects();
  }, [currentPage, search, auth?.isAuthenticated, sort]);

  const changeSearchHandler = (value: string) => {
    setSearch(value);
    setCurrentPage(1);
  };

  return (
    <div className={'projects-overview-wrapper'}>
      <Text as={'h1'} category={'display'}>
        {t('admin.projects.title')}
      </Text>
      <SearchFilter
        setValue={changeSearchHandler}
        placeholder={t('myProfile.myProjects.labels.search')}
      />
      {!loading && projects && projects.length > 0 && (
        <ProjectsTable projects={projects} setSortedColumns={setSort} sort={sort} />
      )}
      {!loading && projects && !projects.length && (
        <Text as="span" category="body" size="small">
          {t('notFound')}
        </Text>
      )}
      <Pagination page={currentPage} setPage={setCurrentPage} totalPages={totalPages} />
    </div>
  );
};

export default ProjectsOverview;
