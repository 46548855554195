import { useTranslation } from 'react-i18next';

import DatePicker from '../DatePicker/DatePicker';
import Text from '../Text/Text';
import { ContractSignature, ContractSignatureType } from '../../generated';

import './ContractSignatures.scss';
interface ContractSignaturesProps {
  signatures: ContractSignature[];
  requiredSignatures?: 'investerra' | 'customer' | 'both';
  setSignature: (date: Date | null, type: ContractSignatureType) => void;
}

const getSignatureDate = (signatures: ContractSignature[], type: ContractSignatureType) => {
  const sig = signatures.find((s) => s.type === type)?.date;
  return sig ? new Date(sig) : null;
};

const ContractSignatures = ({
  signatures,
  setSignature,
  requiredSignatures
}: ContractSignaturesProps) => {
  const { t } = useTranslation();

  const investerraSignature = getSignatureDate(signatures, ContractSignatureType.Investerra);
  const customerSignature = getSignatureDate(signatures, ContractSignatureType.Customer);

  const isSignatureRequired = (signature: string) =>
    signature === requiredSignatures || requiredSignatures === 'both' ? true : false;

  return (
    <div className="contract-signatures">
      <div className="signature-date">
        <Text>
          {t(`admin.projectDetails.contracts.investerraSignature`)}
          {isSignatureRequired(ContractSignatureType.Investerra) && '*'}
        </Text>
        <DatePicker
          selected={investerraSignature}
          setSelected={(date) => setSignature(date, ContractSignatureType.Investerra)}
        />
      </div>
      <div className="signature-date">
        <Text>
          {t(`admin.projectDetails.contracts.customerSignature`)}
          {isSignatureRequired(ContractSignatureType.Customer) && '*'}
        </Text>
        <DatePicker
          selected={customerSignature}
          setSelected={(date) => setSignature(date, ContractSignatureType.Customer)}
        />
      </div>
    </div>
  );
};

export default ContractSignatures;
