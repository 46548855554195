import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useNavigation from '../../../../../hooks/useNavigation';

import Button from '../../../../../components/Button/Button';
import DatePicker from '../../../../../components/DatePicker/DatePicker';
import Dropdown, { DropdownType } from '../../../../../components/Dropdown/Dropdown';
import TextArea from '../../../../../components/Inputs/TextArea/TextArea';
import Text from '../../../../../components/Text/Text';
import PromptModal from '../../../../../components/PromptModal/PromptModal';
import ContractSignatures from '../../../../../components/ContractSignatures/ContractSignatures';
import ContractFilesUpload from '../../../../../components/ContractFilesUpload/ContractFilesUpload';

import { ContractCreate, ContractSignatureType, ContractType } from '../../../../../generated';
import { createContractHandler } from '../../../../../store/contracts/contract.actions';
import { formatDate } from '../../../../../utils/formatting.utils';
import { addYears } from 'date-fns';

import './Contract.scss';

const Contract = () => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();
  const { projectUuid } = useParams();

  const [contract, setContract] = useState<ContractCreate>({
    type: ContractType.Loan,
    expirationDate: '',
    signatures: [],
    comment: ''
  });
  const [contractFiles, setContractFiles] = useState<File[]>([]);

  const contractTypes: DropdownType[] = Object.values(ContractType).map((value) => ({
    value: value,
    displayValue: t(`admin.projectDetails.contracts.${value}`)
  }));

  const expirationDate = contract.expirationDate ? new Date(contract.expirationDate) : null;

  const changeContractTypeHandler = (type: string) => {
    setContract((prev) => {
      return { ...prev, type: type as ContractType };
    });
  };

  const addCommentHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContract((prev) => ({ ...prev, comment: e.target.value }));
  };

  const changeExpirationDateHandler = (date: Date | null) => {
    setContract((prev) => ({
      ...prev,
      expirationDate: formatDate(date)
    }));
  };

  const onSubmit = async () => {
    if (!projectUuid) return;
    const createdContract = await createContractHandler(projectUuid, contractFiles, contract);

    createdContract && goTo(`/admin/contract/${createdContract.id}`, { state: 'CREATED' });
  };

  const setSignatureDateHandler = (date: Date | null, key: ContractSignatureType) => {
    const formattedDate = formatDate(date);

    setContract((prev) => {
      const existingSignature = prev.signatures.find((s) => s.type === key);

      if (existingSignature) {
        const updatedSignatures = prev.signatures.map((s) =>
          s.type === key ? { ...s, date: formattedDate } : s
        );
        return { ...prev, signatures: updatedSignatures };
      } else {
        return {
          ...prev,
          signatures: [...prev.signatures, { type: key, date: formattedDate }]
        };
      }
    });
  };

  return (
    <>
      <div className="contract-wrapper">
        <Text as="h2" category="display">
          {t(`admin.projectDetails.contracts.addContract`)}
        </Text>
        <div className="contract-data">
          <Text as="h3" category="headline" size="large">
            {t(`admin.projectDetails.contracts.addContract`)}
          </Text>
          <div className="contract-header">
            <div>
              <Text as="p">{t(`admin.projectDetails.contracts.contractType`)}*</Text>
              <Dropdown
                values={contractTypes}
                initialValue={{
                  value: contract.type,
                  displayValue: t(`admin.projectDetails.contracts.${contract.type}`)
                }}
                noValueText={t('selectStatus')}
                getValue={changeContractTypeHandler}
              />
            </div>
            <div>
              <Text as="p">{t(`admin.projectDetails.contracts.expirationDate`)}*</Text>
              <DatePicker
                selected={expirationDate}
                setSelected={changeExpirationDateHandler}
                maxDate={addYears(new Date(), 20)}
              />
            </div>
          </div>
          <ContractFilesUpload
            files={contractFiles}
            setFiles={setContractFiles}
            variation="simple"
          />
          <TextArea
            name="comment"
            placeholder={t(`admin.projectDetails.contracts.adminComment`)}
            value={contract?.comment}
            onChange={addCommentHandler}
          />
        </div>
        <Text as="h3" category="headline" size="large">
          {t(`admin.projectDetails.contracts.signatures`)}
        </Text>
        <ContractSignatures
          signatures={contract?.signatures}
          setSignature={setSignatureDateHandler}
          requiredSignatures="both"
        />
        <Button variation="primary" size="large" dataTest="" onClick={onSubmit}>
          {t('admin.projectDetails.contracts.addContract')}
        </Button>
      </div>
      <PromptModal
        ignoreRoutes={['contract']}
        translationKey="admin.projectDetails.contracts.dicardEntry"
      />
    </>
  );
};
export default Contract;
