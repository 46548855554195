import { ProjectLog } from '../project/project.types';
import {
  Address,
  Country,
  GenderType,
  HouseholdType,
  HousingReference,
  MaritalStatus
} from '../questionnaire/questionnaire.types';

enum IntrumStatus {
  START = 'START',
  ABORTED = 'ABORTED',
  CANCELED = 'CANCELED',
  REALTIME = 'REALTIME',
  CHECK_PENDING = 'CHECK_PENDING',
  SUCCESS = 'SUCCESS',
  SUCCESS_DATA_CHANGED = 'SUCCESS_DATA_CHANGED',
  FRAUD_SUSPICION_CONFIRMED = 'FRAUD_SUSPICION_CONFIRMED'
}

export enum FourEyeCheckStatus {
  STATE_CREATED = 'created',
  FOUR_EYE_CHECK_STARTED = 'four_eye_check_started',
  TO_BE_REVIEWED_BY_SUPER_ADMIN = 'toBeReviewedBySuperAdmin',
  REOPENED = 'reopened',
  APPROVED = 'approved',
  DECLINED = 'declined'
}

export enum ExemptAmountType {
  AFTER_TAX_AT_SOURCE = 'AFTER_TAX_AT_SOURCE',
  AFTER_CURRENT_LIABILITIES = 'AFTER_CURRENT_LIABILITIES',
  AFTER_APPLICATION_DATA = 'AFTER_APPLICATION_DATA'
}

export interface Get4EyeProjectDetails {
  customerUUID: string;
  currentState: FourEyeCheckStatus;
  kYCResultsData: KycresultsData;
  offerUrl: string;
  creditCheckData?: CreditCheckData;
}

export interface FourEyeProjectDetails {
  projectDetails?: Get4EyeProjectDetails;
  projectLogs?: ProjectLog[];
  fourEyeCheckId?: string;
  offerId?: number;
}

interface KycresultsData {
  result: IntrumStatus;
  personalDataKYC: PersonalDataKYC;
}

export interface PersonalDataKYC {
  address: Address;
  firstName: string;
  lastName: string;
  nationality: Country;
  gender?: GenderType;
}

export interface CreditCheckData {
  complianceCheckData?: ComplianceCheckData;
  creditOffer?: CreditOffer;
  experianData?: ExperianData;
  financialValuationData?: FinancialValuationData;
  kremoData?: KremoData;
  personalDataCreditCheckForKYC?: PersonalDataCreditCheck;
  zekData?: ZekData;
}

interface ComplianceCheckDataEntry {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  dateOfBirth: string;
  country: string;
  nameOfList: string;
}

export interface ComplianceCheckData {
  pepEntries: ComplianceCheckDataEntry[];
  slEntries: ComplianceCheckDataEntry[];
  wlblEntries: ComplianceCheckDataEntry[];
}

export interface CreditOffer {
  requestedAmount: number;
  requestedTerm: number;
  requestedMonthlyInstallment: number;
  decliningBalance: number;
  downPayment: number;
  interestRateNominal: number;
  contractStartDate: Date;
}

export interface ExperianData {
  systemDecision: string;
  intrumCreditScore: IntrumCreditScore;
  experianReasonCodes: string[];
}

interface ExemptAmount {
  amount: number;
  type: ExemptAmountType;
  isCorrected: boolean;
}

interface IntrumCreditScore {
  premiumConsumerScoreValue: number;
  premiumConsumerScoreHitType: string;
}

export interface FinancialValuationData {
  applicant: ApplicantCost;
  spouse?: SpouseCost;
}

interface ApplicantCost {
  monthlyTransportationCosts: number;
  workingDaysPerMonth: number;
  monthlyHousingCost: number;
  monthlyExpensesAlimony: number;
  monthlyEducationCosts: number;
  monthlyProfessionalOrganizationFee: number;
  monthlyOtherFixExpenses: number;
  monthlyOneTimeExpenses: number;
  totalMonthlyIncomeGrossAmount: number;
  monthlyIncomeGrossAmountMain: number;
  monthlyIncomeGrossAmountAdditional: number;
  disposableMonthlyIncome: string;
  monthlySurplusInstallmentRatio: string;
}

interface SpouseCost {
  monthlyHousingCost: number;
  monthlyExpensesAlimony: number;
  monthlyEducationCosts: number;
  monthlyProfessionalOrganizationFee: number;
  monthlyOtherFixExpenses: number;
  monthlyOneTimeExpenses: number;
  totalMonthlyIncomeGrossAmount: number;
  monthlyIncomeGrossAmountMain: number;
  monthlyIncomeGrossAmountAdditional: number;
  disposableMonthlyIncome: string;
  monthlySurplusInstallmentRatio: string;
}

export interface KremoData {
  kremoHouseholdExpenditures: KremoHouseholdExpenditures;
  generalExpenses: GeneralExpenses;
  childrenList: KremoChild[];
  exemptAmountList?: ExemptAmount[];
}

interface KremoChild {
  ageAtEndOfYear: string;
  subsistenceMinimum: number;
}

interface KremoHouseholdExpenditures {
  healthInsuranceFee: number;
  subsistenceMinimum: number;
}

interface GeneralExpenses {
  applicantExpenses: Expenditures;
  partnerExpenses?: Expenditures;
}

interface Expenditures {
  taxAtSource: number;
  socialCharges: number;
  externalFoodBoardingDeduction: number;
  increasedFoodIntakeDeduction: number;
  increasedClothingRequirementDeduction: number;
  transportationCost: number;
  openCreditAmount: number;
  openLeasingRateAmount: number;
}

interface PersonalDataCreditCheck {
  maritalStatus: MaritalStatus;
  householdType: HouseholdType;
  residenceType: HousingReference;
  householdMonthlyInsuranceFee: number;
}

export interface ZekData {
  zekScore: number;
  zekHits: ZekHits[];
}

interface ZekHits {
  clientId: string;
  zekScoreValue: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  gender: GenderType;
  fullAddress: string;
  nationality: Country;
  personStatus: string;
  hitScore: string;
}

export interface CreditOfferTableData {
  name: CreditOfferTableDataName;
  value?: string | number;
}

export enum CreditOfferTableDataName {
  LOAN_AMOUNT = 'loanAmount',
  LOAN_TERM = 'loanTerm',
  MONTHLY_RATE = 'monthlyRate',
  DECLINING_BALANCE = 'decliningBalance',
  DEPOSIT = 'deposit',
  INTEREST_RATE = 'interestRate',
  CONTRACT_START_DATE = 'contractStartDate'
}
