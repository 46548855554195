import { useTranslation } from 'react-i18next';

import Text from '../Text/Text';
import UploadFile from '../../views/UploadOffer/UploadFile/UploadFile';
import FileItem from '../File/FileItem/FileItem';
import ContractDocumentItem from '../ContractDetails/ContractDocumentsList/ContractDocumentItem/ContractDocumentItem';

import { SetStateType } from '../../types/util.types';

import './ContractFilesUpload.scss';

interface ContractFilesUploadProps {
  files: File[];
  variation?: 'simple' | 'detailed';
  setFiles: SetStateType<File[]>;
}

const ContractFilesUpload = ({ files, variation, setFiles }: ContractFilesUploadProps) => {
  const { t } = useTranslation();

  const selectFileHandler = (files: FileList | null) => {
    if (!files) return;
    if (files.length > 0) {
      const newFiles = Array.from(files);
      setFiles((prev) => [...prev, ...newFiles]);
    }
  };

  const removeFileHandler = (index: number) => {
    const filteredFiles = files?.filter((c) => files.indexOf(c) !== index);
    setFiles(filteredFiles);
  };

  return (
    <div className="contract-files-upload">
      <Text as="p">{t('admin.projectDetails.contracts.contractDocumentsAndAnexes')}</Text>
      <UploadFile
        handleChangeFiles={selectFileHandler}
        variation="simple"
        multiple
        acceptFileType="pdf"
      />
      {!!files?.length &&
        files?.map((c, i) =>
          variation === 'simple' ? (
            <ContractDocumentItem
              key={i}
              fileName={c.name}
              icon="remove"
              onClick={() => {
                removeFileHandler(i);
              }}
            />
          ) : (
            <FileItem
              key={i}
              name={c.name}
              onDelete={() => {
                removeFileHandler(i);
              }}
              variation="primary"
              className="contract-file-display"
            />
          )
        )}
    </div>
  );
};

export default ContractFilesUpload;
