import FinancingCalculator from './FinancingCalculator/FinancingCalculator';
import './Home.scss';

export const Home = () => {
  return (
    <main className={'home-main'}>
      <FinancingCalculator />
      {/* <FinancingViaOfferCalculator /> */}
    </main>
  );
};

export default Home;
