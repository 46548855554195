/* tslint:disable */
/* eslint-disable */
/**
 * Investerra OpenAPI Spec
 * Investerra OpenAPI Spec
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * An AccessToken provides all the required information to make authorized calls and to obtain fresh tokens.
 * @export
 * @interface AccessToken
 */
export interface AccessToken {
    /**
     * JWT token to use for subsequent access.
     * @type {string}
     * @memberof AccessToken
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'token_type'?: AccessTokenTokenTypeEnum;
    /**
     * Number of seconds before the token expires
     * @type {number}
     * @memberof AccessToken
     */
    'expires_in': number;
    /**
     * Token to use for obtaining a new access token using the refresh endpoint
     * @type {string}
     * @memberof AccessToken
     */
    'refresh_token': string;
}

export const AccessTokenTokenTypeEnum = {
    Bearer: 'bearer'
} as const;

export type AccessTokenTokenTypeEnum = typeof AccessTokenTokenTypeEnum[keyof typeof AccessTokenTokenTypeEnum];

/**
 * 
 * @export
 * @interface ActiveModuleDto
 */
export interface ActiveModuleDto {
    /**
     * 
     * @type {string}
     * @memberof ActiveModuleDto
     */
    'type'?: ActiveModuleDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ActiveModuleDto
     */
    'value'?: number;
}

export const ActiveModuleDtoTypeEnum = {
    EChargingStation: 'E_CHARGING_STATION',
    HeatPump: 'HEAT_PUMP',
    StorageSolution: 'STORAGE_SOLUTION',
    InvesterraElectricitySales: 'INVESTERRA_ELECTRICITY_SALES',
    SolarSystem: 'SOLAR_SYSTEM'
} as const;

export type ActiveModuleDtoTypeEnum = typeof ActiveModuleDtoTypeEnum[keyof typeof ActiveModuleDtoTypeEnum];

/**
 * Address object
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'houseNumber': string;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    'postalCode': number;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'municipality': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'canton'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'street'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressDto
     */
    'postalCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'houseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'municipality'?: string;
}
/**
 * AddressType enum
 * @export
 * @enum {string}
 */

export const AddressType = {
    Current: 'CURRENT',
    MailingCorrespondence: 'MAILING_CORRESPONDENCE',
    Previous: 'PREVIOUS',
    Employment: 'EMPLOYMENT',
    Temporary: 'TEMPORARY'
} as const;

export type AddressType = typeof AddressType[keyof typeof AddressType];


/**
 * The credit check data for the applicant for the questionnaire
 * @export
 * @interface ApplicantData
 */
export interface ApplicantData {
    /**
     * 
     * @type {boolean}
     * @memberof ApplicantData
     */
    'beneficialOwner'?: boolean;
    /**
     * 
     * @type {ResidenceType}
     * @memberof ApplicantData
     */
    'permitType'?: ResidenceType | null;
    /**
     * 
     * @type {ResidenceType}
     * @memberof ApplicantData
     */
    'residenceType'?: ResidenceType | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantData
     */
    'residingSinceYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantData
     */
    'residingSinceMonth'?: number | null;
    /**
     * 
     * @type {MaritalStatus}
     * @memberof ApplicantData
     */
    'maritalStatus'?: MaritalStatus | null;
    /**
     * 
     * @type {EmploymentStatus}
     * @memberof ApplicantData
     */
    'employmentStatus'?: EmploymentStatus | null;
    /**
     * 
     * @type {CreditCheckFinancialData}
     * @memberof ApplicantData
     */
    'financialData'?: CreditCheckFinancialData;
}


/**
 * 
 * @export
 * @interface BankDetails
 */
export interface BankDetails {
    /**
     * 
     * @type {string}
     * @memberof BankDetails
     */
    'iBAN'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankDetails
     */
    'bankName'?: string;
}
/**
 * 
 * @export
 * @interface BuildingDto
 */
export interface BuildingDto {
    /**
     * 
     * @type {string}
     * @memberof BuildingDto
     */
    'sepId'?: string;
    /**
     * 
     * @type {AddressDto}
     * @memberof BuildingDto
     */
    'address'?: AddressDto;
    /**
     * 
     * @type {number}
     * @memberof BuildingDto
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingDto
     */
    'longitude'?: number;
}
/**
 * 
 * @export
 * @interface Canton
 */
export interface Canton {
    /**
     * 
     * @type {number}
     * @memberof Canton
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Canton
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Canton
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CantonDto
 */
export interface CantonDto {
    /**
     * 
     * @type {number}
     * @memberof CantonDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CantonDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CantonDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CompanyAddressDto
 */
export interface CompanyAddressDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'houseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'swissZipCode'?: string;
}
/**
 * 
 * @export
 * @interface CompanyDto
 */
export interface CompanyDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'uid'?: string;
    /**
     * 
     * @type {CompanyAddressDto}
     * @memberof CompanyDto
     */
    'address'?: CompanyAddressDto;
    /**
     * 
     * @type {LegalFormDto}
     * @memberof CompanyDto
     */
    'legalForm'?: LegalFormDto;
}
/**
 * 
 * @export
 * @interface ComplianceCheckData
 */
export interface ComplianceCheckData {
    /**
     * 
     * @type {Array<ComplianceCheckEntry>}
     * @memberof ComplianceCheckData
     */
    'pepEntries'?: Array<ComplianceCheckEntry>;
    /**
     * 
     * @type {Array<ComplianceCheckEntry>}
     * @memberof ComplianceCheckData
     */
    'slEntries'?: Array<ComplianceCheckEntry>;
    /**
     * 
     * @type {Array<ComplianceCheckEntry>}
     * @memberof ComplianceCheckData
     */
    'wlblEntries'?: Array<ComplianceCheckEntry>;
}
/**
 * 
 * @export
 * @interface ComplianceCheckEntry
 */
export interface ComplianceCheckEntry {
    /**
     * 
     * @type {string}
     * @memberof ComplianceCheckEntry
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplianceCheckEntry
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplianceCheckEntry
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplianceCheckEntry
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplianceCheckEntry
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplianceCheckEntry
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface ContractCancellation
 */
export interface ContractCancellation {
    /**
     * 
     * @type {ContractCancellationReason}
     * @memberof ContractCancellation
     */
    'reason': ContractCancellationReason;
    /**
     * 
     * @type {string}
     * @memberof ContractCancellation
     */
    'comment'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ContractCancellationReason = {
    CustomerRejection: 'CUSTOMER_REJECTION',
    CustomerAdjustment: 'CUSTOMER_ADJUSTMENT',
    CustomerWithdrawal: 'CUSTOMER_WITHDRAWAL',
    InvesterraWithdrawal: 'INVESTERRA_WITHDRAWAL'
} as const;

export type ContractCancellationReason = typeof ContractCancellationReason[keyof typeof ContractCancellationReason];


/**
 * 
 * @export
 * @interface ContractCreate
 */
export interface ContractCreate {
    /**
     * 
     * @type {ContractType}
     * @memberof ContractCreate
     */
    'type': ContractType;
    /**
     * 
     * @type {string}
     * @memberof ContractCreate
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof ContractCreate
     */
    'comment'?: string;
    /**
     * 
     * @type {Array<ContractSignature>}
     * @memberof ContractCreate
     */
    'signatures': Array<ContractSignature>;
}


/**
 * 
 * @export
 * @interface ContractEvent
 */
export interface ContractEvent {
    /**
     * 
     * @type {string}
     * @memberof ContractEvent
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ContractEvent
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface ContractGet
 */
export interface ContractGet {
    /**
     * 
     * @type {string}
     * @memberof ContractGet
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ContractGet
     */
    'projectId': string;
    /**
     * 
     * @type {ContractType}
     * @memberof ContractGet
     */
    'type': ContractType;
    /**
     * 
     * @type {string}
     * @memberof ContractGet
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof ContractGet
     */
    'comment'?: string;
    /**
     * 
     * @type {ContractStatus}
     * @memberof ContractGet
     */
    'status': ContractStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractGet
     */
    'documents': Array<string>;
    /**
     * 
     * @type {Array<ContractSignature>}
     * @memberof ContractGet
     */
    'signatures'?: Array<ContractSignature>;
    /**
     * 
     * @type {Array<ContractEvent>}
     * @memberof ContractGet
     */
    'events': Array<ContractEvent>;
}


/**
 * 
 * @export
 * @interface ContractHistory
 */
export interface ContractHistory {
    /**
     * 
     * @type {ContractCancellationReason}
     * @memberof ContractHistory
     */
    'reason': ContractCancellationReason;
    /**
     * 
     * @type {string}
     * @memberof ContractHistory
     */
    'comment'?: string;
}


/**
 * 
 * @export
 * @interface ContractSignature
 */
export interface ContractSignature {
    /**
     * 
     * @type {ContractSignatureType}
     * @memberof ContractSignature
     */
    'type': ContractSignatureType;
    /**
     * 
     * @type {string}
     * @memberof ContractSignature
     */
    'date': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ContractSignatureType = {
    Investerra: 'INVESTERRA',
    Customer: 'CUSTOMER'
} as const;

export type ContractSignatureType = typeof ContractSignatureType[keyof typeof ContractSignatureType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ContractStatus = {
    ContractSentToCustomer: 'CONTRACT_SENT_TO_CUSTOMER',
    ContractRejectedByCustomer: 'CONTRACT_REJECTED_BY_CUSTOMER',
    ContractWithdrawnByInvesterra: 'CONTRACT_WITHDRAWN_BY_INVESTERRA',
    ContractChangeRequested: 'CONTRACT_CHANGE_REQUESTED',
    SigningDeadlineNotMet: 'SIGNING_DEADLINE_NOT_MET',
    CustomerUses14DayRightOfWithdrawal: 'CUSTOMER_USES_14_DAY_RIGHT_OF_WITHDRAWAL',
    ContractSignedRightOfWithdrawalActive: 'CONTRACT_SIGNED_RIGHT_OF_WITHDRAWAL_ACTIVE',
    ContractValid: 'CONTRACT_VALID'
} as const;

export type ContractStatus = typeof ContractStatus[keyof typeof ContractStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ContractType = {
    Loan: 'LOAN',
    Insurance: 'INSURANCE'
} as const;

export type ContractType = typeof ContractType[keyof typeof ContractType];


/**
 * 
 * @export
 * @interface ContractUpdate
 */
export interface ContractUpdate {
    /**
     * 
     * @type {ContractCancellation}
     * @memberof ContractUpdate
     */
    'cancellation'?: ContractCancellation;
    /**
     * 
     * @type {Array<ContractSignature>}
     * @memberof ContractUpdate
     */
    'signatures'?: Array<ContractSignature>;
}
/**
 * Country enum
 * @export
 * @enum {string}
 */

export const Country = {
    Afghanistan: 'AFGHANISTAN',
    Albania: 'ALBANIA',
    Algeria: 'ALGERIA',
    Andorra: 'ANDORRA',
    Angola: 'ANGOLA',
    AntiguaAndDeps: 'ANTIGUA_AND_DEPS',
    Argentina: 'ARGENTINA',
    Armenia: 'ARMENIA',
    Australia: 'AUSTRALIA',
    Austria: 'AUSTRIA',
    Azerbaijan: 'AZERBAIJAN',
    Bahamas: 'BAHAMAS',
    Bahrain: 'BAHRAIN',
    Bangladesh: 'BANGLADESH',
    Barbados: 'BARBADOS',
    Belarus: 'BELARUS',
    Belgium: 'BELGIUM',
    Belize: 'BELIZE',
    Benin: 'BENIN',
    Bhutan: 'BHUTAN',
    Bolivia: 'BOLIVIA',
    BosniaHerzegovina: 'BOSNIA_HERZEGOVINA',
    Botswana: 'BOTSWANA',
    Brazil: 'BRAZIL',
    Brunei: 'BRUNEI',
    Bulgaria: 'BULGARIA',
    Burkina: 'BURKINA',
    Burundi: 'BURUNDI',
    Cambodia: 'CAMBODIA',
    Cameroon: 'CAMEROON',
    Canada: 'CANADA',
    CapeVerde: 'CAPE_VERDE',
    CentralAfricanRep: 'CENTRAL_AFRICAN_REP',
    Chad: 'CHAD',
    Chile: 'CHILE',
    China: 'CHINA',
    Colombia: 'COLOMBIA',
    Comoros: 'COMOROS',
    Congo: 'CONGO',
    CongoDemocraticRep: 'CONGO_DEMOCRATIC_REP',
    CostaRica: 'COSTA_RICA',
    Croatia: 'CROATIA',
    Cuba: 'CUBA',
    Cyprus: 'CYPRUS',
    CzechRepublic: 'CZECH_REPUBLIC',
    Denmark: 'DENMARK',
    Djibouti: 'DJIBOUTI',
    Dominica: 'DOMINICA',
    DominicanRepublic: 'DOMINICAN_REPUBLIC',
    EastTimor: 'EAST_TIMOR',
    Ecuador: 'ECUADOR',
    Egypt: 'EGYPT',
    ElSalvador: 'EL_SALVADOR',
    EquatorialGuinea: 'EQUATORIAL_GUINEA',
    Eritrea: 'ERITREA',
    Estonia: 'ESTONIA',
    Ethiopia: 'ETHIOPIA',
    Fiji: 'FIJI',
    Finland: 'FINLAND',
    France: 'FRANCE',
    Gabon: 'GABON',
    Gambia: 'GAMBIA',
    Georgia: 'GEORGIA',
    Germany: 'GERMANY',
    Ghana: 'GHANA',
    Greece: 'GREECE',
    Grenada: 'GRENADA',
    Guatemala: 'GUATEMALA',
    Guinea: 'GUINEA',
    GuineaBissau: 'GUINEA_BISSAU',
    Guyana: 'GUYANA',
    Haiti: 'HAITI',
    Honduras: 'HONDURAS',
    Hungary: 'HUNGARY',
    Iceland: 'ICELAND',
    India: 'INDIA',
    Indonesia: 'INDONESIA',
    Iran: 'IRAN',
    Iraq: 'IRAQ',
    IrelandRepublic: 'IRELAND_REPUBLIC',
    Israel: 'ISRAEL',
    Italy: 'ITALY',
    IvoryCoast: 'IVORY_COAST',
    Jamaica: 'JAMAICA',
    Japan: 'JAPAN',
    Jordan: 'JORDAN',
    Kazakhstan: 'KAZAKHSTAN',
    Kenya: 'KENYA',
    Kiribati: 'KIRIBATI',
    KoreaNorth: 'KOREA_NORTH',
    KoreaSouth: 'KOREA_SOUTH',
    Kosovo: 'KOSOVO',
    Kuwait: 'KUWAIT',
    Kyrgyzstan: 'KYRGYZSTAN',
    Laos: 'LAOS',
    Latvia: 'LATVIA',
    Lebanon: 'LEBANON',
    Lesotho: 'LESOTHO',
    Liberia: 'LIBERIA',
    Libya: 'LIBYA',
    Liechtenstein: 'LIECHTENSTEIN',
    Lithuania: 'LITHUANIA',
    Luxembourg: 'LUXEMBOURG',
    Macedonia: 'MACEDONIA',
    Madagascar: 'MADAGASCAR',
    Malawi: 'MALAWI',
    Malaysia: 'MALAYSIA',
    Maldives: 'MALDIVES',
    Mali: 'MALI',
    Malta: 'MALTA',
    MarshallIslands: 'MARSHALL_ISLANDS',
    Mauritania: 'MAURITANIA',
    Mauritius: 'MAURITIUS',
    Mexico: 'MEXICO',
    Micronesia: 'MICRONESIA',
    Moldova: 'MOLDOVA',
    Monaco: 'MONACO',
    Mongolia: 'MONGOLIA',
    Montenegro: 'MONTENEGRO',
    Morocco: 'MOROCCO',
    Mozambique: 'MOZAMBIQUE',
    MyanmarBurma: 'MYANMAR_BURMA',
    Namibia: 'NAMIBIA',
    Nauru: 'NAURU',
    Nepal: 'NEPAL',
    Netherlands: 'NETHERLANDS',
    NewZealand: 'NEW_ZEALAND',
    Nicaragua: 'NICARAGUA',
    Niger: 'NIGER',
    Nigeria: 'NIGERIA',
    Norway: 'NORWAY',
    Oman: 'OMAN',
    Pakistan: 'PAKISTAN',
    Palau: 'PALAU',
    Panama: 'PANAMA',
    PapuaNewGuinea: 'PAPUA_NEW_GUINEA',
    Paraguay: 'PARAGUAY',
    Peru: 'PERU',
    Philippines: 'PHILIPPINES',
    Poland: 'POLAND',
    Portugal: 'PORTUGAL',
    Qatar: 'QATAR',
    Romania: 'ROMANIA',
    RussianFederation: 'RUSSIAN_FEDERATION',
    Rwanda: 'RWANDA',
    StKittsAndNevis: 'ST_KITTS_AND_NEVIS',
    StLucia: 'ST_LUCIA',
    SaintVincentAndTheGrenadines: 'SAINT_VINCENT_AND_THE_GRENADINES',
    Samoa: 'SAMOA',
    SanMarino: 'SAN_MARINO',
    SaoTomeAndPrincipe: 'SAO_TOME_AND_PRINCIPE',
    SaudiArabia: 'SAUDI_ARABIA',
    Senegal: 'SENEGAL',
    Serbia: 'SERBIA',
    Seychelles: 'SEYCHELLES',
    SierraLeone: 'SIERRA_LEONE',
    Singapore: 'SINGAPORE',
    Slovakia: 'SLOVAKIA',
    Slovenia: 'SLOVENIA',
    SolomonIslands: 'SOLOMON_ISLANDS',
    Somalia: 'SOMALIA',
    SouthAfrica: 'SOUTH_AFRICA',
    SouthSudan: 'SOUTH_SUDAN',
    Spain: 'SPAIN',
    SriLanka: 'SRI_LANKA',
    Sudan: 'SUDAN',
    Suriname: 'SURINAME',
    Swaziland: 'SWAZILAND',
    Sweden: 'SWEDEN',
    Switzerland: 'SWITZERLAND',
    Syria: 'SYRIA',
    Taiwan: 'TAIWAN',
    Tajikistan: 'TAJIKISTAN',
    Tanzania: 'TANZANIA',
    Thailand: 'THAILAND',
    Togo: 'TOGO',
    Tonga: 'TONGA',
    TrinidadAndTobago: 'TRINIDAD_AND_TOBAGO',
    Tunisia: 'TUNISIA',
    Turkey: 'TURKEY',
    Turkmenistan: 'TURKMENISTAN',
    Tuvalu: 'TUVALU',
    Uganda: 'UGANDA',
    Ukraine: 'UKRAINE',
    UnitedArabEmirates: 'UNITED_ARAB_EMIRATES',
    UnitedKingdom: 'UNITED_KINGDOM',
    UnitedStates: 'UNITED_STATES',
    Uruguay: 'URUGUAY',
    Uzbekistan: 'UZBEKISTAN',
    Vanuatu: 'VANUATU',
    VaticanCity: 'VATICAN_CITY',
    Venezuela: 'VENEZUELA',
    Vietnam: 'VIETNAM',
    Yemen: 'YEMEN',
    Zambia: 'ZAMBIA',
    Zimbabwe: 'ZIMBABWE'
} as const;

export type Country = typeof Country[keyof typeof Country];


/**
 * 
 * @export
 * @interface CreatePartner
 */
export interface CreatePartner {
    /**
     * 
     * @type {string}
     * @memberof CreatePartner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePartner
     */
    'uid': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePartner
     */
    'rating'?: number;
    /**
     * 
     * @type {Array<PartnerLocation>}
     * @memberof CreatePartner
     */
    'partnerLocations'?: Array<PartnerLocation>;
    /**
     * 
     * @type {Array<Canton>}
     * @memberof CreatePartner
     */
    'cantons'?: Array<Canton>;
    /**
     * 
     * @type {Array<Module>}
     * @memberof CreatePartner
     */
    'modules'?: Array<Module>;
    /**
     * 
     * @type {string}
     * @memberof CreatePartner
     */
    'legalForm': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePartner
     */
    'contactFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePartner
     */
    'contactLastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePartner
     */
    'contactEmail': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePartner
     */
    'contactPhoneNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePartner
     */
    'verified'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateProjectDto
 */
export interface CreateProjectDto {
    /**
     * 
     * @type {Array<ModuleDto>}
     * @memberof CreateProjectDto
     */
    'modules'?: Array<ModuleDto>;
    /**
     * 
     * @type {FinancingModelDto}
     * @memberof CreateProjectDto
     */
    'financingModel'?: FinancingModelDto;
    /**
     * 
     * @type {BuildingDto}
     * @memberof CreateProjectDto
     */
    'building'?: BuildingDto;
    /**
     * 
     * @type {Array<PartnerDto>}
     * @memberof CreateProjectDto
     */
    'partners'?: Array<PartnerDto>;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectDto
     */
    'projectUuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectDto
     */
    'profileUuid'?: string;
}
/**
 * 
 * @export
 * @interface CreateScannedProjectDto
 */
export interface CreateScannedProjectDto {
    /**
     * 
     * @type {Array<ModuleDto>}
     * @memberof CreateScannedProjectDto
     */
    'modules'?: Array<ModuleDto>;
    /**
     * 
     * @type {FinancingModelDto}
     * @memberof CreateScannedProjectDto
     */
    'financingModel'?: FinancingModelDto;
    /**
     * 
     * @type {BuildingDto}
     * @memberof CreateScannedProjectDto
     */
    'building'?: BuildingDto;
    /**
     * 
     * @type {string}
     * @memberof CreateScannedProjectDto
     */
    'validThru'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateScannedProjectDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateScannedProjectDto
     */
    'offeredPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateScannedProjectDto
     */
    'documentUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateScannedProjectDto
     */
    'area'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateScannedProjectDto
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateScannedProjectDto
     */
    'issuanceDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateScannedProjectDto
     */
    'performance'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateScannedProjectDto
     */
    'subsidy'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateScannedProjectDto
     */
    'contactPerson'?: string;
    /**
     * 
     * @type {CreateScannedSpouseDto}
     * @memberof CreateScannedProjectDto
     */
    'partner'?: CreateScannedSpouseDto;
    /**
     * 
     * @type {string}
     * @memberof CreateScannedProjectDto
     */
    'projectUuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateScannedProjectDto
     */
    'profileUuid'?: string;
}
/**
 * 
 * @export
 * @interface CreateScannedSpouseDto
 */
export interface CreateScannedSpouseDto {
    /**
     * 
     * @type {string}
     * @memberof CreateScannedSpouseDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateScannedSpouseDto
     */
    'uid'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateScannedSpouseDto
     */
    'rating'?: number;
    /**
     * 
     * @type {Array<PartnerLocationDto>}
     * @memberof CreateScannedSpouseDto
     */
    'partnerLocations'?: Array<PartnerLocationDto>;
    /**
     * 
     * @type {string}
     * @memberof CreateScannedSpouseDto
     */
    'legalForm'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateScannedSpouseDto
     */
    'contactFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateScannedSpouseDto
     */
    'contactLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateScannedSpouseDto
     */
    'contactEmail'?: string;
}
/**
 * 
 * @export
 * @interface CreditCheckFinancialData
 */
export interface CreditCheckFinancialData {
    /**
     * 
     * @type {string}
     * @memberof CreditCheckFinancialData
     */
    'monthlyHousingCost'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckFinancialData
     */
    'monthlyIncomeGrossAmountMain'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreditCheckFinancialData
     */
    'monthlyIncomeGrossAmountAdditional'?: Array<string>;
    /**
     * 
     * @type {TransportationType}
     * @memberof CreditCheckFinancialData
     */
    'transportationType'?: TransportationType;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckFinancialData
     */
    'monthlyTransportationCosts'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckFinancialData
     */
    'monthlyExpensesAlimony'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckFinancialData
     */
    'monthlyEducationCosts'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckFinancialData
     */
    'monthlyOtherFixExpenses'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCheckFinancialData
     */
    'monthlyOneTimeExpenses'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreditCheckFinancialData
     */
    'workingDaysPerMonth'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreditCheckFinancialData
     */
    'isChurchTaxObligatory'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof CreditCheckFinancialData
     */
    'numberOfMonthlyIncomeMain'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreditCheckFinancialData
     */
    'numberOfMonthlyIncomeAdditional'?: number | null;
}


/**
 * The credit check data for the questionnaire
 * @export
 * @interface CreditCheckQuestionnaireData
 */
export interface CreditCheckQuestionnaireData {
    /**
     * 
     * @type {ApplicantData}
     * @memberof CreditCheckQuestionnaireData
     */
    'applicant': ApplicantData;
    /**
     * 
     * @type {HouseholdData}
     * @memberof CreditCheckQuestionnaireData
     */
    'household': HouseholdData;
    /**
     * 
     * @type {HouseholdPartnerData}
     * @memberof CreditCheckQuestionnaireData
     */
    'householdPartner'?: HouseholdPartnerData;
}
/**
 * 
 * @export
 * @interface CreditCheckResultsData
 */
export interface CreditCheckResultsData {
    /**
     * 
     * @type {PersonalDataCreditCheck}
     * @memberof CreditCheckResultsData
     */
    'personalDataCreditCheck'?: PersonalDataCreditCheck;
    /**
     * 
     * @type {ExperianData}
     * @memberof CreditCheckResultsData
     */
    'experianData'?: ExperianData;
    /**
     * 
     * @type {CreditOffer}
     * @memberof CreditCheckResultsData
     */
    'creditOffer'?: CreditOffer;
    /**
     * 
     * @type {FinancialValuationData}
     * @memberof CreditCheckResultsData
     */
    'financialValuationData'?: FinancialValuationData;
    /**
     * 
     * @type {ZekData}
     * @memberof CreditCheckResultsData
     */
    'zekData'?: ZekData;
    /**
     * 
     * @type {ComplianceCheckData}
     * @memberof CreditCheckResultsData
     */
    'complianceCheckData'?: ComplianceCheckData;
    /**
     * 
     * @type {KremoData}
     * @memberof CreditCheckResultsData
     */
    'kremoData'?: KremoData;
}
/**
 * 
 * @export
 * @interface CreditOffer
 */
export interface CreditOffer {
    /**
     * 
     * @type {string}
     * @memberof CreditOffer
     */
    'requestedAmount'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditOffer
     */
    'requestedTerm'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditOffer
     */
    'requestedMonthlyInstallment'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditOffer
     */
    'decliningBalance'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditOffer
     */
    'downPayment'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditOffer
     */
    'interestRateNominal'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditOffer
     */
    'contractStartDate'?: string;
}
/**
 * 
 * @export
 * @interface CustomerFinancialData
 */
export interface CustomerFinancialData {
    /**
     * 
     * @type {string}
     * @memberof CustomerFinancialData
     */
    'monthlyTransportationCosts'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerFinancialData
     */
    'workingDaysPerMonth'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerFinancialData
     */
    'monthlyHousingCost'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFinancialData
     */
    'monthlyExpensesAlimony'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFinancialData
     */
    'monthlyEducationCosts'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFinancialData
     */
    'monthlyProfessionalOrganizationFee'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFinancialData
     */
    'monthlyOtherFixExpenses'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFinancialData
     */
    'monthlyOneTimeExpenses'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFinancialData
     */
    'totalMonthlyIncomeGrossAmount'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFinancialData
     */
    'monthlyIncomeGrossAmountMain'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFinancialData
     */
    'monthlyIncomeGrossAmountAdditional'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFinancialData
     */
    'disposableMonthlyIncome'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFinancialData
     */
    'monthlySurplusInstallmentRatio'?: string;
}
/**
 * 
 * @export
 * @interface DocumentTypeResponse
 */
export interface DocumentTypeResponse {
    /**
     * 
     * @type {string}
     * @memberof DocumentTypeResponse
     */
    'url': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EmploymentStatus = {
    Employed: 'EMPLOYED',
    Unemployed: 'UNEMPLOYED',
    SelfEmployed: 'SELF_EMPLOYED',
    Student: 'STUDENT',
    Pension: 'PENSION',
    TemporaryEmployment: 'TEMPORARY_EMPLOYMENT',
    HouseWifeMan: 'HOUSE_WIFE_MAN'
} as const;

export type EmploymentStatus = typeof EmploymentStatus[keyof typeof EmploymentStatus];


/**
 * General JSON representation for errors
 * @export
 * @interface ErrorType
 */
export interface ErrorType {
    /**
     * Unique code for tracing
     * @type {string}
     * @memberof ErrorType
     */
    'trace': string;
    /**
     * Unique error code
     * @type {string}
     * @memberof ErrorType
     */
    'code': string;
    /**
     * description of the error
     * @type {string}
     * @memberof ErrorType
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ExemptAmount
 */
export interface ExemptAmount {
    /**
     * 
     * @type {string}
     * @memberof ExemptAmount
     */
    'amount'?: string;
    /**
     * 
     * @type {ExemptAmountType}
     * @memberof ExemptAmount
     */
    'exemptAmountType'?: ExemptAmountType;
    /**
     * 
     * @type {boolean}
     * @memberof ExemptAmount
     */
    'isCorrected'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ExemptAmountType = {
    TaxAtSource: 'AFTER_TAX_AT_SOURCE',
    CurrentLiabilities: 'AFTER_CURRENT_LIABILITIES',
    ApplicationData: 'AFTER_APPLICATION_DATA'
} as const;

export type ExemptAmountType = typeof ExemptAmountType[keyof typeof ExemptAmountType];


/**
 * 
 * @export
 * @interface Expenditures
 */
export interface Expenditures {
    /**
     * 
     * @type {string}
     * @memberof Expenditures
     */
    'taxAtSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof Expenditures
     */
    'socialCharges'?: string;
    /**
     * 
     * @type {string}
     * @memberof Expenditures
     */
    'externalFoodBoardingDeduction'?: string;
    /**
     * 
     * @type {string}
     * @memberof Expenditures
     */
    'increasedFoodIntakeDeduction'?: string;
    /**
     * 
     * @type {string}
     * @memberof Expenditures
     */
    'increasedClothingRequirementDeduction'?: string;
    /**
     * 
     * @type {string}
     * @memberof Expenditures
     */
    'transportationCost'?: string;
    /**
     * 
     * @type {string}
     * @memberof Expenditures
     */
    'openCreditAmount'?: string;
    /**
     * 
     * @type {string}
     * @memberof Expenditures
     */
    'openLeasingRateAmount'?: string;
}
/**
 * 
 * @export
 * @interface ExperianData
 */
export interface ExperianData {
    /**
     * 
     * @type {string}
     * @memberof ExperianData
     */
    'systemDecision'?: string;
    /**
     * 
     * @type {IntrumCreditScore}
     * @memberof ExperianData
     */
    'intrumCreditScore'?: IntrumCreditScore;
    /**
     * 
     * @type {Array<ExperianReasonCode>}
     * @memberof ExperianData
     */
    'experianReasonCodes'?: Array<ExperianReasonCode>;
}
/**
 * 
 * @export
 * @interface ExperianReasonCode
 */
export interface ExperianReasonCode {
    /**
     * 
     * @type {string}
     * @memberof ExperianReasonCode
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperianReasonCode
     */
    'description'?: string;
}
/**
 * A Token that provides all the required information to make authorized calls and to obtain fresh tokens.
 * @export
 * @interface ExperianToken
 */
export interface ExperianToken {
    /**
     * Time the token was issued
     * @type {string}
     * @memberof ExperianToken
     */
    'issued_at': string;
    /**
     * Number of seconds before the token expires
     * @type {string}
     * @memberof ExperianToken
     */
    'expires_in': string;
    /**
     * 
     * @type {string}
     * @memberof ExperianToken
     */
    'token_type': ExperianTokenTokenTypeEnum;
    /**
     * JWT token to use for subsequent access.
     * @type {string}
     * @memberof ExperianToken
     */
    'access_token': string;
    /**
     * Token to use for obtaining a new access token using the refresh endpoint
     * @type {string}
     * @memberof ExperianToken
     */
    'refresh_token': string;
}

export const ExperianTokenTokenTypeEnum = {
    Bearer: 'bearer'
} as const;

export type ExperianTokenTokenTypeEnum = typeof ExperianTokenTokenTypeEnum[keyof typeof ExperianTokenTokenTypeEnum];

/**
 * 
 * @export
 * @interface FinancialOverviewInputsDto
 */
export interface FinancialOverviewInputsDto {
    /**
     * 
     * @type {string}
     * @memberof FinancialOverviewInputsDto
     */
    'type'?: FinancialOverviewInputsDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof FinancialOverviewInputsDto
     */
    'offerPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialOverviewInputsDto
     */
    'deposit'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialOverviewInputsDto
     */
    'subsidy'?: number;
    /**
     * 
     * @type {Array<ActiveModuleDto>}
     * @memberof FinancialOverviewInputsDto
     */
    'modules'?: Array<ActiveModuleDto>;
    /**
     * 
     * @type {number}
     * @memberof FinancialOverviewInputsDto
     */
    'performance'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialOverviewInputsDto
     */
    'solarPotential'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialOverviewInputsDto
     */
    'altitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialOverviewInputsDto
     */
    'sustainability'?: number;
}

export const FinancialOverviewInputsDtoTypeEnum = {
    Final: 'FINAL',
    Indicative: 'INDICATIVE'
} as const;

export type FinancialOverviewInputsDtoTypeEnum = typeof FinancialOverviewInputsDtoTypeEnum[keyof typeof FinancialOverviewInputsDtoTypeEnum];

/**
 * 
 * @export
 * @interface FinancialOverviewPlanInputsDto
 */
export interface FinancialOverviewPlanInputsDto {
    /**
     * 
     * @type {string}
     * @memberof FinancialOverviewPlanInputsDto
     */
    'type'?: FinancialOverviewPlanInputsDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof FinancialOverviewPlanInputsDto
     */
    'offerPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialOverviewPlanInputsDto
     */
    'deposit'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialOverviewPlanInputsDto
     */
    'duration'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialOverviewPlanInputsDto
     */
    'subsidy'?: number;
    /**
     * 
     * @type {Array<ActiveModuleDto>}
     * @memberof FinancialOverviewPlanInputsDto
     */
    'modules'?: Array<ActiveModuleDto>;
    /**
     * 
     * @type {number}
     * @memberof FinancialOverviewPlanInputsDto
     */
    'performance'?: number;
}

export const FinancialOverviewPlanInputsDtoTypeEnum = {
    Final: 'FINAL',
    Indicative: 'INDICATIVE'
} as const;

export type FinancialOverviewPlanInputsDtoTypeEnum = typeof FinancialOverviewPlanInputsDtoTypeEnum[keyof typeof FinancialOverviewPlanInputsDtoTypeEnum];

/**
 * 
 * @export
 * @interface FinancialValuationData
 */
export interface FinancialValuationData {
    /**
     * 
     * @type {CustomerFinancialData}
     * @memberof FinancialValuationData
     */
    'applicant'?: CustomerFinancialData;
    /**
     * 
     * @type {CustomerFinancialData}
     * @memberof FinancialValuationData
     */
    'spouse'?: CustomerFinancialData;
}
/**
 * 
 * @export
 * @interface FinancingModelDto
 */
export interface FinancingModelDto {
    /**
     * 
     * @type {number}
     * @memberof FinancingModelDto
     */
    'duration'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancingModelDto
     */
    'deposit'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancingModelDto
     */
    'baseFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancingModelDto
     */
    'interestRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancingModelDto
     */
    'monthlyRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof FinancingModelDto
     */
    'monthlyRateInvoiceUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingModelDto
     */
    'monthlyRateQrUrl'?: string;
}
/**
 * 
 * @export
 * @interface FourEyeCheckData
 */
export interface FourEyeCheckData {
    /**
     * 
     * @type {string}
     * @memberof FourEyeCheckData
     */
    'customerUUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof FourEyeCheckData
     */
    'currentState'?: string;
    /**
     * 
     * @type {KYCResultsData}
     * @memberof FourEyeCheckData
     */
    'kYCResultsData'?: KYCResultsData;
    /**
     * 
     * @type {string}
     * @memberof FourEyeCheckData
     */
    'offerUrl'?: string;
    /**
     * 
     * @type {CreditCheckResultsData}
     * @memberof FourEyeCheckData
     */
    'creditCheckData'?: CreditCheckResultsData;
}
/**
 * 
 * @export
 * @interface FourEyeCheckDecision
 */
export interface FourEyeCheckDecision {
    /**
     * 
     * @type {boolean}
     * @memberof FourEyeCheckDecision
     */
    'approved': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof FourEyeCheckDecision
     */
    'fileNames': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FourEyeCheckDecision
     */
    'comment': string;
}
/**
 * Gender enum
 * @export
 * @enum {string}
 */

export const Gender = {
    Female: 'FEMALE',
    Male: 'MALE',
    Other: 'OTHER'
} as const;

export type Gender = typeof Gender[keyof typeof Gender];


/**
 * 
 * @export
 * @interface GeneralExpenditures
 */
export interface GeneralExpenditures {
    /**
     * 
     * @type {Expenditures}
     * @memberof GeneralExpenditures
     */
    'applicantExpenses'?: Expenditures;
    /**
     * 
     * @type {Expenditures}
     * @memberof GeneralExpenditures
     */
    'partnerExpenses'?: Expenditures;
}
/**
 * 
 * @export
 * @interface GetProjectOverview200Response
 */
export interface GetProjectOverview200Response {
    /**
     * The page number which is retrieved.
     * @type {number}
     * @memberof GetProjectOverview200Response
     */
    'page'?: number;
    /**
     * The number of items per page.
     * @type {number}
     * @memberof GetProjectOverview200Response
     */
    'size'?: number;
    /**
     * Represents the number of total items.
     * @type {number}
     * @memberof GetProjectOverview200Response
     */
    'totalElements'?: number;
    /**
     * Represents a total number of pages.
     * @type {number}
     * @memberof GetProjectOverview200Response
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<ProjectOverview>}
     * @memberof GetProjectOverview200Response
     */
    'content'?: Array<ProjectOverview>;
}
/**
 * 
 * @export
 * @interface HouseholdData
 */
export interface HouseholdData {
    /**
     * 
     * @type {HouseholdType}
     * @memberof HouseholdData
     */
    'householdType'?: HouseholdType | null;
    /**
     * 
     * @type {number}
     * @memberof HouseholdData
     */
    'householdNumberOfSupportedGrownUps'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HouseholdData
     */
    'householdMonthlyInsuranceFee'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof HouseholdData
     */
    'childrenInHouseholdAge': Array<number>;
}


/**
 * 
 * @export
 * @interface HouseholdPartnerData
 */
export interface HouseholdPartnerData {
    /**
     * 
     * @type {string}
     * @memberof HouseholdPartnerData
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof HouseholdPartnerData
     */
    'gender'?: Gender;
    /**
     * 
     * @type {MaritalStatus}
     * @memberof HouseholdPartnerData
     */
    'maritalStatus'?: MaritalStatus | null;
    /**
     * 
     * @type {Address}
     * @memberof HouseholdPartnerData
     */
    'address'?: Address;
    /**
     * 
     * @type {EmploymentStatus}
     * @memberof HouseholdPartnerData
     */
    'employmentStatus'?: EmploymentStatus | null;
    /**
     * 
     * @type {CreditCheckFinancialData}
     * @memberof HouseholdPartnerData
     */
    'financialData'?: CreditCheckFinancialData;
}


/**
 * HouseholdType enum
 * @export
 * @enum {string}
 */

export const HouseholdType = {
    SingleParent: 'SINGLE_PARENT',
    Single: 'SINGLE',
    SingleHouseholdCommunity: 'SINGLE_HOUSEHOLD_COMMUNITY',
    MarriedCouple: 'MARRIED_COUPLE',
    SingleSupportingGrownupInSameHousehold: 'SINGLE_SUPPORTING_GROWNUP_IN_SAME_HOUSEHOLD',
    SingleParentHouseholdCommunity: 'SINGLE_PARENT_HOUSEHOLD_COMMUNITY'
} as const;

export type HouseholdType = typeof HouseholdType[keyof typeof HouseholdType];


/**
 * 
 * @export
 * @interface IdentData
 */
export interface IdentData {
    /**
     * 
     * @type {KycIdentificationProcess}
     * @memberof IdentData
     */
    'identificationprocess'?: KycIdentificationProcess;
    /**
     * 
     * @type {KycCustomData}
     * @memberof IdentData
     */
    'customdata'?: KycCustomData;
    /**
     * 
     * @type {KycContactData}
     * @memberof IdentData
     */
    'contactdata'?: KycContactData;
    /**
     * 
     * @type {KycUserdata}
     * @memberof IdentData
     */
    'userdata'?: KycUserdata;
    /**
     * 
     * @type {KycIdentificationDocument}
     * @memberof IdentData
     */
    'identificationdocument'?: KycIdentificationDocument;
    /**
     * 
     * @type {KycAttachments}
     * @memberof IdentData
     */
    'attachments'?: KycAttachments;
}
/**
 * 
 * @export
 * @interface InboxMessageDto
 */
export interface InboxMessageDto {
    /**
     * 
     * @type {number}
     * @memberof InboxMessageDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof InboxMessageDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessageDto
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessageDto
     */
    'plainContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessageDto
     */
    'values'?: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessageDto
     */
    'sentTimestamp'?: string;
}
/**
 * 
 * @export
 * @interface InstallationBuilding
 */
export interface InstallationBuilding {
    /**
     * 
     * @type {string}
     * @memberof InstallationBuilding
     */
    'sepId': string;
    /**
     * 
     * @type {number}
     * @memberof InstallationBuilding
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof InstallationBuilding
     */
    'longitude': number;
    /**
     * 
     * @type {Address}
     * @memberof InstallationBuilding
     */
    'address': Address;
}
/**
 * 
 * @export
 * @interface InstallationCompany
 */
export interface InstallationCompany {
    /**
     * 
     * @type {string}
     * @memberof InstallationCompany
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof InstallationCompany
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof InstallationCompany
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof InstallationCompany
     */
    'uid': string;
    /**
     * 
     * @type {Address}
     * @memberof InstallationCompany
     */
    'address': Address;
}
/**
 * 
 * @export
 * @interface InstallationLoan
 */
export interface InstallationLoan {
    /**
     * 
     * @type {string}
     * @memberof InstallationLoan
     */
    'deposit': string;
    /**
     * 
     * @type {string}
     * @memberof InstallationLoan
     */
    'termYears': string;
}
/**
 * 
 * @export
 * @interface InstallationOffer
 */
export interface InstallationOffer {
    /**
     * 
     * @type {string}
     * @memberof InstallationOffer
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof InstallationOffer
     */
    'price': string;
}
/**
 * Offer create object
 * @export
 * @interface InstallationOfferCreate
 */
export interface InstallationOfferCreate {
    /**
     * 
     * @type {InstallationPersonalDetails}
     * @memberof InstallationOfferCreate
     */
    'personalDetails': InstallationPersonalDetails;
    /**
     * 
     * @type {InstallationPartner}
     * @memberof InstallationOfferCreate
     */
    'installationPartner': InstallationPartner;
    /**
     * 
     * @type {InstallationCompany}
     * @memberof InstallationOfferCreate
     */
    'installationCompany': InstallationCompany;
    /**
     * 
     * @type {InstallationOffer}
     * @memberof InstallationOfferCreate
     */
    'offer': InstallationOffer;
    /**
     * 
     * @type {InstallationLoan}
     * @memberof InstallationOfferCreate
     */
    'loan': InstallationLoan;
    /**
     * 
     * @type {string}
     * @memberof InstallationOfferCreate
     */
    'filePath': string;
}
/**
 * 
 * @export
 * @interface InstallationPartner
 */
export interface InstallationPartner {
    /**
     * 
     * @type {string}
     * @memberof InstallationPartner
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof InstallationPartner
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface InstallationPersonalDetails
 */
export interface InstallationPersonalDetails {
    /**
     * 
     * @type {string}
     * @memberof InstallationPersonalDetails
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof InstallationPersonalDetails
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof InstallationPersonalDetails
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof InstallationPersonalDetails
     */
    'phoneNumber': string;
    /**
     * 
     * @type {InstallationBuilding}
     * @memberof InstallationPersonalDetails
     */
    'building': InstallationBuilding;
}
/**
 * 
 * @export
 * @interface IntrumCreditScore
 */
export interface IntrumCreditScore {
    /**
     * 
     * @type {string}
     * @memberof IntrumCreditScore
     */
    'premiumConsumerScoreValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntrumCreditScore
     */
    'premiumConsumerScoreHitType'?: string;
}
/**
 * IntrumStatus enum
 * @export
 * @enum {string}
 */

export const IntrumStatus = {
    Start: 'START',
    Aborted: 'ABORTED',
    Canceled: 'CANCELED',
    Realtime: 'REALTIME',
    CheckPending: 'CHECK_PENDING',
    Success: 'SUCCESS',
    SuccessDataChanged: 'SUCCESS_DATA_CHANGED',
    FraudSuspicionConfirmed: 'FRAUD_SUSPICION_CONFIRMED'
} as const;

export type IntrumStatus = typeof IntrumStatus[keyof typeof IntrumStatus];


/**
 * A Token that provides all the required information to make authorized calls and to obtain fresh tokens.
 * @export
 * @interface IntrumToken
 */
export interface IntrumToken {
    /**
     * JWT token to use for subsequent access.
     * @type {string}
     * @memberof IntrumToken
     */
    'authToken'?: string;
}
/**
 * KYCData object
 * @export
 * @interface KYCData
 */
export interface KYCData {
    /**
     * 
     * @type {string}
     * @memberof KYCData
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof KYCData
     */
    'placeOfBirth'?: string;
    /**
     * 
     * @type {Gender}
     * @memberof KYCData
     */
    'gender'?: Gender;
    /**
     * 
     * @type {Country}
     * @memberof KYCData
     */
    'nationality'?: Country;
    /**
     * 
     * @type {Address}
     * @memberof KYCData
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof KYCData
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {BankDetails}
     * @memberof KYCData
     */
    'bankDetails'?: BankDetails;
}


/**
 * 
 * @export
 * @interface KYCResultsData
 */
export interface KYCResultsData {
    /**
     * 
     * @type {IntrumStatus}
     * @memberof KYCResultsData
     */
    'result'?: IntrumStatus;
    /**
     * 
     * @type {PersonalDataKYC}
     * @memberof KYCResultsData
     */
    'personalDataKYC'?: PersonalDataKYC;
}


/**
 * 
 * @export
 * @interface KremoChild
 */
export interface KremoChild {
    /**
     * 
     * @type {number}
     * @memberof KremoChild
     */
    'ageAtEndOfYear'?: number;
    /**
     * 
     * @type {string}
     * @memberof KremoChild
     */
    'subsistenceMinimum'?: string;
}
/**
 * 
 * @export
 * @interface KremoData
 */
export interface KremoData {
    /**
     * 
     * @type {Array<ExemptAmount>}
     * @memberof KremoData
     */
    'exemptAmountList'?: Array<ExemptAmount>;
    /**
     * 
     * @type {KremoHouseholdExpenditures}
     * @memberof KremoData
     */
    'kremoHouseholdExpenditures'?: KremoHouseholdExpenditures;
    /**
     * 
     * @type {GeneralExpenditures}
     * @memberof KremoData
     */
    'generalExpenses'?: GeneralExpenditures;
    /**
     * 
     * @type {Array<KremoChild>}
     * @memberof KremoData
     */
    'childrenList'?: Array<KremoChild>;
}
/**
 * 
 * @export
 * @interface KremoHouseholdExpenditures
 */
export interface KremoHouseholdExpenditures {
    /**
     * 
     * @type {string}
     * @memberof KremoHouseholdExpenditures
     */
    'healthInsuranceFee'?: string;
    /**
     * 
     * @type {string}
     * @memberof KremoHouseholdExpenditures
     */
    'subsistenceMinimum'?: string;
}
/**
 * 
 * @export
 * @interface KycAddress
 */
export interface KycAddress {
    /**
     * 
     * @type {StatusValueOriginal}
     * @memberof KycAddress
     */
    'streetnumber'?: StatusValueOriginal;
    /**
     * 
     * @type {StatusValueOriginal}
     * @memberof KycAddress
     */
    'street'?: StatusValueOriginal;
    /**
     * 
     * @type {StatusValueOriginal}
     * @memberof KycAddress
     */
    'city'?: StatusValueOriginal;
    /**
     * 
     * @type {StatusValueOriginal}
     * @memberof KycAddress
     */
    'country'?: StatusValueOriginal;
    /**
     * 
     * @type {StatusValueOriginal}
     * @memberof KycAddress
     */
    'zipcode'?: StatusValueOriginal;
}
/**
 * 
 * @export
 * @interface KycAttachments
 */
export interface KycAttachments {
    /**
     * 
     * @type {string}
     * @memberof KycAttachments
     */
    'pdf'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycAttachments
     */
    'xml'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycAttachments
     */
    'idbackside'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycAttachments
     */
    'idfrontside'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycAttachments
     */
    'security1'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycAttachments
     */
    'userface'?: string;
}
/**
 * 
 * @export
 * @interface KycContactData
 */
export interface KycContactData {
    /**
     * 
     * @type {string}
     * @memberof KycContactData
     */
    'identlanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycContactData
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface KycCustomData
 */
export interface KycCustomData {
    /**
     * 
     * @type {string}
     * @memberof KycCustomData
     */
    'custom3'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycCustomData
     */
    'custom4'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycCustomData
     */
    'custom1'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycCustomData
     */
    'custom2'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycCustomData
     */
    'custom5'?: string;
}
/**
 * 
 * @export
 * @interface KycIdentificationDocument
 */
export interface KycIdentificationDocument {
    /**
     * 
     * @type {StatusValueOriginal}
     * @memberof KycIdentificationDocument
     */
    'country'?: StatusValueOriginal;
    /**
     * 
     * @type {StatusValueOriginal}
     * @memberof KycIdentificationDocument
     */
    'number'?: StatusValueOriginal;
    /**
     * 
     * @type {StatusValueOriginal}
     * @memberof KycIdentificationDocument
     */
    'issuedby'?: StatusValueOriginal;
    /**
     * 
     * @type {StatusValueOriginal}
     * @memberof KycIdentificationDocument
     */
    'dateissued'?: StatusValueOriginal;
    /**
     * 
     * @type {StatusValueOriginal}
     * @memberof KycIdentificationDocument
     */
    'type'?: StatusValueOriginal;
    /**
     * 
     * @type {StatusValueOriginal}
     * @memberof KycIdentificationDocument
     */
    'validuntil'?: StatusValueOriginal;
}
/**
 * 
 * @export
 * @interface KycIdentificationProcess
 */
export interface KycIdentificationProcess {
    /**
     * 
     * @type {string}
     * @memberof KycIdentificationProcess
     */
    'result'?: KycIdentificationProcessResultEnum;
    /**
     * 
     * @type {string}
     * @memberof KycIdentificationProcess
     */
    'reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycIdentificationProcess
     */
    'companyid'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycIdentificationProcess
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycIdentificationProcess
     */
    'identificationtime'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycIdentificationProcess
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycIdentificationProcess
     */
    'href'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycIdentificationProcess
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof KycIdentificationProcess
     */
    'transactionnumber'?: string;
}

export const KycIdentificationProcessResultEnum = {
    Success: 'SUCCESS',
    Pending: 'PENDING',
    Failed: 'FAILED'
} as const;

export type KycIdentificationProcessResultEnum = typeof KycIdentificationProcessResultEnum[keyof typeof KycIdentificationProcessResultEnum];

/**
 * KYC Status enum
 * @export
 * @enum {string}
 */

export const KycStatus = {
    KycOpen: 'KYC_OPEN',
    KkgFinal: 'KKG_FINAL',
    AmlFinal: 'AML_FINAL',
    FirstCheckSuccess: 'FIRST_CHECK_SUCCESS',
    FirstCheckFail: 'FIRST_CHECK_FAIL',
    SecondCheckSuccess: 'SECOND_CHECK_SUCCESS',
    SecondCheckFail: 'SECOND_CHECK_FAIL',
    KycSuccess: 'KYC_SUCCESS',
    KycFail: 'KYC_FAIL',
    Admonished: 'ADMONISHED'
} as const;

export type KycStatus = typeof KycStatus[keyof typeof KycStatus];


/**
 * 
 * @export
 * @interface KycUserdata
 */
export interface KycUserdata {
    /**
     * 
     * @type {StatusValueOriginal}
     * @memberof KycUserdata
     */
    'birthday'?: StatusValueOriginal;
    /**
     * 
     * @type {StatusValueOriginal}
     * @memberof KycUserdata
     */
    'firstname'?: StatusValueOriginal;
    /**
     * 
     * @type {KycAddress}
     * @memberof KycUserdata
     */
    'address'?: KycAddress;
    /**
     * 
     * @type {StatusValueOriginal}
     * @memberof KycUserdata
     */
    'birthplace'?: StatusValueOriginal;
    /**
     * 
     * @type {StatusValueOriginal}
     * @memberof KycUserdata
     */
    'nationality'?: StatusValueOriginal;
    /**
     * 
     * @type {StatusValueOriginal}
     * @memberof KycUserdata
     */
    'gender'?: StatusValueOriginal;
    /**
     * 
     * @type {StatusValueOriginal}
     * @memberof KycUserdata
     */
    'identlanguage'?: StatusValueOriginal;
    /**
     * 
     * @type {StatusValueOriginal}
     * @memberof KycUserdata
     */
    'lastname'?: StatusValueOriginal;
}
/**
 * 
 * @export
 * @interface LegalFormDto
 */
export interface LegalFormDto {
    /**
     * 
     * @type {number}
     * @memberof LegalFormDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LegalFormDto
     */
    'uid'?: string;
    /**
     * 
     * @type {ShortNameDto}
     * @memberof LegalFormDto
     */
    'shortName'?: ShortNameDto;
}
/**
 * Required login request params.
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'grant_type': LoginGrantTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'password': string;
}

export const LoginGrantTypeEnum = {
    Password: 'password'
} as const;

export type LoginGrantTypeEnum = typeof LoginGrantTypeEnum[keyof typeof LoginGrantTypeEnum];

/**
 * Required login request params.
 * @export
 * @interface LoginSimple
 */
export interface LoginSimple {
    /**
     * 
     * @type {string}
     * @memberof LoginSimple
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof LoginSimple
     */
    'password': string;
}
/**
 * Required logout request params.
 * @export
 * @interface Logout
 */
export interface Logout {
    /**
     * 
     * @type {string}
     * @memberof Logout
     */
    'refresh_token': string;
}
/**
 * MaritalStatus enum
 * @export
 * @enum {string}
 */

export const MaritalStatus = {
    Married: 'MARRIED',
    Single: 'SINGLE',
    Divorced: 'DIVORCED',
    Widowed: 'WIDOWED',
    Separated: 'SEPARATED',
    Registered: 'REGISTERED',
    JuridicalResolved: 'JURIDICAL_RESOLVED'
} as const;

export type MaritalStatus = typeof MaritalStatus[keyof typeof MaritalStatus];


/**
 * 
 * @export
 * @interface Module
 */
export interface Module {
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    'id'?: number;
    /**
     * 
     * @type {ProductType}
     * @memberof Module
     */
    'type'?: ProductType;
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    'value'?: number;
}


/**
 * 
 * @export
 * @interface ModuleDto
 */
export interface ModuleDto {
    /**
     * 
     * @type {number}
     * @memberof ModuleDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleDto
     */
    'type'?: ModuleDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ModuleDto
     */
    'value'?: number;
}

export const ModuleDtoTypeEnum = {
    EChargingStation: 'E_CHARGING_STATION',
    HeatPump: 'HEAT_PUMP',
    StorageSolution: 'STORAGE_SOLUTION',
    InvesterraElectricitySales: 'INVESTERRA_ELECTRICITY_SALES',
    SolarSystem: 'SOLAR_SYSTEM'
} as const;

export type ModuleDtoTypeEnum = typeof ModuleDtoTypeEnum[keyof typeof ModuleDtoTypeEnum];

/**
 * Specific error for oAuth flows
 * @export
 * @interface OAuthError
 */
export interface OAuthError {
    /**
     * Unique error code
     * @type {string}
     * @memberof OAuthError
     */
    'error': OAuthErrorErrorEnum;
    /**
     * Human-readable description
     * @type {string}
     * @memberof OAuthError
     */
    'error_description': string;
}

export const OAuthErrorErrorEnum = {
    InvalidRequest: 'invalid_request',
    InvalidClient: 'invalid_client',
    InvalidGrant: 'invalid_grant',
    InvalidScope: 'invalid_scope',
    UnauthorizedClient: 'unauthorized_client',
    UnsupportedGrantType: 'unsupported_grant_type'
} as const;

export type OAuthErrorErrorEnum = typeof OAuthErrorErrorEnum[keyof typeof OAuthErrorErrorEnum];

/**
 * 
 * @export
 * @interface OfferDto
 */
export interface OfferDto {
    /**
     * 
     * @type {number}
     * @memberof OfferDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    'sentTimestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    'responseTimestamp'?: string;
    /**
     * 
     * @type {OfferStatus}
     * @memberof OfferDto
     */
    'status'?: OfferStatus;
    /**
     * 
     * @type {number}
     * @memberof OfferDto
     */
    'offeredPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    'validThru'?: string;
    /**
     * 
     * @type {PartnerDto}
     * @memberof OfferDto
     */
    'partner'?: PartnerDto;
    /**
     * 
     * @type {ProfileDto}
     * @memberof OfferDto
     */
    'contact'?: ProfileDto;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof OfferDto
     */
    'area'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    'issuanceDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof OfferDto
     */
    'performance'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferDto
     */
    'subsidy'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    'documentUrl'?: string;
}


/**
 * Offer Status enum
 * @export
 * @enum {string}
 */

export const OfferStatus = {
    Pending: 'PENDING',
    Received: 'RECEIVED',
    Accepted: 'ACCEPTED',
    Declined: 'DECLINED',
    Expired: 'EXPIRED',
    Created: 'CREATED'
} as const;

export type OfferStatus = typeof OfferStatus[keyof typeof OfferStatus];


/**
 * 
 * @export
 * @interface PageInboxMessageDto
 */
export interface PageInboxMessageDto {
    /**
     * 
     * @type {number}
     * @memberof PageInboxMessageDto
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInboxMessageDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInboxMessageDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<InboxMessageDto>}
     * @memberof PageInboxMessageDto
     */
    'content'?: Array<InboxMessageDto>;
    /**
     * 
     * @type {number}
     * @memberof PageInboxMessageDto
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageInboxMessageDto
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageInboxMessageDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageInboxMessageDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageInboxMessageDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInboxMessageDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInboxMessageDto
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'pageSize'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof Pageable
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'offset'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    'paged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    'unpaged'?: boolean;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageableObject
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
}
/**
 * 
 * @export
 * @interface PageableResponse
 */
export interface PageableResponse {
    /**
     * 
     * @type {Pageable}
     * @memberof PageableResponse
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageableResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageableResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableResponse
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageableResponse
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageableResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageableResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PaginatedResponse
 */
export interface PaginatedResponse {
    /**
     * The page number which is retrieved.
     * @type {number}
     * @memberof PaginatedResponse
     */
    'page'?: number;
    /**
     * The number of items per page.
     * @type {number}
     * @memberof PaginatedResponse
     */
    'size'?: number;
    /**
     * Represents the number of total items.
     * @type {number}
     * @memberof PaginatedResponse
     */
    'totalElements'?: number;
    /**
     * Represents a total number of pages.
     * @type {number}
     * @memberof PaginatedResponse
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PartnerDto
 */
export interface PartnerDto {
    /**
     * 
     * @type {number}
     * @memberof PartnerDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerDto
     */
    'rating'?: number;
    /**
     * 
     * @type {Array<PartnerLocationDto>}
     * @memberof PartnerDto
     */
    'partnerLocations'?: Array<PartnerLocationDto>;
}
/**
 * 
 * @export
 * @interface PartnerGet
 */
export interface PartnerGet {
    /**
     * 
     * @type {number}
     * @memberof PartnerGet
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'uid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'legalForm'?: string;
    /**
     * 
     * @type {Array<PartnerLocation>}
     * @memberof PartnerGet
     */
    'partnerLocations'?: Array<PartnerLocation>;
    /**
     * 
     * @type {Array<Canton>}
     * @memberof PartnerGet
     */
    'cantons'?: Array<Canton>;
    /**
     * 
     * @type {Array<Module>}
     * @memberof PartnerGet
     */
    'modules'?: Array<Module>;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'contactFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'contactLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'contactEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'contactPhoneNumber'?: string;
    /**
     * 
     * @type {PartnerType}
     * @memberof PartnerGet
     */
    'type'?: PartnerType;
    /**
     * 
     * @type {Profile}
     * @memberof PartnerGet
     */
    'profile'?: Profile;
    /**
     * 
     * @type {PartnerLevel}
     * @memberof PartnerGet
     */
    'partnerLevel'?: PartnerLevel;
}


/**
 * Partner level enum
 * @export
 * @enum {string}
 */

export const PartnerLevel = {
    Starter: 'STARTER',
    VoltOn: 'VOLT_ON',
    HeavyCurrent: 'HEAVY_CURRENT',
    PowerPlant: 'POWER_PLANT'
} as const;

export type PartnerLevel = typeof PartnerLevel[keyof typeof PartnerLevel];


/**
 * 
 * @export
 * @interface PartnerLocation
 */
export interface PartnerLocation {
    /**
     * 
     * @type {string}
     * @memberof PartnerLocation
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerLocation
     */
    'municipality'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerLocation
     */
    'postalCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerLocation
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerLocation
     */
    'longitude'?: number;
}
/**
 * 
 * @export
 * @interface PartnerLocationDto
 */
export interface PartnerLocationDto {
    /**
     * 
     * @type {string}
     * @memberof PartnerLocationDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerLocationDto
     */
    'municipality'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerLocationDto
     */
    'postalCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerLocationDto
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerLocationDto
     */
    'longitude'?: number;
}
/**
 * 
 * @export
 * @interface PartnerOfferDto
 */
export interface PartnerOfferDto {
    /**
     * 
     * @type {number}
     * @memberof PartnerOfferDto
     */
    'offeredPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerOfferDto
     */
    'validThru'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerOfferDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerOfferDto
     */
    'area'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerOfferDto
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerOfferDto
     */
    'issuanceDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerOfferDto
     */
    'performance'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerOfferDto
     */
    'subsidy'?: number;
    /**
     * 
     * @type {ProfileDto}
     * @memberof PartnerOfferDto
     */
    'contact'?: ProfileDto;
}
/**
 * 
 * @export
 * @interface PartnerOfferSearchResponse
 */
export interface PartnerOfferSearchResponse {
    /**
     * 
     * @type {Pageable}
     * @memberof PartnerOfferSearchResponse
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerOfferSearchResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PartnerOfferSearchResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerOfferSearchResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerOfferSearchResponse
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerOfferSearchResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PartnerOfferSearchResponse
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerOfferSearchResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PartnerOfferSearchResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerOfferSearchResponse
     */
    'empty'?: boolean;
    /**
     * 
     * @type {Array<PartnerOfferSearchResultDto>}
     * @memberof PartnerOfferSearchResponse
     */
    'content'?: Array<PartnerOfferSearchResultDto>;
}
/**
 * 
 * @export
 * @interface PartnerOfferSearchResultDto
 */
export interface PartnerOfferSearchResultDto {
    /**
     * 
     * @type {number}
     * @memberof PartnerOfferSearchResultDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerOfferSearchResultDto
     */
    'sentTimestamp'?: string;
    /**
     * 
     * @type {OfferStatus}
     * @memberof PartnerOfferSearchResultDto
     */
    'status'?: OfferStatus;
    /**
     * 
     * @type {number}
     * @memberof PartnerOfferSearchResultDto
     */
    'offeredPrice'?: number;
    /**
     * 
     * @type {ProjectDto}
     * @memberof PartnerOfferSearchResultDto
     */
    'project'?: ProjectDto;
    /**
     * 
     * @type {PartnerLevel}
     * @memberof PartnerOfferSearchResultDto
     */
    'partnerLevel'?: PartnerLevel;
}


/**
 * 
 * @export
 * @interface PartnerSearchResponse
 */
export interface PartnerSearchResponse {
    /**
     * 
     * @type {number}
     * @memberof PartnerSearchResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerSearchResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerSearchResponse
     */
    'contactFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerSearchResponse
     */
    'contactLastName'?: string;
    /**
     * 
     * @type {PartnerType}
     * @memberof PartnerSearchResponse
     */
    'type'?: PartnerType;
    /**
     * 
     * @type {PartnerLevel}
     * @memberof PartnerSearchResponse
     */
    'partnerLevel'?: PartnerLevel;
    /**
     * 
     * @type {Array<Module>}
     * @memberof PartnerSearchResponse
     */
    'modules'?: Array<Module>;
}


/**
 * 
 * @export
 * @interface PartnerSearchResponsePageable
 */
export interface PartnerSearchResponsePageable {
    /**
     * The page number which is retrieved.
     * @type {number}
     * @memberof PartnerSearchResponsePageable
     */
    'page'?: number;
    /**
     * The number of items per page.
     * @type {number}
     * @memberof PartnerSearchResponsePageable
     */
    'size'?: number;
    /**
     * Represents the number of total items.
     * @type {number}
     * @memberof PartnerSearchResponsePageable
     */
    'totalElements'?: number;
    /**
     * Represents a total number of pages.
     * @type {number}
     * @memberof PartnerSearchResponsePageable
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<PartnerSearchResponse>}
     * @memberof PartnerSearchResponsePageable
     */
    'content'?: Array<PartnerSearchResponse>;
}
/**
 * Type of partner
 * @export
 * @enum {string}
 */

export const PartnerType = {
    SalesPartner: 'SALES_PARTNER',
    InstallationPartner: 'INSTALLATION_PARTNER'
} as const;

export type PartnerType = typeof PartnerType[keyof typeof PartnerType];


/**
 * 
 * @export
 * @interface PartnerUpdate
 */
export interface PartnerUpdate {
    /**
     * 
     * @type {string}
     * @memberof PartnerUpdate
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerUpdate
     */
    'rating'?: number;
    /**
     * 
     * @type {Array<PartnerLocation>}
     * @memberof PartnerUpdate
     */
    'partnerLocations'?: Array<PartnerLocation>;
    /**
     * 
     * @type {Array<Canton>}
     * @memberof PartnerUpdate
     */
    'cantons'?: Array<Canton>;
    /**
     * 
     * @type {Array<Module>}
     * @memberof PartnerUpdate
     */
    'modules'?: Array<Module>;
}
/**
 * ResidenceType enum (deliberately restricted to only those allowed)
 * @export
 * @enum {string}
 */

export const PermitType = {
    B: 'B',
    BEu: 'B_EU',
    C: 'C',
    CEu: 'C_EU'
} as const;

export type PermitType = typeof PermitType[keyof typeof PermitType];


/**
 * 
 * @export
 * @interface PersonalDataCreditCheck
 */
export interface PersonalDataCreditCheck {
    /**
     * 
     * @type {MaritalStatus}
     * @memberof PersonalDataCreditCheck
     */
    'maritalStatus'?: MaritalStatus | null;
    /**
     * 
     * @type {HouseholdType}
     * @memberof PersonalDataCreditCheck
     */
    'householdType'?: HouseholdType | null;
    /**
     * 
     * @type {string}
     * @memberof PersonalDataCreditCheck
     */
    'householdMonthlyInsuranceFee'?: string;
    /**
     * 
     * @type {ResidenceType}
     * @memberof PersonalDataCreditCheck
     */
    'residenceType'?: ResidenceType | null;
}


/**
 * 
 * @export
 * @interface PersonalDataKYC
 */
export interface PersonalDataKYC {
    /**
     * 
     * @type {string}
     * @memberof PersonalDataKYC
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalDataKYC
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalDataKYC
     */
    'gender'?: PersonalDataKYCGenderEnum;
    /**
     * 
     * @type {Country}
     * @memberof PersonalDataKYC
     */
    'nationality'?: Country;
    /**
     * 
     * @type {Address}
     * @memberof PersonalDataKYC
     */
    'address'?: Address;
}

export const PersonalDataKYCGenderEnum = {
    Male: 'Male',
    Female: 'Female',
    Other: 'Other'
} as const;

export type PersonalDataKYCGenderEnum = typeof PersonalDataKYCGenderEnum[keyof typeof PersonalDataKYCGenderEnum];

/**
 * ProductType enum
 * @export
 * @enum {string}
 */

export const ProductType = {
    EChargingStation: 'E_CHARGING_STATION',
    HeatPump: 'HEAT_PUMP',
    StorageSolution: 'STORAGE_SOLUTION',
    InvesterraElectricitySales: 'INVESTERRA_ELECTRICITY_SALES',
    SolarSystem: 'SOLAR_SYSTEM'
} as const;

export type ProductType = typeof ProductType[keyof typeof ProductType];


/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'userUuid': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {KycStatus}
     * @memberof Profile
     */
    'kycStatus'?: KycStatus;
    /**
     * 
     * @type {Address}
     * @memberof Profile
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'bankName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'bic'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'iBAN'?: string;
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    'partnerId'?: number;
}


/**
 * 
 * @export
 * @interface ProfileDto
 */
export interface ProfileDto {
    /**
     * 
     * @type {number}
     * @memberof ProfileDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    'userUuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {KycStatus}
     * @memberof ProfileDto
     */
    'kycStatus'?: KycStatus;
    /**
     * 
     * @type {AddressDto}
     * @memberof ProfileDto
     */
    'address'?: AddressDto;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    'bankName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    'bic'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    'iBAN'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfileDto
     */
    'partnerId'?: number;
}


/**
 * Project object
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'fourEyeCheckId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'currentState'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'loanAmount'?: string;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'loanTerm'?: number;
    /**
     * 
     * @type {Array<ProductType>}
     * @memberof Project
     */
    'productTypes'?: Array<ProductType>;
}
/**
 * Project create object
 * @export
 * @interface ProjectCreate
 */
export interface ProjectCreate {
    /**
     * 
     * @type {Project}
     * @memberof ProjectCreate
     */
    'project'?: Project;
    /**
     * 
     * @type {CreateProjectDto}
     * @memberof ProjectCreate
     */
    'createProjectDto'?: CreateProjectDto;
}
/**
 * 
 * @export
 * @interface ProjectDto
 */
export interface ProjectDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'status'?: ProjectDtoStatusEnum;
    /**
     * 
     * @type {ProfileDto}
     * @memberof ProjectDto
     */
    'owner'?: ProfileDto;
    /**
     * 
     * @type {Array<ModuleDto>}
     * @memberof ProjectDto
     */
    'modules'?: Array<ModuleDto>;
    /**
     * 
     * @type {FinancingModelDto}
     * @memberof ProjectDto
     */
    'financingModel'?: FinancingModelDto;
    /**
     * 
     * @type {BuildingDto}
     * @memberof ProjectDto
     */
    'building'?: BuildingDto;
    /**
     * 
     * @type {OfferDto}
     * @memberof ProjectDto
     */
    'offer'?: OfferDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'projectUuid'?: string;
}

export const ProjectDtoStatusEnum = {
    OfferRequested: 'OFFER_REQUESTED',
    OfferReceived: 'OFFER_RECEIVED',
    CheckOpen: 'CHECK_OPEN',
    CheckApproveRequested: 'CHECK_APPROVE_REQUESTED',
    CheckFailed: 'CHECK_FAILED',
    CheckDone: 'CHECK_DONE',
    ContractsIssued: 'CONTRACTS_ISSUED',
    ClientSigned: 'CLIENT_SIGNED',
    LoanOpen: 'LOAN_OPEN',
    LoanClosed: 'LOAN_CLOSED'
} as const;

export type ProjectDtoStatusEnum = typeof ProjectDtoStatusEnum[keyof typeof ProjectDtoStatusEnum];

/**
 * Project get object
 * @export
 * @interface ProjectGet
 */
export interface ProjectGet {
    /**
     * 
     * @type {Project}
     * @memberof ProjectGet
     */
    'project'?: Project;
    /**
     * 
     * @type {ProjectDto}
     * @memberof ProjectGet
     */
    'projectDto'?: ProjectDto;
}
/**
 * 
 * @export
 * @interface ProjectLogEntry
 */
export interface ProjectLogEntry {
    /**
     * 
     * @type {ProjectLogType}
     * @memberof ProjectLogEntry
     */
    'logType'?: ProjectLogType;
    /**
     * 
     * @type {string}
     * @memberof ProjectLogEntry
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectLogEntry
     */
    'partyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectLogEntry
     */
    'partyEmail'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectLogEntry
     */
    'files'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProjectLogEntry
     */
    'comment'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectLogType = {
    Created: 'CREATED',
    QuestionaireSubmitted: 'QUESTIONAIRE_SUBMITTED',
    FourEyeCheckRequested: 'FOUR_EYE_CHECK_REQUESTED',
    FourEyeCheckApproved: 'FOUR_EYE_CHECK_APPROVED',
    FourEyeCheckRejected: 'FOUR_EYE_CHECK_REJECTED',
    FourEyeCheckReopened: 'FOUR_EYE_CHECK_REOPENED',
    ProjectApproved: 'PROJECT_APPROVED',
    ProjectDeclined: 'PROJECT_DECLINED',
    ProjectReset: 'PROJECT_RESET',
    ProjectRetrigger: 'PROJECT_RETRIGGER'
} as const;

export type ProjectLogType = typeof ProjectLogType[keyof typeof ProjectLogType];


/**
 * 
 * @export
 * @interface ProjectOffersDto
 */
export interface ProjectOffersDto {
    /**
     * 
     * @type {ProjectDto}
     * @memberof ProjectOffersDto
     */
    'project'?: ProjectDto;
    /**
     * 
     * @type {Array<OfferDto>}
     * @memberof ProjectOffersDto
     */
    'offers'?: Array<OfferDto>;
}
/**
 * 
 * @export
 * @interface ProjectOverview
 */
export interface ProjectOverview {
    /**
     * 
     * @type {number}
     * @memberof ProjectOverview
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverview
     */
    'projectUuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverview
     */
    'creationTime'?: string;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof ProjectOverview
     */
    'status'?: ProjectStatus;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverview
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverview
     */
    'clientName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverview
     */
    'partnerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverview
     */
    'lastLogAuthor'?: string;
}


/**
 * 
 * @export
 * @interface ProjectOverviewDto
 */
export interface ProjectOverviewDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectOverviewDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverviewDto
     */
    'projectUuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverviewDto
     */
    'creationTime'?: string;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof ProjectOverviewDto
     */
    'status'?: ProjectStatus;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverviewDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverviewDto
     */
    'clientName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverviewDto
     */
    'partnerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverviewDto
     */
    'lastLogAuthor'?: string;
}


/**
 * 
 * @export
 * @interface ProjectOverviewResponse
 */
export interface ProjectOverviewResponse {
    /**
     * The page number which is retrieved.
     * @type {number}
     * @memberof ProjectOverviewResponse
     */
    'page'?: number;
    /**
     * The number of items per page.
     * @type {number}
     * @memberof ProjectOverviewResponse
     */
    'size'?: number;
    /**
     * Represents the number of total items.
     * @type {number}
     * @memberof ProjectOverviewResponse
     */
    'totalElements'?: number;
    /**
     * Represents a total number of pages.
     * @type {number}
     * @memberof ProjectOverviewResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<ProjectOverviewDto>}
     * @memberof ProjectOverviewResponse
     */
    'content'?: Array<ProjectOverviewDto>;
}
/**
 * Project Status enum
 * @export
 * @enum {string}
 */

export const ProjectStatus = {
    OfferRequested: 'OFFER_REQUESTED',
    OfferReceived: 'OFFER_RECEIVED',
    CheckOpen: 'CHECK_OPEN',
    CheckApproveRequested: 'CHECK_APPROVE_REQUESTED',
    CheckFailed: 'CHECK_FAILED',
    CheckDone: 'CHECK_DONE',
    ContractsIssued: 'CONTRACTS_ISSUED',
    ClientSigned: 'CLIENT_SIGNED',
    LoanOpen: 'LOAN_OPEN',
    LoanClosed: 'LOAN_CLOSED'
} as const;

export type ProjectStatus = typeof ProjectStatus[keyof typeof ProjectStatus];


/**
 * QuestionnaireData object
 * @export
 * @interface QuestionnaireData
 */
export interface QuestionnaireData {
    /**
     * 
     * @type {KYCData}
     * @memberof QuestionnaireData
     */
    'kycData'?: KYCData;
    /**
     * 
     * @type {CreditCheckQuestionnaireData}
     * @memberof QuestionnaireData
     */
    'creditCheckData'?: CreditCheckQuestionnaireData;
}
/**
 * Required refresh request params.
 * @export
 * @interface Refresh
 */
export interface Refresh {
    /**
     * 
     * @type {string}
     * @memberof Refresh
     */
    'grant_type': RefreshGrantTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Refresh
     */
    'refresh_token': string;
}

export const RefreshGrantTypeEnum = {
    RefreshToken: 'refresh_token'
} as const;

export type RefreshGrantTypeEnum = typeof RefreshGrantTypeEnum[keyof typeof RefreshGrantTypeEnum];

/**
 * Reset object
 * @export
 * @interface Reset
 */
export interface Reset {
    /**
     * 
     * @type {boolean}
     * @memberof Reset
     */
    'withKYC': boolean;
}
/**
 * ResidenceType enum
 * @export
 * @enum {string}
 */

export const ResidenceType = {
    Employer: 'EMPLOYER',
    Family: 'FAMILY',
    NoPermanentResidence: 'NO_PERMANENT_RESIDENCE',
    OwnerWithMortgage: 'OWNER_WITH_MORTGAGE',
    OwnerWithoutMortgage: 'OWNER_WITHOUT_MORTGAGE',
    Renter: 'RENTER'
} as const;

export type ResidenceType = typeof ResidenceType[keyof typeof ResidenceType];


/**
 * Project create object
 * @export
 * @interface ScannedProjectCreate
 */
export interface ScannedProjectCreate {
    /**
     * 
     * @type {Project}
     * @memberof ScannedProjectCreate
     */
    'project'?: Project;
    /**
     * 
     * @type {CreateScannedProjectDto}
     * @memberof ScannedProjectCreate
     */
    'createScannedProjectDto'?: CreateScannedProjectDto;
}
/**
 * 
 * @export
 * @interface ShortNameDto
 */
export interface ShortNameDto {
    /**
     * 
     * @type {string}
     * @memberof ShortNameDto
     */
    'de'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortNameDto
     */
    'fr'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortNameDto
     */
    'it'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortNameDto
     */
    'en'?: string;
}
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'unsorted'?: boolean;
}
/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'unsorted'?: boolean;
}
/**
 * 
 * @export
 * @interface StatusValueOriginal
 */
export interface StatusValueOriginal {
    /**
     * 
     * @type {string}
     * @memberof StatusValueOriginal
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusValueOriginal
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusValueOriginal
     */
    'original'?: string;
}
/**
 * TransportationType enum
 * @export
 * @enum {string}
 */

export const TransportationType = {
    None: 'NONE',
    Bicycle: 'BICYCLE',
    Moped: 'MOPED',
    Motorcycle: 'MOTORCYCLE',
    Car: 'CAR',
    Public: 'PUBLIC'
} as const;

export type TransportationType = typeof TransportationType[keyof typeof TransportationType];


/**
 * 
 * @export
 * @interface UpdateBankDetailsDto
 */
export interface UpdateBankDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateBankDetailsDto
     */
    'iBAN'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankDetailsDto
     */
    'bic'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankDetailsDto
     */
    'bankName'?: string;
}
/**
 * 
 * @export
 * @interface UpdateGlobalSpouseCommissionVariableDto
 */
export interface UpdateGlobalSpouseCommissionVariableDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateGlobalSpouseCommissionVariableDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateGlobalSpouseCommissionVariableDto
     */
    'brokeredValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateGlobalSpouseCommissionVariableDto
     */
    'orderVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateGlobalSpouseCommissionVariableDto
     */
    'loanAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateGlobalSpouseCommissionVariableDto
     */
    'paymentTerms'?: number;
}
/**
 * 
 * @export
 * @interface UpdateGlobalVariableDto
 */
export interface UpdateGlobalVariableDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateGlobalVariableDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateGlobalVariableDto
     */
    'value'?: number;
}
/**
 * User object
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'role': Array<string>;
}
/**
 * UserData object
 * @export
 * @interface UserData
 */
export interface UserData {
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'phoneNumber'?: string;
}
/**
 * User get object
 * @export
 * @interface UserGet
 */
export interface UserGet {
    /**
     * 
     * @type {User}
     * @memberof UserGet
     */
    'user': User;
    /**
     * 
     * @type {Profile}
     * @memberof UserGet
     */
    'profile': Profile;
}
/**
 * User update object
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {Address}
     * @memberof UserUpdate
     */
    'address'?: Address;
    /**
     * 
     * @type {BankDetails}
     * @memberof UserUpdate
     */
    'bankDetails'?: BankDetails;
    /**
     * 
     * @type {KycStatus}
     * @memberof UserUpdate
     */
    'kycStatus'?: KycStatus;
}


/**
 * 
 * @export
 * @interface ZEKHitData
 */
export interface ZEKHitData {
    /**
     * 
     * @type {string}
     * @memberof ZEKHitData
     */
    'clientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ZEKHitData
     */
    'zeKScoreValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof ZEKHitData
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ZEKHitData
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ZEKHitData
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {Gender}
     * @memberof ZEKHitData
     */
    'gender'?: Gender;
    /**
     * 
     * @type {string}
     * @memberof ZEKHitData
     */
    'fullAddress'?: string;
    /**
     * 
     * @type {Country}
     * @memberof ZEKHitData
     */
    'nationality'?: Country;
    /**
     * 
     * @type {string}
     * @memberof ZEKHitData
     */
    'personStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof ZEKHitData
     */
    'hitScore'?: string;
}


/**
 * 
 * @export
 * @interface ZekData
 */
export interface ZekData {
    /**
     * 
     * @type {string}
     * @memberof ZekData
     */
    'zekScore'?: string;
    /**
     * 
     * @type {Array<ZEKHitData>}
     * @memberof ZekData
     */
    'zekHits'?: Array<ZEKHitData>;
}

/**
 * CantonControllerApi - axios parameter creator
 * @export
 */
export const CantonControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll2: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cantons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CantonControllerApi - functional programming interface
 * @export
 */
export const CantonControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CantonControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll2(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll2(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CantonControllerApi.getAll2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CantonControllerApi - factory interface
 * @export
 */
export const CantonControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CantonControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll2(options?: any): AxiosPromise<object> {
            return localVarFp.getAll2(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CantonControllerApi - object-oriented interface
 * @export
 * @class CantonControllerApi
 * @extends {BaseAPI}
 */
export class CantonControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CantonControllerApi
     */
    public getAll2(options?: RawAxiosRequestConfig) {
        return CantonControllerApiFp(this.configuration).getAll2(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CompanyRegisterApi - axios parameter creator
 * @export
 */
export const CompanyRegisterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get company that match given name
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesByName: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getCompaniesByName', 'name', name)
            const localVarPath = `/company-register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyRegisterApi - functional programming interface
 * @export
 */
export const CompanyRegisterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyRegisterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get company that match given name
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesByName(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesByName(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyRegisterApi.getCompaniesByName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CompanyRegisterApi - factory interface
 * @export
 */
export const CompanyRegisterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyRegisterApiFp(configuration)
    return {
        /**
         * 
         * @summary Get company that match given name
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesByName(name: string, options?: any): AxiosPromise<Array<CompanyDto>> {
            return localVarFp.getCompaniesByName(name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyRegisterApi - object-oriented interface
 * @export
 * @class CompanyRegisterApi
 * @extends {BaseAPI}
 */
export class CompanyRegisterApi extends BaseAPI {
    /**
     * 
     * @summary Get company that match given name
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRegisterApi
     */
    public getCompaniesByName(name: string, options?: RawAxiosRequestConfig) {
        return CompanyRegisterApiFp(this.configuration).getCompaniesByName(name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ContractApi - axios parameter creator
 * @export
 */
export const ContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a Contract
         * @param {string} id the id of a protocol
         * @param {Array<File>} files 
         * @param {ContractCreate} contractCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContract: async (id: string, files: Array<File>, contractCreate: ContractCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createContract', 'id', id)
            // verify required parameter 'files' is not null or undefined
            assertParamExists('createContract', 'files', files)
            // verify required parameter 'contractCreate' is not null or undefined
            assertParamExists('createContract', 'contractCreate', contractCreate)
            const localVarPath = `/project/{id}/contract`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (contractCreate !== undefined) { 
                localVarFormParams.append('contractCreate', new Blob([JSON.stringify(contractCreate)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a contract document
         * @param {string} id the id of a protocol
         * @param {string} filePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchContractDocumentFile: async (id: string, filePath: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchContractDocumentFile', 'id', id)
            // verify required parameter 'filePath' is not null or undefined
            assertParamExists('fetchContractDocumentFile', 'filePath', filePath)
            const localVarPath = `/contract/{id}/document`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filePath !== undefined) {
                localVarQueryParameter['filePath'] = filePath;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a Contract by Id
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContractById', 'id', id)
            const localVarPath = `/contract/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all Contracts
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContracts: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContracts', 'id', id)
            const localVarPath = `/project/{id}/contract`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a Contract by Id
         * @param {string} id the id of a protocol
         * @param {Array<File>} [filename] 
         * @param {ContractUpdate} [contractUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContractById: async (id: string, filename?: Array<File>, contractUpdate?: ContractUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateContractById', 'id', id)
            const localVarPath = `/contract/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filename) {
                filename.forEach((element) => {
                    localVarFormParams.append('filename', element as any);
                })
            }

    
            if (contractUpdate !== undefined) { 
                localVarFormParams.append('contractUpdate', new Blob([JSON.stringify(contractUpdate)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractApi - functional programming interface
 * @export
 */
export const ContractApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a Contract
         * @param {string} id the id of a protocol
         * @param {Array<File>} files 
         * @param {ContractCreate} contractCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContract(id: string, files: Array<File>, contractCreate: ContractCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContract(id, files, contractCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractApi.createContract']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a contract document
         * @param {string} id the id of a protocol
         * @param {string} filePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchContractDocumentFile(id: string, filePath: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchContractDocumentFile(id, filePath, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractApi.fetchContractDocumentFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get a Contract by Id
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractApi.getContractById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get all Contracts
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContracts(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContractGet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContracts(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractApi.getContracts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update a Contract by Id
         * @param {string} id the id of a protocol
         * @param {Array<File>} [filename] 
         * @param {ContractUpdate} [contractUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContractById(id: string, filename?: Array<File>, contractUpdate?: ContractUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContractById(id, filename, contractUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractApi.updateContractById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ContractApi - factory interface
 * @export
 */
export const ContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractApiFp(configuration)
    return {
        /**
         * 
         * @summary create a Contract
         * @param {string} id the id of a protocol
         * @param {Array<File>} files 
         * @param {ContractCreate} contractCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContract(id: string, files: Array<File>, contractCreate: ContractCreate, options?: any): AxiosPromise<ContractGet> {
            return localVarFp.createContract(id, files, contractCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a contract document
         * @param {string} id the id of a protocol
         * @param {string} filePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchContractDocumentFile(id: string, filePath: string, options?: any): AxiosPromise<File> {
            return localVarFp.fetchContractDocumentFile(id, filePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a Contract by Id
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractById(id: string, options?: any): AxiosPromise<ContractGet> {
            return localVarFp.getContractById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all Contracts
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContracts(id: string, options?: any): AxiosPromise<Array<ContractGet>> {
            return localVarFp.getContracts(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a Contract by Id
         * @param {string} id the id of a protocol
         * @param {Array<File>} [filename] 
         * @param {ContractUpdate} [contractUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContractById(id: string, filename?: Array<File>, contractUpdate?: ContractUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateContractById(id, filename, contractUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractApi - object-oriented interface
 * @export
 * @class ContractApi
 * @extends {BaseAPI}
 */
export class ContractApi extends BaseAPI {
    /**
     * 
     * @summary create a Contract
     * @param {string} id the id of a protocol
     * @param {Array<File>} files 
     * @param {ContractCreate} contractCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public createContract(id: string, files: Array<File>, contractCreate: ContractCreate, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).createContract(id, files, contractCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a contract document
     * @param {string} id the id of a protocol
     * @param {string} filePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public fetchContractDocumentFile(id: string, filePath: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).fetchContractDocumentFile(id, filePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a Contract by Id
     * @param {string} id the id of a protocol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public getContractById(id: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).getContractById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all Contracts
     * @param {string} id the id of a protocol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public getContracts(id: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).getContracts(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a Contract by Id
     * @param {string} id the id of a protocol
     * @param {Array<File>} [filename] 
     * @param {ContractUpdate} [contractUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public updateContractById(id: string, filename?: Array<File>, contractUpdate?: ContractUpdate, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).updateContractById(id, filename, contractUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FinancialOverviewControllerApi - axios parameter creator
 * @export
 */
export const FinancialOverviewControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get financial overview
         * @param {FinancialOverviewInputsDto} financialOverviewInputsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialOverview: async (financialOverviewInputsDto: FinancialOverviewInputsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'financialOverviewInputsDto' is not null or undefined
            assertParamExists('financialOverview', 'financialOverviewInputsDto', financialOverviewInputsDto)
            const localVarPath = `/api/financial-overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(financialOverviewInputsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project\'s financial overview
         * @param {number} id 
         * @param {number} [solarPotential] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialOverviewForProject: async (id: number, solarPotential?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('financialOverviewForProject', 'id', id)
            const localVarPath = `/api/financial-overview/project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (solarPotential !== undefined) {
                localVarQueryParameter['solarPotential'] = solarPotential;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project\'s financial overview as partner
         * @param {number} id 
         * @param {number} [solarPotential] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialOverviewForProjectAsSpouse: async (id: number, solarPotential?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('financialOverviewForProjectAsSpouse', 'id', id)
            const localVarPath = `/api/financial-overview/partner/project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (solarPotential !== undefined) {
                localVarQueryParameter['solarPotential'] = solarPotential;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project\'s financial overview plan
         * @param {FinancialOverviewPlanInputsDto} financialOverviewPlanInputsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monthlyRate: async (financialOverviewPlanInputsDto: FinancialOverviewPlanInputsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'financialOverviewPlanInputsDto' is not null or undefined
            assertParamExists('monthlyRate', 'financialOverviewPlanInputsDto', financialOverviewPlanInputsDto)
            const localVarPath = `/api/financial-overview/plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(financialOverviewPlanInputsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FinancialOverviewControllerApi - functional programming interface
 * @export
 */
export const FinancialOverviewControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FinancialOverviewControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get financial overview
         * @param {FinancialOverviewInputsDto} financialOverviewInputsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financialOverview(financialOverviewInputsDto: FinancialOverviewInputsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.financialOverview(financialOverviewInputsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinancialOverviewControllerApi.financialOverview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get project\'s financial overview
         * @param {number} id 
         * @param {number} [solarPotential] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financialOverviewForProject(id: number, solarPotential?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.financialOverviewForProject(id, solarPotential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinancialOverviewControllerApi.financialOverviewForProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get project\'s financial overview as partner
         * @param {number} id 
         * @param {number} [solarPotential] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financialOverviewForProjectAsSpouse(id: number, solarPotential?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.financialOverviewForProjectAsSpouse(id, solarPotential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinancialOverviewControllerApi.financialOverviewForProjectAsSpouse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get project\'s financial overview plan
         * @param {FinancialOverviewPlanInputsDto} financialOverviewPlanInputsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monthlyRate(financialOverviewPlanInputsDto: FinancialOverviewPlanInputsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monthlyRate(financialOverviewPlanInputsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinancialOverviewControllerApi.monthlyRate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FinancialOverviewControllerApi - factory interface
 * @export
 */
export const FinancialOverviewControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FinancialOverviewControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get financial overview
         * @param {FinancialOverviewInputsDto} financialOverviewInputsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialOverview(financialOverviewInputsDto: FinancialOverviewInputsDto, options?: any): AxiosPromise<object> {
            return localVarFp.financialOverview(financialOverviewInputsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project\'s financial overview
         * @param {number} id 
         * @param {number} [solarPotential] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialOverviewForProject(id: number, solarPotential?: number, options?: any): AxiosPromise<object> {
            return localVarFp.financialOverviewForProject(id, solarPotential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project\'s financial overview as partner
         * @param {number} id 
         * @param {number} [solarPotential] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialOverviewForProjectAsSpouse(id: number, solarPotential?: number, options?: any): AxiosPromise<object> {
            return localVarFp.financialOverviewForProjectAsSpouse(id, solarPotential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project\'s financial overview plan
         * @param {FinancialOverviewPlanInputsDto} financialOverviewPlanInputsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monthlyRate(financialOverviewPlanInputsDto: FinancialOverviewPlanInputsDto, options?: any): AxiosPromise<object> {
            return localVarFp.monthlyRate(financialOverviewPlanInputsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FinancialOverviewControllerApi - object-oriented interface
 * @export
 * @class FinancialOverviewControllerApi
 * @extends {BaseAPI}
 */
export class FinancialOverviewControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get financial overview
     * @param {FinancialOverviewInputsDto} financialOverviewInputsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialOverviewControllerApi
     */
    public financialOverview(financialOverviewInputsDto: FinancialOverviewInputsDto, options?: RawAxiosRequestConfig) {
        return FinancialOverviewControllerApiFp(this.configuration).financialOverview(financialOverviewInputsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project\'s financial overview
     * @param {number} id 
     * @param {number} [solarPotential] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialOverviewControllerApi
     */
    public financialOverviewForProject(id: number, solarPotential?: number, options?: RawAxiosRequestConfig) {
        return FinancialOverviewControllerApiFp(this.configuration).financialOverviewForProject(id, solarPotential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project\'s financial overview as partner
     * @param {number} id 
     * @param {number} [solarPotential] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialOverviewControllerApi
     */
    public financialOverviewForProjectAsSpouse(id: number, solarPotential?: number, options?: RawAxiosRequestConfig) {
        return FinancialOverviewControllerApiFp(this.configuration).financialOverviewForProjectAsSpouse(id, solarPotential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project\'s financial overview plan
     * @param {FinancialOverviewPlanInputsDto} financialOverviewPlanInputsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialOverviewControllerApi
     */
    public monthlyRate(financialOverviewPlanInputsDto: FinancialOverviewPlanInputsDto, options?: RawAxiosRequestConfig) {
        return FinancialOverviewControllerApiFp(this.configuration).monthlyRate(financialOverviewPlanInputsDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FourEyeCheckApi - axios parameter creator
 * @export
 */
export const FourEyeCheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Decide on a four eye check for reviewers
         * @param {string} id the id of a protocol
         * @param {FourEyeCheckDecision} [fourEyeCheckDecision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fourEyeCheckDecision: async (id: string, fourEyeCheckDecision?: FourEyeCheckDecision, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fourEyeCheckDecision', 'id', id)
            const localVarPath = `/fourEyeCheck/{id}/decision`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fourEyeCheckDecision, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the Four Eye Check Data
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFourEyeCheckDataById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFourEyeCheckDataById', 'id', id)
            const localVarPath = `/fourEyeCheck/{id}/data`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a four eye check as declined by a super admin
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markDeclined: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markDeclined', 'id', id)
            const localVarPath = `/fourEyeCheck/{id}/mark-declined`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reopen the Four Eye Check to permit two reviewers to approve the Four Eye Check
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reopenFourEyeCheck: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reopenFourEyeCheck', 'id', id)
            const localVarPath = `/fourEyeCheck/{id}/reopen`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset Credit Check and/or KYC for a project (by a reviewer)
         * @param {string} id the id of a protocol
         * @param {Reset} reset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetProject: async (id: string, reset: Reset, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resetProject', 'id', id)
            // verify required parameter 'reset' is not null or undefined
            assertParamExists('resetProject', 'reset', reset)
            const localVarPath = `/fourEyeCheck/{id}/reset-project`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrigger Credit Check and/or KYC for a project (by a reviewer)
         * @param {string} id the id of a protocol
         * @param {Reset} reset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retriggerProject: async (id: string, reset: Reset, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retriggerProject', 'id', id)
            // verify required parameter 'reset' is not null or undefined
            assertParamExists('retriggerProject', 'reset', reset)
            const localVarPath = `/fourEyeCheck/{id}/retrigger-project`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a single document to be a part of the Reviewer decision
         * @param {string} id the id of a protocol
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument: async (id: string, file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadDocument', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadDocument', 'file', file)
            const localVarPath = `/fourEyeCheck/{id}/upload-document`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FourEyeCheckApi - functional programming interface
 * @export
 */
export const FourEyeCheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FourEyeCheckApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Decide on a four eye check for reviewers
         * @param {string} id the id of a protocol
         * @param {FourEyeCheckDecision} [fourEyeCheckDecision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fourEyeCheckDecision(id: string, fourEyeCheckDecision?: FourEyeCheckDecision, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fourEyeCheckDecision(id, fourEyeCheckDecision, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FourEyeCheckApi.fourEyeCheckDecision']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get the Four Eye Check Data
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFourEyeCheckDataById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FourEyeCheckData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFourEyeCheckDataById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FourEyeCheckApi.getFourEyeCheckDataById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Mark a four eye check as declined by a super admin
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markDeclined(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markDeclined(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FourEyeCheckApi.markDeclined']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reopen the Four Eye Check to permit two reviewers to approve the Four Eye Check
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reopenFourEyeCheck(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reopenFourEyeCheck(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FourEyeCheckApi.reopenFourEyeCheck']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reset Credit Check and/or KYC for a project (by a reviewer)
         * @param {string} id the id of a protocol
         * @param {Reset} reset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetProject(id: string, reset: Reset, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetProject(id, reset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FourEyeCheckApi.resetProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrigger Credit Check and/or KYC for a project (by a reviewer)
         * @param {string} id the id of a protocol
         * @param {Reset} reset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retriggerProject(id: string, reset: Reset, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retriggerProject(id, reset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FourEyeCheckApi.retriggerProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upload a single document to be a part of the Reviewer decision
         * @param {string} id the id of a protocol
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDocument(id: string, file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDocument(id, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FourEyeCheckApi.uploadDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FourEyeCheckApi - factory interface
 * @export
 */
export const FourEyeCheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FourEyeCheckApiFp(configuration)
    return {
        /**
         * 
         * @summary Decide on a four eye check for reviewers
         * @param {string} id the id of a protocol
         * @param {FourEyeCheckDecision} [fourEyeCheckDecision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fourEyeCheckDecision(id: string, fourEyeCheckDecision?: FourEyeCheckDecision, options?: any): AxiosPromise<void> {
            return localVarFp.fourEyeCheckDecision(id, fourEyeCheckDecision, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the Four Eye Check Data
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFourEyeCheckDataById(id: string, options?: any): AxiosPromise<FourEyeCheckData> {
            return localVarFp.getFourEyeCheckDataById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a four eye check as declined by a super admin
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markDeclined(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.markDeclined(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reopen the Four Eye Check to permit two reviewers to approve the Four Eye Check
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reopenFourEyeCheck(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.reopenFourEyeCheck(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset Credit Check and/or KYC for a project (by a reviewer)
         * @param {string} id the id of a protocol
         * @param {Reset} reset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetProject(id: string, reset: Reset, options?: any): AxiosPromise<void> {
            return localVarFp.resetProject(id, reset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrigger Credit Check and/or KYC for a project (by a reviewer)
         * @param {string} id the id of a protocol
         * @param {Reset} reset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retriggerProject(id: string, reset: Reset, options?: any): AxiosPromise<void> {
            return localVarFp.retriggerProject(id, reset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a single document to be a part of the Reviewer decision
         * @param {string} id the id of a protocol
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument(id: string, file: File, options?: any): AxiosPromise<void> {
            return localVarFp.uploadDocument(id, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FourEyeCheckApi - object-oriented interface
 * @export
 * @class FourEyeCheckApi
 * @extends {BaseAPI}
 */
export class FourEyeCheckApi extends BaseAPI {
    /**
     * 
     * @summary Decide on a four eye check for reviewers
     * @param {string} id the id of a protocol
     * @param {FourEyeCheckDecision} [fourEyeCheckDecision] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FourEyeCheckApi
     */
    public fourEyeCheckDecision(id: string, fourEyeCheckDecision?: FourEyeCheckDecision, options?: RawAxiosRequestConfig) {
        return FourEyeCheckApiFp(this.configuration).fourEyeCheckDecision(id, fourEyeCheckDecision, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the Four Eye Check Data
     * @param {string} id the id of a protocol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FourEyeCheckApi
     */
    public getFourEyeCheckDataById(id: string, options?: RawAxiosRequestConfig) {
        return FourEyeCheckApiFp(this.configuration).getFourEyeCheckDataById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a four eye check as declined by a super admin
     * @param {string} id the id of a protocol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FourEyeCheckApi
     */
    public markDeclined(id: string, options?: RawAxiosRequestConfig) {
        return FourEyeCheckApiFp(this.configuration).markDeclined(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reopen the Four Eye Check to permit two reviewers to approve the Four Eye Check
     * @param {string} id the id of a protocol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FourEyeCheckApi
     */
    public reopenFourEyeCheck(id: string, options?: RawAxiosRequestConfig) {
        return FourEyeCheckApiFp(this.configuration).reopenFourEyeCheck(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset Credit Check and/or KYC for a project (by a reviewer)
     * @param {string} id the id of a protocol
     * @param {Reset} reset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FourEyeCheckApi
     */
    public resetProject(id: string, reset: Reset, options?: RawAxiosRequestConfig) {
        return FourEyeCheckApiFp(this.configuration).resetProject(id, reset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrigger Credit Check and/or KYC for a project (by a reviewer)
     * @param {string} id the id of a protocol
     * @param {Reset} reset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FourEyeCheckApi
     */
    public retriggerProject(id: string, reset: Reset, options?: RawAxiosRequestConfig) {
        return FourEyeCheckApiFp(this.configuration).retriggerProject(id, reset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a single document to be a part of the Reviewer decision
     * @param {string} id the id of a protocol
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FourEyeCheckApi
     */
    public uploadDocument(id: string, file: File, options?: RawAxiosRequestConfig) {
        return FourEyeCheckApiFp(this.configuration).uploadDocument(id, file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GlobalVariablesControllerApi - axios parameter creator
 * @export
 */
export const GlobalVariablesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll1: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/global-variables`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCommissions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/global-variables/commissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} keyword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByName: async (keyword: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('getByName', 'keyword', keyword)
            const localVarPath = `/api/global-variables/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update global partner commission variables.
         * @param {Array<UpdateGlobalSpouseCommissionVariableDto>} updateGlobalSpouseCommissionVariableDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommissions: async (updateGlobalSpouseCommissionVariableDto: Array<UpdateGlobalSpouseCommissionVariableDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateGlobalSpouseCommissionVariableDto' is not null or undefined
            assertParamExists('updateCommissions', 'updateGlobalSpouseCommissionVariableDto', updateGlobalSpouseCommissionVariableDto)
            const localVarPath = `/api/global-variables/commissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGlobalSpouseCommissionVariableDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update global variable.
         * @param {Array<UpdateGlobalVariableDto>} updateGlobalVariableDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateValues: async (updateGlobalVariableDto: Array<UpdateGlobalVariableDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateGlobalVariableDto' is not null or undefined
            assertParamExists('updateValues', 'updateGlobalVariableDto', updateGlobalVariableDto)
            const localVarPath = `/api/global-variables`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGlobalVariableDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalVariablesControllerApi - functional programming interface
 * @export
 */
export const GlobalVariablesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalVariablesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll1(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll1(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlobalVariablesControllerApi.getAll1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCommissions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCommissions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlobalVariablesControllerApi.getAllCommissions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} keyword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByName(keyword: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByName(keyword, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlobalVariablesControllerApi.getByName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update global partner commission variables.
         * @param {Array<UpdateGlobalSpouseCommissionVariableDto>} updateGlobalSpouseCommissionVariableDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCommissions(updateGlobalSpouseCommissionVariableDto: Array<UpdateGlobalSpouseCommissionVariableDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCommissions(updateGlobalSpouseCommissionVariableDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlobalVariablesControllerApi.updateCommissions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update global variable.
         * @param {Array<UpdateGlobalVariableDto>} updateGlobalVariableDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateValues(updateGlobalVariableDto: Array<UpdateGlobalVariableDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateValues(updateGlobalVariableDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlobalVariablesControllerApi.updateValues']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GlobalVariablesControllerApi - factory interface
 * @export
 */
export const GlobalVariablesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalVariablesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll1(options?: any): AxiosPromise<object> {
            return localVarFp.getAll1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCommissions(options?: any): AxiosPromise<object> {
            return localVarFp.getAllCommissions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} keyword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByName(keyword: string, options?: any): AxiosPromise<object> {
            return localVarFp.getByName(keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update global partner commission variables.
         * @param {Array<UpdateGlobalSpouseCommissionVariableDto>} updateGlobalSpouseCommissionVariableDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommissions(updateGlobalSpouseCommissionVariableDto: Array<UpdateGlobalSpouseCommissionVariableDto>, options?: any): AxiosPromise<void> {
            return localVarFp.updateCommissions(updateGlobalSpouseCommissionVariableDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update global variable.
         * @param {Array<UpdateGlobalVariableDto>} updateGlobalVariableDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateValues(updateGlobalVariableDto: Array<UpdateGlobalVariableDto>, options?: any): AxiosPromise<void> {
            return localVarFp.updateValues(updateGlobalVariableDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GlobalVariablesControllerApi - object-oriented interface
 * @export
 * @class GlobalVariablesControllerApi
 * @extends {BaseAPI}
 */
export class GlobalVariablesControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariablesControllerApi
     */
    public getAll1(options?: RawAxiosRequestConfig) {
        return GlobalVariablesControllerApiFp(this.configuration).getAll1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariablesControllerApi
     */
    public getAllCommissions(options?: RawAxiosRequestConfig) {
        return GlobalVariablesControllerApiFp(this.configuration).getAllCommissions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} keyword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariablesControllerApi
     */
    public getByName(keyword: string, options?: RawAxiosRequestConfig) {
        return GlobalVariablesControllerApiFp(this.configuration).getByName(keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update global partner commission variables.
     * @param {Array<UpdateGlobalSpouseCommissionVariableDto>} updateGlobalSpouseCommissionVariableDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariablesControllerApi
     */
    public updateCommissions(updateGlobalSpouseCommissionVariableDto: Array<UpdateGlobalSpouseCommissionVariableDto>, options?: RawAxiosRequestConfig) {
        return GlobalVariablesControllerApiFp(this.configuration).updateCommissions(updateGlobalSpouseCommissionVariableDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update global variable.
     * @param {Array<UpdateGlobalVariableDto>} updateGlobalVariableDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariablesControllerApi
     */
    public updateValues(updateGlobalVariableDto: Array<UpdateGlobalVariableDto>, options?: RawAxiosRequestConfig) {
        return GlobalVariablesControllerApiFp(this.configuration).updateValues(updateGlobalVariableDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InboxMessageControllerApi - axios parameter creator
 * @export
 */
export const InboxMessageControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Method for getting all users emails
         * @param {string} email 
         * @param {number} [page] page number
         * @param {number} [size] items per page
         * @param {string} [sort] sort criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllEmailsByEmail: async (email: string, page?: number, size?: number, sort?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('findAllEmailsByEmail', 'email', email)
            const localVarPath = `/api/inbox-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InboxMessageControllerApi - functional programming interface
 * @export
 */
export const InboxMessageControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InboxMessageControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Method for getting all users emails
         * @param {string} email 
         * @param {number} [page] page number
         * @param {number} [size] items per page
         * @param {string} [sort] sort criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllEmailsByEmail(email: string, page?: number, size?: number, sort?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageInboxMessageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllEmailsByEmail(email, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InboxMessageControllerApi.findAllEmailsByEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InboxMessageControllerApi - factory interface
 * @export
 */
export const InboxMessageControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InboxMessageControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Method for getting all users emails
         * @param {string} email 
         * @param {number} [page] page number
         * @param {number} [size] items per page
         * @param {string} [sort] sort criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllEmailsByEmail(email: string, page?: number, size?: number, sort?: string, options?: any): AxiosPromise<PageInboxMessageDto> {
            return localVarFp.findAllEmailsByEmail(email, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InboxMessageControllerApi - object-oriented interface
 * @export
 * @class InboxMessageControllerApi
 * @extends {BaseAPI}
 */
export class InboxMessageControllerApi extends BaseAPI {
    /**
     * 
     * @summary Method for getting all users emails
     * @param {string} email 
     * @param {number} [page] page number
     * @param {number} [size] items per page
     * @param {string} [sort] sort criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxMessageControllerApi
     */
    public findAllEmailsByEmail(email: string, page?: number, size?: number, sort?: string, options?: RawAxiosRequestConfig) {
        return InboxMessageControllerApiFp(this.configuration).findAllEmailsByEmail(email, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InstallationOfferApi - axios parameter creator
 * @export
 */
export const InstallationOfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an Installation Offer
         * @param {InstallationOfferCreate} installationOfferCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInstallationOffer: async (installationOfferCreate: InstallationOfferCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'installationOfferCreate' is not null or undefined
            assertParamExists('createInstallationOffer', 'installationOfferCreate', installationOfferCreate)
            const localVarPath = `/installationOffer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(installationOfferCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload an Installation Offer Document
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installationOfferUpload: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('installationOfferUpload', 'file', file)
            const localVarPath = `/installationOffer/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstallationOfferApi - functional programming interface
 * @export
 */
export const InstallationOfferApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstallationOfferApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create an Installation Offer
         * @param {InstallationOfferCreate} installationOfferCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInstallationOffer(installationOfferCreate: InstallationOfferCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInstallationOffer(installationOfferCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InstallationOfferApi.createInstallationOffer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upload an Installation Offer Document
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async installationOfferUpload(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.installationOfferUpload(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InstallationOfferApi.installationOfferUpload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InstallationOfferApi - factory interface
 * @export
 */
export const InstallationOfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstallationOfferApiFp(configuration)
    return {
        /**
         * 
         * @summary Create an Installation Offer
         * @param {InstallationOfferCreate} installationOfferCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInstallationOffer(installationOfferCreate: InstallationOfferCreate, options?: any): AxiosPromise<ProjectGet> {
            return localVarFp.createInstallationOffer(installationOfferCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload an Installation Offer Document
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installationOfferUpload(file: File, options?: any): AxiosPromise<DocumentTypeResponse> {
            return localVarFp.installationOfferUpload(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstallationOfferApi - object-oriented interface
 * @export
 * @class InstallationOfferApi
 * @extends {BaseAPI}
 */
export class InstallationOfferApi extends BaseAPI {
    /**
     * 
     * @summary Create an Installation Offer
     * @param {InstallationOfferCreate} installationOfferCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstallationOfferApi
     */
    public createInstallationOffer(installationOfferCreate: InstallationOfferCreate, options?: RawAxiosRequestConfig) {
        return InstallationOfferApiFp(this.configuration).createInstallationOffer(installationOfferCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload an Installation Offer Document
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstallationOfferApi
     */
    public installationOfferUpload(file: File, options?: RawAxiosRequestConfig) {
        return InstallationOfferApiFp(this.configuration).installationOfferUpload(file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * KYCApi - axios parameter creator
 * @export
 */
export const KYCApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get the kyc link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKYCLink: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/kyc/link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Webhook for KYC client
         * @param {IdentData} identData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kycWebhook: async (identData: IdentData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identData' is not null or undefined
            assertParamExists('kycWebhook', 'identData', identData)
            const localVarPath = `/kyc/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KYCApi - functional programming interface
 * @export
 */
export const KYCApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KYCApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get the kyc link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKYCLink(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKYCLink(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KYCApi.getKYCLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Webhook for KYC client
         * @param {IdentData} identData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kycWebhook(identData: IdentData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kycWebhook(identData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KYCApi.kycWebhook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * KYCApi - factory interface
 * @export
 */
export const KYCApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KYCApiFp(configuration)
    return {
        /**
         * 
         * @summary get the kyc link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKYCLink(options?: any): AxiosPromise<string> {
            return localVarFp.getKYCLink(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Webhook for KYC client
         * @param {IdentData} identData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kycWebhook(identData: IdentData, options?: any): AxiosPromise<void> {
            return localVarFp.kycWebhook(identData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KYCApi - object-oriented interface
 * @export
 * @class KYCApi
 * @extends {BaseAPI}
 */
export class KYCApi extends BaseAPI {
    /**
     * 
     * @summary get the kyc link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KYCApi
     */
    public getKYCLink(options?: RawAxiosRequestConfig) {
        return KYCApiFp(this.configuration).getKYCLink(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Webhook for KYC client
     * @param {IdentData} identData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KYCApi
     */
    public kycWebhook(identData: IdentData, options?: RawAxiosRequestConfig) {
        return KYCApiFp(this.configuration).kycWebhook(identData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * KeycloakApi - axios parameter creator
 * @export
 */
export const KeycloakApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Login with Keycloak
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (login: Login, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists('login', 'login', login)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invalidate a previous login
         * @param {Logout} logout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (logout: Logout, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logout' is not null or undefined
            assertParamExists('logout', 'logout', logout)
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logout, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtain a new access token after expiry
         * @param {Refresh} refresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh: async (refresh: Refresh, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refresh' is not null or undefined
            assertParamExists('refresh', 'refresh', refresh)
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refresh, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KeycloakApi - functional programming interface
 * @export
 */
export const KeycloakApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KeycloakApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Login with Keycloak
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(login: Login, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(login, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KeycloakApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Invalidate a previous login
         * @param {Logout} logout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(logout: Logout, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(logout, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KeycloakApi.logout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Obtain a new access token after expiry
         * @param {Refresh} refresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refresh(refresh: Refresh, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refresh(refresh, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KeycloakApi.refresh']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * KeycloakApi - factory interface
 * @export
 */
export const KeycloakApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KeycloakApiFp(configuration)
    return {
        /**
         * 
         * @summary Login with Keycloak
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(login: Login, options?: any): AxiosPromise<AccessToken> {
            return localVarFp.login(login, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invalidate a previous login
         * @param {Logout} logout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(logout: Logout, options?: any): AxiosPromise<void> {
            return localVarFp.logout(logout, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtain a new access token after expiry
         * @param {Refresh} refresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(refresh: Refresh, options?: any): AxiosPromise<AccessToken> {
            return localVarFp.refresh(refresh, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KeycloakApi - object-oriented interface
 * @export
 * @class KeycloakApi
 * @extends {BaseAPI}
 */
export class KeycloakApi extends BaseAPI {
    /**
     * 
     * @summary Login with Keycloak
     * @param {Login} login 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApi
     */
    public login(login: Login, options?: RawAxiosRequestConfig) {
        return KeycloakApiFp(this.configuration).login(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invalidate a previous login
     * @param {Logout} logout 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApi
     */
    public logout(logout: Logout, options?: RawAxiosRequestConfig) {
        return KeycloakApiFp(this.configuration).logout(logout, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtain a new access token after expiry
     * @param {Refresh} refresh 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApi
     */
    public refresh(refresh: Refresh, options?: RawAxiosRequestConfig) {
        return KeycloakApiFp(this.configuration).refresh(refresh, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ModuleControllerApi - axios parameter creator
 * @export
 */
export const ModuleControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/modules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModuleControllerApi - functional programming interface
 * @export
 */
export const ModuleControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModuleControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleControllerApi.getAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ModuleControllerApi - factory interface
 * @export
 */
export const ModuleControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModuleControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: any): AxiosPromise<object> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModuleControllerApi - object-oriented interface
 * @export
 * @class ModuleControllerApi
 * @extends {BaseAPI}
 */
export class ModuleControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleControllerApi
     */
    public getAll(options?: RawAxiosRequestConfig) {
        return ModuleControllerApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OfferControllerApi - axios parameter creator
 * @export
 */
export const OfferControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachFile: async (id: number, file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('attachFile', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('attachFile', 'file', file)
            const localVarPath = `/api/offers/{id}/attach-file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetches offers for a specific project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOfferByProjectId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOfferByProjectId', 'id', id)
            const localVarPath = `/api/offers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetches offers for a specific project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOffersByProject: async (projectId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('findOffersByProject', 'projectId', projectId)
            const localVarPath = `/api/offers/project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetches offers for a logged in partner
         * @param {string} userUuid 
         * @param {OfferStatus} [offerStatus] Offer status
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {number} [priceFrom] 
         * @param {number} [priceTo] 
         * @param {string} [keyword] search term
         * @param {number} [page] page number
         * @param {number} [size] items per page
         * @param {string} [sort] sort criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPartnerOffers: async (userUuid: string, offerStatus?: OfferStatus, dateFrom?: string, dateTo?: string, priceFrom?: number, priceTo?: number, keyword?: string, page?: number, size?: number, sort?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUuid' is not null or undefined
            assertParamExists('findPartnerOffers', 'userUuid', userUuid)
            const localVarPath = `/api/offers/partner/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userUuid !== undefined) {
                localVarQueryParameter['userUuid'] = userUuid;
            }

            if (offerStatus !== undefined) {
                localVarQueryParameter['OfferStatus'] = offerStatus;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substring(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substring(0,10) :
                    dateTo;
            }

            if (priceFrom !== undefined) {
                localVarQueryParameter['priceFrom'] = priceFrom;
            }

            if (priceTo !== undefined) {
                localVarQueryParameter['priceTo'] = priceTo;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download offer file
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerDocumentDownload: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerDocumentDownload', 'id', id)
            const localVarPath = `/api/offers/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Back office method for setting offer state to RECEIVED and adding price and offer description.
         * @param {number} id 
         * @param {PartnerOfferDto} partnerOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveOffer: async (id: number, partnerOfferDto: PartnerOfferDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('receiveOffer', 'id', id)
            // verify required parameter 'partnerOfferDto' is not null or undefined
            assertParamExists('receiveOffer', 'partnerOfferDto', partnerOfferDto)
            const localVarPath = `/api/offers/{id}/receive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partnerOfferDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Scan text from uploaded offer
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scan: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('scan', 'file', file)
            const localVarPath = `/api/offers/scan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferControllerApi - functional programming interface
 * @export
 */
export const OfferControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachFile(id: number, file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachFile(id, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OfferControllerApi.attachFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetches offers for a specific project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOfferByProjectId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOfferByProjectId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OfferControllerApi.findOfferByProjectId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetches offers for a specific project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOffersByProject(projectId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectOffersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOffersByProject(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OfferControllerApi.findOffersByProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetches offers for a logged in partner
         * @param {string} userUuid 
         * @param {OfferStatus} [offerStatus] Offer status
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {number} [priceFrom] 
         * @param {number} [priceTo] 
         * @param {string} [keyword] search term
         * @param {number} [page] page number
         * @param {number} [size] items per page
         * @param {string} [sort] sort criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPartnerOffers(userUuid: string, offerStatus?: OfferStatus, dateFrom?: string, dateTo?: string, priceFrom?: number, priceTo?: number, keyword?: string, page?: number, size?: number, sort?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerOfferSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPartnerOffers(userUuid, offerStatus, dateFrom, dateTo, priceFrom, priceTo, keyword, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OfferControllerApi.findPartnerOffers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Download offer file
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerDocumentDownload(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerDocumentDownload(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OfferControllerApi.offerDocumentDownload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Back office method for setting offer state to RECEIVED and adding price and offer description.
         * @param {number} id 
         * @param {PartnerOfferDto} partnerOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiveOffer(id: number, partnerOfferDto: PartnerOfferDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiveOffer(id, partnerOfferDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OfferControllerApi.receiveOffer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Scan text from uploaded offer
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scan(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scan(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OfferControllerApi.scan']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OfferControllerApi - factory interface
 * @export
 */
export const OfferControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachFile(id: number, file: File, options?: any): AxiosPromise<void> {
            return localVarFp.attachFile(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetches offers for a specific project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOfferByProjectId(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.findOfferByProjectId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetches offers for a specific project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOffersByProject(projectId: number, options?: any): AxiosPromise<ProjectOffersDto> {
            return localVarFp.findOffersByProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetches offers for a logged in partner
         * @param {string} userUuid 
         * @param {OfferStatus} [offerStatus] Offer status
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {number} [priceFrom] 
         * @param {number} [priceTo] 
         * @param {string} [keyword] search term
         * @param {number} [page] page number
         * @param {number} [size] items per page
         * @param {string} [sort] sort criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPartnerOffers(userUuid: string, offerStatus?: OfferStatus, dateFrom?: string, dateTo?: string, priceFrom?: number, priceTo?: number, keyword?: string, page?: number, size?: number, sort?: string, options?: any): AxiosPromise<PartnerOfferSearchResponse> {
            return localVarFp.findPartnerOffers(userUuid, offerStatus, dateFrom, dateTo, priceFrom, priceTo, keyword, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download offer file
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerDocumentDownload(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.offerDocumentDownload(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Back office method for setting offer state to RECEIVED and adding price and offer description.
         * @param {number} id 
         * @param {PartnerOfferDto} partnerOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveOffer(id: number, partnerOfferDto: PartnerOfferDto, options?: any): AxiosPromise<void> {
            return localVarFp.receiveOffer(id, partnerOfferDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Scan text from uploaded offer
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scan(file: File, options?: any): AxiosPromise<object> {
            return localVarFp.scan(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferControllerApi - object-oriented interface
 * @export
 * @class OfferControllerApi
 * @extends {BaseAPI}
 */
export class OfferControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public attachFile(id: number, file: File, options?: RawAxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).attachFile(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetches offers for a specific project
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public findOfferByProjectId(id: number, options?: RawAxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).findOfferByProjectId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetches offers for a specific project
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public findOffersByProject(projectId: number, options?: RawAxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).findOffersByProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetches offers for a logged in partner
     * @param {string} userUuid 
     * @param {OfferStatus} [offerStatus] Offer status
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {number} [priceFrom] 
     * @param {number} [priceTo] 
     * @param {string} [keyword] search term
     * @param {number} [page] page number
     * @param {number} [size] items per page
     * @param {string} [sort] sort criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public findPartnerOffers(userUuid: string, offerStatus?: OfferStatus, dateFrom?: string, dateTo?: string, priceFrom?: number, priceTo?: number, keyword?: string, page?: number, size?: number, sort?: string, options?: RawAxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).findPartnerOffers(userUuid, offerStatus, dateFrom, dateTo, priceFrom, priceTo, keyword, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download offer file
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public offerDocumentDownload(id: number, options?: RawAxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).offerDocumentDownload(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Back office method for setting offer state to RECEIVED and adding price and offer description.
     * @param {number} id 
     * @param {PartnerOfferDto} partnerOfferDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public receiveOffer(id: number, partnerOfferDto: PartnerOfferDto, options?: RawAxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).receiveOffer(id, partnerOfferDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Scan text from uploaded offer
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public scan(file: File, options?: RawAxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).scan(file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PartnerApi - axios parameter creator
 * @export
 */
export const PartnerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePartner} createPartner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartner: async (createPartner: CreatePartner, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPartner' is not null or undefined
            assertParamExists('createPartner', 'createPartner', createPartner)
            const localVarPath = `/partner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPartner, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPartnerById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findPartnerById', 'id', id)
            const localVarPath = `/partner/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [keyword] search term
         * @param {PartnerType} [partnerType] search filter
         * @param {boolean} [verified] search filter
         * @param {ProductType} [moduleType] Type of module
         * @param {string} [cantonCode] Code of canton
         * @param {number} [page] page number
         * @param {number} [size] items per page
         * @param {string} [sort] sort criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPartner: async (keyword?: string, partnerType?: PartnerType, verified?: boolean, moduleType?: ProductType, cantonCode?: string, page?: number, size?: number, sort?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (partnerType !== undefined) {
                localVarQueryParameter['partnerType'] = partnerType;
            }

            if (verified !== undefined) {
                localVarQueryParameter['verified'] = verified;
            }

            if (moduleType !== undefined) {
                localVarQueryParameter['moduleType'] = moduleType;
            }

            if (cantonCode !== undefined) {
                localVarQueryParameter['cantonCode'] = cantonCode;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PartnerUpdate} partnerUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartner: async (id: number, partnerUpdate: PartnerUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePartner', 'id', id)
            // verify required parameter 'partnerUpdate' is not null or undefined
            assertParamExists('updatePartner', 'partnerUpdate', partnerUpdate)
            const localVarPath = `/partner/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partnerUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerContactEmail: async (id: number, email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePartnerContactEmail', 'id', id)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('updatePartnerContactEmail', 'email', email)
            const localVarPath = `/partner/{id}/email`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerApi - functional programming interface
 * @export
 */
export const PartnerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePartner} createPartner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPartner(createPartner: CreatePartner, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPartner(createPartner, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnerApi.createPartner']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPartnerById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPartnerById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnerApi.findPartnerById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [keyword] search term
         * @param {PartnerType} [partnerType] search filter
         * @param {boolean} [verified] search filter
         * @param {ProductType} [moduleType] Type of module
         * @param {string} [cantonCode] Code of canton
         * @param {number} [page] page number
         * @param {number} [size] items per page
         * @param {string} [sort] sort criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPartner(keyword?: string, partnerType?: PartnerType, verified?: boolean, moduleType?: ProductType, cantonCode?: string, page?: number, size?: number, sort?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerSearchResponsePageable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPartner(keyword, partnerType, verified, moduleType, cantonCode, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnerApi.searchPartner']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {PartnerUpdate} partnerUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePartner(id: number, partnerUpdate: PartnerUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartner(id, partnerUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnerApi.updatePartner']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePartnerContactEmail(id: number, email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartnerContactEmail(id, email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnerApi.updatePartnerContactEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PartnerApi - factory interface
 * @export
 */
export const PartnerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePartner} createPartner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartner(createPartner: CreatePartner, options?: any): AxiosPromise<number> {
            return localVarFp.createPartner(createPartner, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPartnerById(id: number, options?: any): AxiosPromise<PartnerGet> {
            return localVarFp.findPartnerById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [keyword] search term
         * @param {PartnerType} [partnerType] search filter
         * @param {boolean} [verified] search filter
         * @param {ProductType} [moduleType] Type of module
         * @param {string} [cantonCode] Code of canton
         * @param {number} [page] page number
         * @param {number} [size] items per page
         * @param {string} [sort] sort criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPartner(keyword?: string, partnerType?: PartnerType, verified?: boolean, moduleType?: ProductType, cantonCode?: string, page?: number, size?: number, sort?: string, options?: any): AxiosPromise<PartnerSearchResponsePageable> {
            return localVarFp.searchPartner(keyword, partnerType, verified, moduleType, cantonCode, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PartnerUpdate} partnerUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartner(id: number, partnerUpdate: PartnerUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updatePartner(id, partnerUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerContactEmail(id: number, email: string, options?: any): AxiosPromise<void> {
            return localVarFp.updatePartnerContactEmail(id, email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerApi - object-oriented interface
 * @export
 * @class PartnerApi
 * @extends {BaseAPI}
 */
export class PartnerApi extends BaseAPI {
    /**
     * 
     * @param {CreatePartner} createPartner 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public createPartner(createPartner: CreatePartner, options?: RawAxiosRequestConfig) {
        return PartnerApiFp(this.configuration).createPartner(createPartner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public findPartnerById(id: number, options?: RawAxiosRequestConfig) {
        return PartnerApiFp(this.configuration).findPartnerById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [keyword] search term
     * @param {PartnerType} [partnerType] search filter
     * @param {boolean} [verified] search filter
     * @param {ProductType} [moduleType] Type of module
     * @param {string} [cantonCode] Code of canton
     * @param {number} [page] page number
     * @param {number} [size] items per page
     * @param {string} [sort] sort criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public searchPartner(keyword?: string, partnerType?: PartnerType, verified?: boolean, moduleType?: ProductType, cantonCode?: string, page?: number, size?: number, sort?: string, options?: RawAxiosRequestConfig) {
        return PartnerApiFp(this.configuration).searchPartner(keyword, partnerType, verified, moduleType, cantonCode, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PartnerUpdate} partnerUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public updatePartner(id: number, partnerUpdate: PartnerUpdate, options?: RawAxiosRequestConfig) {
        return PartnerApiFp(this.configuration).updatePartner(id, partnerUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public updatePartnerContactEmail(id: number, email: string, options?: RawAxiosRequestConfig) {
        return PartnerApiFp(this.configuration).updatePartnerContactEmail(id, email, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProfileControllerApi - axios parameter creator
 * @export
 */
export const ProfileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Method for getting all users
         * @param {KycStatus} [kycStatus] KYC status
         * @param {string} [keyword] search term
         * @param {number} [page] page number
         * @param {number} [size] items per page
         * @param {string} [sort] sort criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProfiles: async (kycStatus?: KycStatus, keyword?: string, page?: number, size?: number, sort?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profiles/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kycStatus !== undefined) {
                localVarQueryParameter['kycStatus'] = kycStatus;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileControllerApi - functional programming interface
 * @export
 */
export const ProfileControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Method for getting all users
         * @param {KycStatus} [kycStatus] KYC status
         * @param {string} [keyword] search term
         * @param {number} [page] page number
         * @param {number} [size] items per page
         * @param {string} [sort] sort criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProfiles(kycStatus?: KycStatus, keyword?: string, page?: number, size?: number, sort?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProfiles(kycStatus, keyword, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileControllerApi.searchProfiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProfileControllerApi - factory interface
 * @export
 */
export const ProfileControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Method for getting all users
         * @param {KycStatus} [kycStatus] KYC status
         * @param {string} [keyword] search term
         * @param {number} [page] page number
         * @param {number} [size] items per page
         * @param {string} [sort] sort criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProfiles(kycStatus?: KycStatus, keyword?: string, page?: number, size?: number, sort?: string, options?: any): AxiosPromise<object> {
            return localVarFp.searchProfiles(kycStatus, keyword, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileControllerApi - object-oriented interface
 * @export
 * @class ProfileControllerApi
 * @extends {BaseAPI}
 */
export class ProfileControllerApi extends BaseAPI {
    /**
     * 
     * @summary Method for getting all users
     * @param {KycStatus} [kycStatus] KYC status
     * @param {string} [keyword] search term
     * @param {number} [page] page number
     * @param {number} [size] items per page
     * @param {string} [sort] sort criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public searchProfiles(kycStatus?: KycStatus, keyword?: string, page?: number, size?: number, sort?: string, options?: RawAxiosRequestConfig) {
        return ProfileControllerApiFp(this.configuration).searchProfiles(kycStatus, keyword, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a Project
         * @param {ProjectCreate} projectCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (projectCreate: ProjectCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectCreate' is not null or undefined
            assertParamExists('createProject', 'projectCreate', projectCreate)
            const localVarPath = `/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a Scanned Project (deprecated, use POST /installationOffer instead)
         * @param {ScannedProjectCreate} scannedProjectCreate 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createScannedProject: async (scannedProjectCreate: ScannedProjectCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scannedProjectCreate' is not null or undefined
            assertParamExists('createScannedProject', 'scannedProjectCreate', scannedProjectCreate)
            const localVarPath = `/project/scanned`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scannedProjectCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a file from the project log for a specific project id
         * @param {string} id the id of a protocol
         * @param {string} filePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchProjectLogFile: async (id: string, filePath: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchProjectLogFile', 'id', id)
            // verify required parameter 'filePath' is not null or undefined
            assertParamExists('fetchProjectLogFile', 'filePath', filePath)
            const localVarPath = `/project/{id}/log-file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filePath !== undefined) {
                localVarQueryParameter['filePath'] = filePath;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a Project by Id
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProjectById', 'id', id)
            const localVarPath = `/project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the project logs for a specific project id
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectLogs: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProjectLogs', 'id', id)
            const localVarPath = `/project/{id}/logs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the projects overview
         * @param {number} page 
         * @param {number} size 
         * @param {string} [keyword] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectOverview: async (page: number, size: number, keyword?: string, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getProjectOverview', 'page', page)
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getProjectOverview', 'size', size)
            const localVarPath = `/project/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the project\'s Questionnaire
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectQuestionnaireData: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProjectQuestionnaireData', 'id', id)
            const localVarPath = `/project/{id}/questionnaire`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary attach a file to an offer on a project
         * @param {string} id the id of a protocol
         * @param {number} offerId 
         * @param {FinancingModelDto} financingModelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestLoanForOffer: async (id: string, offerId: number, financingModelDto: FinancingModelDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('requestLoanForOffer', 'id', id)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('requestLoanForOffer', 'offerId', offerId)
            // verify required parameter 'financingModelDto' is not null or undefined
            assertParamExists('requestLoanForOffer', 'financingModelDto', financingModelDto)
            const localVarPath = `/project/{id}/offer/{offerId}/loan/request`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(financingModelDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary submit the project\'s Questionnaire data to the credit check agency
         * @param {string} id the id of a protocol
         * @param {QuestionnaireData} questionnaireData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitProjectQuestionnaireData: async (id: string, questionnaireData: QuestionnaireData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('submitProjectQuestionnaireData', 'id', id)
            // verify required parameter 'questionnaireData' is not null or undefined
            assertParamExists('submitProjectQuestionnaireData', 'questionnaireData', questionnaireData)
            const localVarPath = `/project/{id}/questionnaire`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionnaireData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the project\'s Questionnaire Data
         * @param {string} id the id of a protocol
         * @param {QuestionnaireData} questionnaireData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectQuestionnaireData: async (id: string, questionnaireData: QuestionnaireData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProjectQuestionnaireData', 'id', id)
            // verify required parameter 'questionnaireData' is not null or undefined
            assertParamExists('updateProjectQuestionnaireData', 'questionnaireData', questionnaireData)
            const localVarPath = `/project/{id}/questionnaire`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionnaireData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a Project
         * @param {ProjectCreate} projectCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(projectCreate: ProjectCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(projectCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.createProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary create a Scanned Project (deprecated, use POST /installationOffer instead)
         * @param {ScannedProjectCreate} scannedProjectCreate 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createScannedProject(scannedProjectCreate: ScannedProjectCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScannedProject(scannedProjectCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.createScannedProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a file from the project log for a specific project id
         * @param {string} id the id of a protocol
         * @param {string} filePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchProjectLogFile(id: string, filePath: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchProjectLogFile(id, filePath, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.fetchProjectLogFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get a Project by Id
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.getProjectById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns the project logs for a specific project id
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectLogs(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectLogEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectLogs(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.getProjectLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns the projects overview
         * @param {number} page 
         * @param {number} size 
         * @param {string} [keyword] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectOverview(page: number, size: number, keyword?: string, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectOverview200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectOverview(page, size, keyword, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.getProjectOverview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get the project\'s Questionnaire
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectQuestionnaireData(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectQuestionnaireData(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.getProjectQuestionnaireData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary attach a file to an offer on a project
         * @param {string} id the id of a protocol
         * @param {number} offerId 
         * @param {FinancingModelDto} financingModelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestLoanForOffer(id: string, offerId: number, financingModelDto: FinancingModelDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestLoanForOffer(id, offerId, financingModelDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.requestLoanForOffer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary submit the project\'s Questionnaire data to the credit check agency
         * @param {string} id the id of a protocol
         * @param {QuestionnaireData} questionnaireData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitProjectQuestionnaireData(id: string, questionnaireData: QuestionnaireData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitProjectQuestionnaireData(id, questionnaireData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.submitProjectQuestionnaireData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update the project\'s Questionnaire Data
         * @param {string} id the id of a protocol
         * @param {QuestionnaireData} questionnaireData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProjectQuestionnaireData(id: string, questionnaireData: QuestionnaireData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProjectQuestionnaireData(id, questionnaireData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.updateProjectQuestionnaireData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * 
         * @summary create a Project
         * @param {ProjectCreate} projectCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(projectCreate: ProjectCreate, options?: any): AxiosPromise<ProjectGet> {
            return localVarFp.createProject(projectCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a Scanned Project (deprecated, use POST /installationOffer instead)
         * @param {ScannedProjectCreate} scannedProjectCreate 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createScannedProject(scannedProjectCreate: ScannedProjectCreate, options?: any): AxiosPromise<ProjectGet> {
            return localVarFp.createScannedProject(scannedProjectCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a file from the project log for a specific project id
         * @param {string} id the id of a protocol
         * @param {string} filePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchProjectLogFile(id: string, filePath: string, options?: any): AxiosPromise<File> {
            return localVarFp.fetchProjectLogFile(id, filePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a Project by Id
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById(id: string, options?: any): AxiosPromise<ProjectGet> {
            return localVarFp.getProjectById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the project logs for a specific project id
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectLogs(id: string, options?: any): AxiosPromise<Array<ProjectLogEntry>> {
            return localVarFp.getProjectLogs(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the projects overview
         * @param {number} page 
         * @param {number} size 
         * @param {string} [keyword] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectOverview(page: number, size: number, keyword?: string, sort?: Array<string>, options?: any): AxiosPromise<GetProjectOverview200Response> {
            return localVarFp.getProjectOverview(page, size, keyword, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the project\'s Questionnaire
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectQuestionnaireData(id: string, options?: any): AxiosPromise<QuestionnaireData> {
            return localVarFp.getProjectQuestionnaireData(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary attach a file to an offer on a project
         * @param {string} id the id of a protocol
         * @param {number} offerId 
         * @param {FinancingModelDto} financingModelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestLoanForOffer(id: string, offerId: number, financingModelDto: FinancingModelDto, options?: any): AxiosPromise<void> {
            return localVarFp.requestLoanForOffer(id, offerId, financingModelDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary submit the project\'s Questionnaire data to the credit check agency
         * @param {string} id the id of a protocol
         * @param {QuestionnaireData} questionnaireData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitProjectQuestionnaireData(id: string, questionnaireData: QuestionnaireData, options?: any): AxiosPromise<QuestionnaireData> {
            return localVarFp.submitProjectQuestionnaireData(id, questionnaireData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the project\'s Questionnaire Data
         * @param {string} id the id of a protocol
         * @param {QuestionnaireData} questionnaireData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectQuestionnaireData(id: string, questionnaireData: QuestionnaireData, options?: any): AxiosPromise<QuestionnaireData> {
            return localVarFp.updateProjectQuestionnaireData(id, questionnaireData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * 
     * @summary create a Project
     * @param {ProjectCreate} projectCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public createProject(projectCreate: ProjectCreate, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).createProject(projectCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a Scanned Project (deprecated, use POST /installationOffer instead)
     * @param {ScannedProjectCreate} scannedProjectCreate 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public createScannedProject(scannedProjectCreate: ScannedProjectCreate, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).createScannedProject(scannedProjectCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a file from the project log for a specific project id
     * @param {string} id the id of a protocol
     * @param {string} filePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public fetchProjectLogFile(id: string, filePath: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).fetchProjectLogFile(id, filePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a Project by Id
     * @param {string} id the id of a protocol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjectById(id: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProjectById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the project logs for a specific project id
     * @param {string} id the id of a protocol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjectLogs(id: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProjectLogs(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the projects overview
     * @param {number} page 
     * @param {number} size 
     * @param {string} [keyword] 
     * @param {Array<string>} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjectOverview(page: number, size: number, keyword?: string, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProjectOverview(page, size, keyword, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the project\'s Questionnaire
     * @param {string} id the id of a protocol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjectQuestionnaireData(id: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProjectQuestionnaireData(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary attach a file to an offer on a project
     * @param {string} id the id of a protocol
     * @param {number} offerId 
     * @param {FinancingModelDto} financingModelDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public requestLoanForOffer(id: string, offerId: number, financingModelDto: FinancingModelDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).requestLoanForOffer(id, offerId, financingModelDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary submit the project\'s Questionnaire data to the credit check agency
     * @param {string} id the id of a protocol
     * @param {QuestionnaireData} questionnaireData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public submitProjectQuestionnaireData(id: string, questionnaireData: QuestionnaireData, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).submitProjectQuestionnaireData(id, questionnaireData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the project\'s Questionnaire Data
     * @param {string} id the id of a protocol
     * @param {QuestionnaireData} questionnaireData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public updateProjectQuestionnaireData(id: string, questionnaireData: QuestionnaireData, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).updateProjectQuestionnaireData(id, questionnaireData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProjectControllerApi - axios parameter creator
 * @export
 */
export const ProjectControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create project and trigger offer initialization
         * @param {CreateProjectDto} createProjectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectJava: async (createProjectDto: CreateProjectDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProjectDto' is not null or undefined
            assertParamExists('createProjectJava', 'createProjectDto', createProjectDto)
            const localVarPath = `/api/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProjectDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create scanned project and trigger offer initialization
         * @param {CreateScannedProjectDto} createScannedProjectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScannedProjectJava: async (createScannedProjectDto: CreateScannedProjectDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createScannedProjectDto' is not null or undefined
            assertParamExists('createScannedProjectJava', 'createScannedProjectDto', createScannedProjectDto)
            const localVarPath = `/api/projects/scanned`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createScannedProjectDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project information by project id
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findProjectById', 'id', id)
            const localVarPath = `/api/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project details for partner
         * @param {string} id the id of a protocol
         * @param {string} userUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectDetailsForPartner: async (id: string, userUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findProjectDetailsForPartner', 'id', id)
            // verify required parameter 'userUuid' is not null or undefined
            assertParamExists('findProjectDetailsForPartner', 'userUuid', userUuid)
            const localVarPath = `/api/projects/{id}/partner/details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userUuid !== undefined) {
                localVarQueryParameter['userUuid'] = userUuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project details for user
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectDetails: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProjectDetails', 'id', id)
            const localVarPath = `/api/projects/{id}/details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project details for admin
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectDetailsForAdmin: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProjectDetailsForAdmin', 'id', id)
            const localVarPath = `/api/projects/{id}/details/admin`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectControllerApi - functional programming interface
 * @export
 */
export const ProjectControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create project and trigger offer initialization
         * @param {CreateProjectDto} createProjectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectJava(createProjectDto: CreateProjectDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectJava(createProjectDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerApi.createProjectJava']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create scanned project and trigger offer initialization
         * @param {CreateScannedProjectDto} createScannedProjectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScannedProjectJava(createScannedProjectDto: CreateScannedProjectDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScannedProjectJava(createScannedProjectDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerApi.createScannedProjectJava']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get project information by project id
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProjectById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProjectById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerApi.findProjectById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get project details for partner
         * @param {string} id the id of a protocol
         * @param {string} userUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProjectDetailsForPartner(id: string, userUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProjectDetailsForPartner(id, userUuid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerApi.findProjectDetailsForPartner']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get project details for user
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectDetails(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectDetails(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerApi.getProjectDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get project details for admin
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectDetailsForAdmin(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectDetailsForAdmin(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerApi.getProjectDetailsForAdmin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProjectControllerApi - factory interface
 * @export
 */
export const ProjectControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create project and trigger offer initialization
         * @param {CreateProjectDto} createProjectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectJava(createProjectDto: CreateProjectDto, options?: any): AxiosPromise<ProjectDto> {
            return localVarFp.createProjectJava(createProjectDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create scanned project and trigger offer initialization
         * @param {CreateScannedProjectDto} createScannedProjectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScannedProjectJava(createScannedProjectDto: CreateScannedProjectDto, options?: any): AxiosPromise<ProjectDto> {
            return localVarFp.createScannedProjectJava(createScannedProjectDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project information by project id
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectById(id: string, options?: any): AxiosPromise<ProjectDto> {
            return localVarFp.findProjectById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project details for partner
         * @param {string} id the id of a protocol
         * @param {string} userUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectDetailsForPartner(id: string, userUuid: string, options?: any): AxiosPromise<object> {
            return localVarFp.findProjectDetailsForPartner(id, userUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project details for user
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectDetails(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.getProjectDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project details for admin
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectDetailsForAdmin(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.getProjectDetailsForAdmin(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectControllerApi - object-oriented interface
 * @export
 * @class ProjectControllerApi
 * @extends {BaseAPI}
 */
export class ProjectControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create project and trigger offer initialization
     * @param {CreateProjectDto} createProjectDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public createProjectJava(createProjectDto: CreateProjectDto, options?: RawAxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration).createProjectJava(createProjectDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create scanned project and trigger offer initialization
     * @param {CreateScannedProjectDto} createScannedProjectDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public createScannedProjectJava(createScannedProjectDto: CreateScannedProjectDto, options?: RawAxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration).createScannedProjectJava(createScannedProjectDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project information by project id
     * @param {string} id the id of a protocol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public findProjectById(id: string, options?: RawAxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration).findProjectById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project details for partner
     * @param {string} id the id of a protocol
     * @param {string} userUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public findProjectDetailsForPartner(id: string, userUuid: string, options?: RawAxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration).findProjectDetailsForPartner(id, userUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project details for user
     * @param {string} id the id of a protocol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public getProjectDetails(id: string, options?: RawAxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration).getProjectDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project details for admin
     * @param {string} id the id of a protocol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public getProjectDetailsForAdmin(id: string, options?: RawAxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration).getProjectDetailsForAdmin(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create an Admin
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdmin: async (user: User, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('createAdmin', 'user', user)
            const localVarPath = `/user/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a User
         * @param {User} user 
         * @param {boolean} [iAM] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (user: User, iAM?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('createUser', 'user', user)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (iAM !== undefined) {
                localVarQueryParameter['IAM'] = iAM;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a User by Id
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserById', 'id', id)
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the currently logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a User by Id
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserById', 'id', id)
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a User by Id
         * @param {string} id the id of a protocol
         * @param {UserUpdate} [userUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserById: async (id: string, userUpdate?: UserUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserById', 'id', id)
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary check if otp credential exists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOtpCredentialCheck: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/credentials/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete otp credential
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOtpCredentialDelete: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/credentials/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create an Admin
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdmin(user: User, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdmin(user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.createAdmin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary create a User
         * @param {User} user 
         * @param {boolean} [iAM] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(user: User, iAM?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(user, iAM, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.createUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary delete a User by Id
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.deleteUserById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get the currently logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getCurrentUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get a User by Id
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUserById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update a User by Id
         * @param {string} id the id of a protocol
         * @param {UserUpdate} [userUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserById(id: string, userUpdate?: UserUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserById(id, userUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateUserById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary check if otp credential exists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userOtpCredentialCheck(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userOtpCredentialCheck(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userOtpCredentialCheck']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary delete otp credential
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userOtpCredentialDelete(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userOtpCredentialDelete(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userOtpCredentialDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary create an Admin
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdmin(user: User, options?: any): AxiosPromise<UserGet> {
            return localVarFp.createAdmin(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a User
         * @param {User} user 
         * @param {boolean} [iAM] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(user: User, iAM?: boolean, options?: any): AxiosPromise<UserGet> {
            return localVarFp.createUser(user, iAM, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a User by Id
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the currently logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<UserGet> {
            return localVarFp.getCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a User by Id
         * @param {string} id the id of a protocol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(id: string, options?: any): AxiosPromise<UserGet> {
            return localVarFp.getUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a User by Id
         * @param {string} id the id of a protocol
         * @param {UserUpdate} [userUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserById(id: string, userUpdate?: UserUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserById(id, userUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary check if otp credential exists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOtpCredentialCheck(options?: any): AxiosPromise<void> {
            return localVarFp.userOtpCredentialCheck(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete otp credential
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOtpCredentialDelete(options?: any): AxiosPromise<void> {
            return localVarFp.userOtpCredentialDelete(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary create an Admin
     * @param {User} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createAdmin(user: User, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).createAdmin(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a User
     * @param {User} user 
     * @param {boolean} [iAM] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(user: User, iAM?: boolean, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).createUser(user, iAM, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a User by Id
     * @param {string} id the id of a protocol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUserById(id: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUserById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the currently logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getCurrentUser(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a User by Id
     * @param {string} id the id of a protocol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserById(id: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a User by Id
     * @param {string} id the id of a protocol
     * @param {UserUpdate} [userUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserById(id: string, userUpdate?: UserUpdate, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserById(id, userUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary check if otp credential exists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userOtpCredentialCheck(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userOtpCredentialCheck(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete otp credential
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userOtpCredentialDelete(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userOtpCredentialDelete(options).then((request) => request(this.axios, this.basePath));
    }
}



