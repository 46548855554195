import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocizeBackend from 'i18next-locize-backend';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { locizePlugin } from 'locize';
import locizeLastUsed from 'locize-lastused';

export enum Languages {
  DE = 'de',
  FR = 'fr'
}

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID as string,
  apiKey: process.env.REACT_APP_LOCIZE_API_KEY as string,
  version: process.env.REACT_APP_LOCIZE_VERSION as string,
  referenceLng: Languages.DE
};

i18n
  .use(locizePlugin)
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(locizeLastUsed)
  .init({
    supportedLngs: Object.values(Languages),
    fallbackLng: Languages.DE,
    interpolation: { escapeValue: false },
    returnNull: false,
    returnEmptyString: false,
    backend: {
      backends: [
        LocizeBackend,
        resourcesToBackend((lng: string, ns: string) => import(`./locales/${lng}/${ns}.json`))
      ],
      backendOptions: [{ ...locizeOptions }]
    },
    // saveMissing: !isProduction ** COMMENTED OUT UNTIL ISSUE WITH ADDING DINAMYC KEYS TO LOCIZE IS RESOLVED **
    locizeLastUsed: { ...locizeOptions, allowedHosts: ['localhost'], debounceSubmit: 900000 },
    parseMissingKeyHandler: () => ``
  });

export default i18n;
