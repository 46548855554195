import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Text from '../../../components/Text/Text';
import TextInput from '../../../components/Inputs/TextInput/TextInput';
import Radio from '../../../components/Inputs/Radio/Radio';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Dropdown from '../../../components/Dropdown/Dropdown';
import NumberInput from '../../../components/Inputs/NumberInput/NumberInput';

import {
  EmploymentStatus,
  QuestionnaireFormActionType,
  QuestionnaireFormProps,
  QuestionnaireType,
  TransportType
} from '../../../store/questionnaire/questionnaire.types';
import { RootState } from '../../../reducers/rootReducer';
import { User } from '../../../store/user/types';

import { changeFinancialDetails } from '../../../store/questionnaire/questionnaire.actions';

import { validateEmpty, validateIban } from '../../../utils/validations.utils';
import { getHighlightClass } from '../../../utils/questionnaire.utils';

import '../Questionnaire.scss';

const FinancialSituation = ({ isDataMissing }: QuestionnaireFormProps) => {
  const { t } = useTranslation();
  const user = useSelector<RootState>((state) => state.userReducer) as User;
  const questionnaire = useSelector<RootState>(
    (state) => state.questionnaireReducer
  ) as QuestionnaireType;
  const dispatch = useDispatch();

  const bankDetails = questionnaire.kycData?.bankDetails ?? {};
  const employmentStatus = questionnaire.creditCheckData.applicant?.employmentStatus ?? '';
  const monthlyIncomeGrossAmountMain =
    questionnaire.creditCheckData.applicant?.financialData?.monthlyIncomeGrossAmountMain ?? '';
  const workingDaysPerMonth =
    questionnaire.creditCheckData.applicant?.financialData?.workingDaysPerMonth ?? '';
  const monthlyHousingCost =
    questionnaire.creditCheckData?.applicant?.financialData?.monthlyHousingCost ?? '';
  const monthlyExpensesAlimony =
    questionnaire.creditCheckData.applicant?.financialData?.monthlyExpensesAlimony ?? '';
  const monthlyEducationCosts =
    questionnaire.creditCheckData?.applicant?.financialData?.monthlyEducationCosts ?? '';
  const monthlyOtherFixExpenses =
    questionnaire.creditCheckData?.applicant?.financialData?.monthlyOtherFixExpenses ?? '';
  const monthlyOneTimeExpenses =
    questionnaire.creditCheckData?.applicant?.financialData?.monthlyOneTimeExpenses ?? '';

  const transportationType =
    questionnaire.creditCheckData?.applicant?.financialData?.transportationType ?? '';

  const transportationCost =
    questionnaire.creditCheckData?.applicant?.financialData?.monthlyTransportationCosts ?? '';

  const transportationCostRequired =
    transportationType === TransportType.CAR || transportationType === TransportType.PUBLIC;

  useEffect(() => {
    user.bankName &&
      dispatch({
        type: QuestionnaireFormActionType.CHANGE_BANK_DETAILS,
        payload: { field: 'bankName', value: user.bankName }
      });
    user.iBAN &&
      dispatch({
        type: QuestionnaireFormActionType.CHANGE_BANK_DETAILS,
        payload: { field: 'iBAN', value: user.iBAN }
      });
  }, [user]);

  const changeBankDetailsHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: QuestionnaireFormActionType.CHANGE_BANK_DETAILS,
      payload: { field: e.target.name, value: e.target.value || undefined }
    });
  };

  const changeNumberOfMonthlyIncomeHandler = (
    field: 'numberOfMonthlyIncomeMain' | 'numberOfMonthlyIncomeAdditional'
  ) => {
    let value = questionnaire.creditCheckData.applicant?.financialData?.[field];

    value === 12 || !value ? (value = 13) : (value = 12);

    dispatch({
      type: QuestionnaireFormActionType.CHANGE_FINANCIAL_DETAILS,
      payload: { field, value }
    });
  };

  const changeMonthlyAdditionalIncomeHandler = (income: number) => {
    if (income < 0) return;

    if (income === 0) {
      dispatch({
        type: QuestionnaireFormActionType.CHANGE_MONTHLY_INCOME_ADDITIONAL,
        payload: []
      });
    } else {
      dispatch({
        type: QuestionnaireFormActionType.CHANGE_MONTHLY_INCOME_ADDITIONAL,
        payload: [income]
      });
    }
  };

  const changeTransportTypeHandler = (value: string) => {
    dispatch({
      type: QuestionnaireFormActionType.CHANGE_TRANSPORT_TYPE,
      payload: value as TransportType
    });

    if (value !== TransportType.CAR && value !== TransportType.PUBLIC && transportationCost) {
      dispatch({
        type: QuestionnaireFormActionType.CHANGE_FINANCIAL_DETAILS,
        payload: {
          field: 'monthlyTransportationCosts',
          value: undefined
        }
      });
    }
  };

  return (
    <form className="credit-check-form">
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.financialSituation.bankDetails')}
        </Text>
        <TextInput
          type="text"
          name="bankName"
          value={bankDetails?.bankName || ''}
          onChange={changeBankDetailsHandler}
          placeholder={t('myProfile.bankDetails.bankName')}
          validate={validateEmpty}
          required
          className={getHighlightClass(bankDetails?.bankName, isDataMissing)}
        />
        <TextInput
          type="text"
          name="iBAN"
          value={bankDetails?.iBAN || ''}
          onChange={changeBankDetailsHandler}
          placeholder={t('iban')}
          validate={validateIban}
          required
          className={getHighlightClass(
            bankDetails?.iBAN,
            isDataMissing,
            !validateIban(bankDetails?.iBAN)
          )}
        />
      </div>
      <div className="account-owner">
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.financialSituation.accountHolderName')}
        </Text>
        <TextInput
          type="text"
          name="firstName"
          value={user?.firstName}
          placeholder={t('name')}
          validate={validateEmpty}
          disabled
          required
        />
        <TextInput
          type="text"
          name="lastName"
          value={user?.lastName}
          placeholder={t('lastName')}
          validate={validateEmpty}
          disabled
          required
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.financialSituation.employmentStatusText')}
        </Text>
        <Dropdown
          values={Object.values(EmploymentStatus).map((elem) => ({
            value: elem,
            displayValue: t(`creditCheck.employmentStatus.${elem}`)
          }))}
          initialValue={{
            value: employmentStatus,
            displayValue: t(`creditCheck.employmentStatus.${employmentStatus}`)
          }}
          noValueText={t('creditCheck.financialSituation.employmentStatus')}
          getValue={(value) => {
            dispatch({
              type: QuestionnaireFormActionType.CHANGE_EMPLOYMENT_STATUS,
              payload: value as EmploymentStatus
            });
          }}
          required
          className={getHighlightClass(employmentStatus, isDataMissing)}
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.financialSituation.monthlyIncomeGrossAmountMain')}
        </Text>
        <NumberInput
          name="monthlyIncomeGrossAmountMain"
          value={monthlyIncomeGrossAmountMain}
          onChange={(amount) => {
            changeFinancialDetails(amount, 'monthlyIncomeGrossAmountMain', dispatch);
          }}
          placeholder={`${t('currency.chf')} / ${t('month')}`}
          required
          className={getHighlightClass(monthlyIncomeGrossAmountMain, isDataMissing)}
        />
        <Checkbox
          checked={
            questionnaire.creditCheckData.applicant?.financialData?.numberOfMonthlyIncomeMain === 13
          }
          label={t('creditCheck.financialSituation.thirteenSalary')}
          onClick={() => {
            changeNumberOfMonthlyIncomeHandler('numberOfMonthlyIncomeMain');
          }}
        />
      </div>
      <div>
        <Text as="h3" category="headline" size="medium">
          {t('creditCheck.financialSituation.additionalIncome')}
        </Text>
        <Text as="span" category="body" size="large">
          {t('creditCheck.financialSituation.ammountOfAdditionalIncome')}
        </Text>
        <NumberInput
          name="monthlyIncomeGrossAmountAdditional"
          value={
            questionnaire.creditCheckData.applicant?.financialData
              ?.monthlyIncomeGrossAmountAdditional?.[0]
          }
          onChange={changeMonthlyAdditionalIncomeHandler}
          placeholder={`${t('currency.chf')} / ${t('month')}`}
        />
        <Checkbox
          checked={
            questionnaire.creditCheckData.applicant?.financialData
              ?.numberOfMonthlyIncomeAdditional === 13
          }
          label={t('creditCheck.financialSituation.additionalIncomeThirteenSalary')}
          onClick={() => {
            changeNumberOfMonthlyIncomeHandler('numberOfMonthlyIncomeAdditional');
          }}
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.financialSituation.workingDaysPerMonth')}
        </Text>
        <Text as="span" category="body" size="large">
          {t('creditCheck.financialSituation.workingDaysPerMonthText')}
        </Text>
        <NumberInput
          value={workingDaysPerMonth}
          name="workingDaysPerMonth"
          maxValue={31}
          placeholder={`${t('days')} / ${t('month')}`}
          onChange={(age) => {
            changeFinancialDetails(age, 'workingDaysPerMonth', dispatch);
          }}
          required
          className={getHighlightClass(workingDaysPerMonth, isDataMissing)}
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.financialSituation.rentalCostMonthly')}
        </Text>
        <Text size="large">{t('creditCheck.financialSituation.rentalCostMonthlyExamples')}</Text>
        <NumberInput
          value={monthlyHousingCost}
          name="monthlyHousingCost"
          placeholder={`${t('currency.chf')} / ${t('month')}`}
          onChange={(cost) => {
            changeFinancialDetails(cost, 'monthlyHousingCost', dispatch);
          }}
          required
          className={getHighlightClass(monthlyHousingCost, isDataMissing)}
        />
      </div>
      <div className="transport-choices-wrapper">
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.financialSituation.workTransportText')}
        </Text>
        <Dropdown
          values={Object.values(TransportType).map((elem) => ({
            value: elem,
            displayValue: t(`creditCheck.transportType.${elem}`)
          }))}
          initialValue={{
            value: transportationType,
            displayValue: t(`creditCheck.transportType.${transportationType}`)
          }}
          noValueText={t('creditCheck.financialSituation.workTransport')}
          getValue={changeTransportTypeHandler}
          required
          className={getHighlightClass(transportationType, isDataMissing)}
        />
      </div>
      {transportationCostRequired && (
        <div>
          <Text as="h3" category="headline" size="medium">
            {t('creditCheck.financialSituation.monthlyTransportationCosts')}
          </Text>
          <Text as="span" size="large">
            {t('creditCheck.financialSituation.onlyIfPublicTransportUsed')}
          </Text>
          <NumberInput
            value={transportationCost}
            name="monthlyTransportationCosts"
            placeholder={`${t('currency.chf')} / ${t('month')}`}
            onChange={(cost) => {
              changeFinancialDetails(cost, 'monthlyTransportationCosts', dispatch);
            }}
            required
            className={getHighlightClass(transportationCost, isDataMissing)}
          />
        </div>
      )}
      <div>
        <Text as="h3" category="headline" size="medium">
          {t('creditCheck.financialSituation.isChurchTaxObligatory')}
        </Text>
        <div className="church-obligation-wrapper">
          <Radio
            value="yes"
            variation="secondary"
            checked={
              !!questionnaire.creditCheckData.applicant?.financialData?.isChurchTaxObligatory
            }
            label={t('yes')}
            onClick={() => {
              dispatch({
                type: QuestionnaireFormActionType.CHANGE_CHURCH_TAX_OBLIGATION,
                payload: true
              });
            }}
          />
          <Radio
            value="no"
            variation="secondary"
            checked={!questionnaire.creditCheckData.applicant?.financialData?.isChurchTaxObligatory}
            label={t('no')}
            onClick={() => {
              dispatch({
                type: QuestionnaireFormActionType.CHANGE_CHURCH_TAX_OBLIGATION,
                payload: false
              });
            }}
          />
        </div>
      </div>
      <div>
        <Text as="h3" category="headline" size="medium">
          {t('creditCheck.financialSituation.monthlyExpensesAlimony')}
        </Text>
        <NumberInput
          value={monthlyExpensesAlimony}
          name="monthlyExpensesAlimony"
          placeholder={`${t('currency.chf')} / ${t('month')}`}
          onChange={(expense) => {
            changeFinancialDetails(expense, 'monthlyExpensesAlimony', dispatch);
          }}
          required
          className={getHighlightClass(monthlyExpensesAlimony, isDataMissing)}
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.financialSituation.monthlyEducationCosts')}
        </Text>
        <NumberInput
          value={monthlyEducationCosts}
          name="monthlyEducationCosts"
          placeholder={`${t('currency.chf')} / ${t('month')}`}
          onChange={(cost) => {
            changeFinancialDetails(cost, 'monthlyEducationCosts', dispatch);
          }}
          required
          className={getHighlightClass(monthlyEducationCosts, isDataMissing)}
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.financialSituation.monthlyOtherFixExpenses')}
        </Text>
        <Text size="large">
          {t('creditCheck.financialSituation.monthlyOtherFixExpensesDescription')}
        </Text>
        <NumberInput
          value={monthlyOtherFixExpenses}
          name="monthlyOtherFixExpenses"
          placeholder={`${t('currency.chf')} / ${t('month')}`}
          onChange={(expense) => {
            changeFinancialDetails(expense, 'monthlyOtherFixExpenses', dispatch);
          }}
          required
          className={getHighlightClass(monthlyOtherFixExpenses, isDataMissing)}
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.financialSituation.monthlyOneTimeExpenses')}
        </Text>
        <Text size="large">{t('creditCheck.financialSituation.majorExpensesPlanned')}</Text>
        <NumberInput
          value={monthlyOneTimeExpenses}
          name="monthlyOneTimeExpenses"
          placeholder={t('currency.chf')}
          onChange={(expense) => {
            changeFinancialDetails(expense, 'monthlyOneTimeExpenses', dispatch);
          }}
          required
          className={getHighlightClass(monthlyOneTimeExpenses, isDataMissing)}
        />
      </div>
    </form>
  );
};

export default FinancialSituation;
