import { HttpStatusCode } from 'axios';
import api, { kotlinApi } from '../../api/api';
import { toastUtil } from '../../utils/toast.utils';
import { previewFile } from '../../utils/general.utils';
import { ContractApiFp, ContractCreate, ContractUpdate } from '../../generated';

const {
  createContract,
  getContractById,
  getContracts,
  fetchContractDocumentFile,
  updateContractById
} = ContractApiFp();

export const getAllContracts = async (projectUid: string) => {
  try {
    const getContractsRequest = await getContracts(projectUid);
    const res = await getContractsRequest(kotlinApi);
    return res.data;
  } catch (error) {
    if (error instanceof Error) {
      toastUtil('error', error.message);
    }
    return [];
  }
};

export const getContract = async (id: string) => {
  try {
    const getContractByIdRequest = await getContractById(id);
    const res = await getContractByIdRequest(kotlinApi);
    return res.data;
  } catch (error) {
    if (error instanceof Error) {
      toastUtil('error', error.message);
    }
    return null;
  }
};

export const getContractDocument = async (id: string, path: string) => {
  try {
    const fetchContractDocumentFileRequest = await fetchContractDocumentFile(id, path, {
      responseType: 'blob'
    });
    const res = await fetchContractDocumentFileRequest(kotlinApi);
    previewFile(res.data);
  } catch (error) {
    if (error instanceof Error) {
      toastUtil('error', error.message);
    }
  }
};

export const createContractHandler = async (
  projectUuid: string,
  files: File[],
  contractCreate: ContractCreate
) => {
  try {
    const createContractRequest = await createContract(projectUuid, files, contractCreate, {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: [
        () => {
          const formData = new FormData();
          formData.append('contractCreate', JSON.stringify(contractCreate));
          files.forEach((file) => formData.append('files', file));
          return formData;
        }
      ]
    });
    const res = await createContractRequest(kotlinApi);
    return res.data;
  } catch (error) {
    if (error instanceof Error) {
      toastUtil('error', error.message);
    }
    return null;
  }
};

export const updateContract = async (
  id: string,
  files?: File[],
  contractUpdate?: ContractUpdate
) => {
  try {
    const updateContractRequest = await updateContractById(id, files, contractUpdate);
    const res = await updateContractRequest(kotlinApi);
    return res.status === HttpStatusCode.Ok;
  } catch (error) {
    if (error instanceof Error) {
      toastUtil('error', error.message);
    }
  }
};

export const uploadContract = async (
  contractId: string | number,
  files: FileList
): Promise<boolean> => {
  try {
    const file = files[0];
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(`/contracts/${contractId}/admin/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.status == HttpStatusCode.Ok;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
    return false;
  }
};

export const uploadOrderConfirmation = async (
  projectId: string | number,
  file: File
): Promise<boolean> => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(
      `/contracts/user/project/${projectId}/order-confirmation-upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response.status == HttpStatusCode.Ok;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
    return false;
  }
};

export const downloadOrderConfirmation = async (projectId: string | number) => {
  try {
    const response = await api.get(`/contracts/project/${projectId}/order-confirmation-download`, {
      responseType: 'blob'
    });
    previewFile(response.data);
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (error: any) {
    toastUtil('error', error.message);
  }
};
