import { Country } from '../store/questionnaire/questionnaire.types';
import { isValueMissing } from './general.utils';

export const permitTypeRequired = (value: Country) =>
  value !== Country.SWITZERLAND && value !== Country.LIECHTENSTEIN;

export const getHighlightClass = (
  value: unknown,
  isDataMissing: boolean,
  isValid?: boolean
): string => {
  const shouldHighlight =
    isDataMissing && (isValueMissing(value) || value === false || isValid === false);
  return shouldHighlight ? 'highlight' : '';
};
