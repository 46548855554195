import { useTranslation } from 'react-i18next';

import { getContractDocument } from '../../../store/contracts/contract.actions';

import Text from '../../Text/Text';
import ContractDocumentItem from './ContractDocumentItem/ContractDocumentItem';

import { parseFilename } from '../../../utils/general.utils';

interface ContractDocumentsListProps {
  documents?: string[];
  contractId?: string;
}

const ContractDocumentsList = ({ documents, contractId }: ContractDocumentsListProps) => {
  const { t } = useTranslation();

  const fetchContractDocument = async (path?: string) => {
    if (contractId && path) {
      const res = await getContractDocument(contractId, path);
      return res;
    }
    return null;
  };

  return (
    <>
      <Text>{t('admin.projectDetails.contracts.contractDocuments')}:</Text>
      <div className="contract-documents">
        {documents?.map((d, i) => (
          <ContractDocumentItem
            key={i}
            fileName={parseFilename(d)}
            onClick={() => fetchContractDocument(d)}
          />
        ))}
      </div>
    </>
  );
};

export default ContractDocumentsList;
