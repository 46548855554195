import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useModal } from '../../hooks/useModal';

import ContractSignatures from '../ContractSignatures/ContractSignatures';
import ContractFilesUpload from '../ContractFilesUpload/ContractFilesUpload';
import Dropdown, { DropdownType } from '../Dropdown/Dropdown';
import TextArea from '../Inputs/TextArea/TextArea';
import Text from '../Text/Text';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';

import { ContractCancellationReason, ContractUpdate, ContractSignatureType } from '../../generated';

import { formatDate } from '../../utils/formatting.utils';

import './ContractCancellation.scss';

interface ContractCancellationProps {
  isLoanCancellation?: boolean;
  onSubmit?: (data: ContractUpdate) => void;
}

const ContractCancellation = ({ isLoanCancellation, onSubmit }: ContractCancellationProps) => {
  const { t } = useTranslation();
  const { isOpened, toggle } = useModal();

  const [data, setData] = useState<ContractUpdate | null>();
  const [files, setFiles] = useState<File[]>([]);

  const cancellationReason = useMemo(() => {
    const reasons: DropdownType[] = [
      {
        value: ContractCancellationReason.InvesterraWithdrawal,
        displayValue: t(
          `admin.projectDetails.contracts.${ContractCancellationReason.InvesterraWithdrawal}`
        )
      }
    ];

    if (isLoanCancellation) {
      reasons.push({
        value: ContractCancellationReason.CustomerRejection,
        displayValue: t(
          `admin.projectDetails.contracts.${ContractCancellationReason.CustomerRejection}`
        )
      });
    } else {
      reasons.push({
        value: ContractCancellationReason.CustomerWithdrawal,
        displayValue: t(
          `admin.projectDetails.contracts.${ContractCancellationReason.CustomerWithdrawal}`
        )
      });
    }

    return reasons;
  }, [isLoanCancellation]);

  const showFileUploadAndSignaturesSection = !isLoanCancellation && data?.cancellation?.reason;

  const onSubmitHandler = () => {
    //TODO: HANDLE PROPERLY AFTER updateContract and contractCancellation IS IMPLEMENTED
    if (!data || !onSubmit) return;
    onSubmit(data);
    setData(null);
    toggle();
  };

  const changeCancellationReasonHandler = (reason: ContractCancellationReason) => {
    setData((prev) => ({
      ...prev,
      cancellation: {
        ...prev?.cancellation,
        reason: reason
      }
    }));
  };

  const changeSignatureDateHandler = (date: Date | null, key: ContractSignatureType) => {
    const formattedDate = formatDate(date);

    setData((prev) => {
      const existingSignature = prev?.signatures?.find((s) => s.type === key);

      if (existingSignature) {
        const updatedSignatures = prev?.signatures?.map((s) =>
          s.type === key ? { ...s, date: formattedDate } : s
        );
        return { ...prev, signatures: updatedSignatures };
      } else {
        return {
          ...prev,
          signatures: [...(prev?.signatures ?? []), { type: key, date: formattedDate }]
        };
      }
    });
  };

  const addCommentHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setData((prev) => ({ ...prev, comment: e.target.value }));
  };

  const closeModalHandler = () => {
    setData(null);
    toggle();
  };

  return (
    <div className="contract-cancelation-wrapper">
      <div className="contract-cancellation-reason">
        <Text>{t(`admin.projectDetails.contracts.cancellationReason`)}*</Text>
        <Dropdown
          values={cancellationReason}
          noValueText={t(`admin.projectDetails.contracts.selectCancellationReason`)}
          initialValue={{
            value: data?.cancellation?.reason ?? '',
            displayValue: t(`admin.projectDetails.contracts.${data?.cancellation?.reason}`)
          }}
          getValue={(value) => {
            changeCancellationReasonHandler(value as ContractCancellationReason);
          }}
        />
      </div>
      <TextArea
        name="comment"
        placeholder={t(`admin.projectDetails.contracts.adminComment`)}
        onChange={addCommentHandler}
      />
      {showFileUploadAndSignaturesSection && (
        <>
          <ContractFilesUpload files={files} setFiles={setFiles} />
          <ContractSignatures
            signatures={data?.signatures ?? []}
            setSignature={changeSignatureDateHandler}
          />
        </>
      )}
      <Button onClick={toggle} dataTest={''} disabled={!data?.cancellation?.reason}>
        {t(
          `admin.projectDetails.contracts.${
            isLoanCancellation ? 'withdrawFundingRequest' : 'withdrawContract'
          }`
        )}
      </Button>
      <Modal
        title={t('admin.projectDetails.contracts.cancelContractModal.title')}
        isOpened={isOpened}
        close={toggle}>
        <Text>{t('admin.projectDetails.contracts.cancelContractModal.description')}</Text>
        <div className={'cancel-contract-buttons'}>
          <Button onClick={onSubmitHandler} dataTest="">
            {t('admin.projectDetails.contracts.cancelContractModal.yes')}
          </Button>
          <Button onClick={closeModalHandler} variation="secondary" dataTest="">
            {isLoanCancellation
              ? t('admin.projectDetails.contracts.cancelContractModal.backToEdit')
              : t('admin.projectDetails.contracts.cancelContractModal.no')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ContractCancellation;
