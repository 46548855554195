import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useNavigation from '../../hooks/useNavigation';

import Checkbox from '../../components/Checkbox/Checkbox';
import PromptModal from '../../components/PromptModal/PromptModal';
import QuestionnaireActions from '../../components/QuestionnaireActions/QuestionnaireActions';
import Text from '../../components/Text/Text';
import Wizard from '../../components/Wizard/Wizard';

import FinancialSituation from './FinancialSituation/FinancialSituation';
import HousingSituation from './HousingSituation/HousingSituation';
import LifePartnerDetails from './LifePartnerDetails/LifePartnerDetails';
import LifePartnerFinancialSituation from './LifePartnerFinancialSituation/LifePartnerFinancialSituation';
import PersonalDetails from './PersonalDetails/PersonalDetails';

import { RootState } from '../../reducers/rootReducer';
import {
  getQuestionnaire,
  submitQuestionnaire,
  updateQuestionnaire
} from '../../store/questionnaire/questionnaire.actions';
import {
  Address,
  QuestionnaireType,
  QuestionnaireFormActionType,
  MaritalStatus,
  TransportType,
  HouseholdType
} from '../../store/questionnaire/questionnaire.types';

import { hasTruthyValues, isAtLeast18 } from '../../utils/general.utils';
import { getHighlightClass, permitTypeRequired } from '../../utils/questionnaire.utils';
import { toastUtil } from '../../utils/toast.utils';
import { phoneNumberValidation, validateIban } from '../../utils/validations.utils';

import './Questionnaire.scss';

enum QuestionnaireRenderProps {
  PERSONAL_DETAILS = 'personalDetails',
  HOUSING_SITUATION = 'housingSituation',
  FINANCIAL_SITUATION = 'financialSituation',
  LIFE_PARTNER = 'lifePartner',
  LIFE_PARTNER_FINANCIAL_SITUATION = 'lifePartnerFinancialSituation'
}

interface QuestionnaireSteps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  comp: React.ComponentType<any>;
  title: QuestionnaireRenderProps;
}

const Questionnaire = () => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();
  const { projectId } = useParams();

  const questionnaire = useSelector<RootState>(
    (state) => state.questionnaireReducer
  ) as QuestionnaireType;

  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState<number>(1);

  const [isDataMissing, setIsDataMissing] = useState<boolean>(false);

  const dateOfBirth = questionnaire.kycData.dateOfBirth
    ? new Date(questionnaire.kycData.dateOfBirth)
    : '';
  const iBAN = questionnaire.kycData.bankDetails?.iBAN ?? '';
  const phoneNumber = questionnaire.kycData.phoneNumber ?? '';
  const nationality = questionnaire.kycData.nationality ?? '';
  const maritalStatus = questionnaire.creditCheckData.applicant?.maritalStatus ?? '';
  const householdType = questionnaire.creditCheckData.household?.householdType ?? '';
  const transportationType =
    questionnaire.creditCheckData.applicant?.financialData?.transportationType ?? '';
  const householdPartnerTransportationType =
    questionnaire.creditCheckData.householdPartner.financialData?.transportationType ?? '';

  const householdPartnerRequired =
    householdType === HouseholdType.MARRIED_COUPLE ||
    householdType === HouseholdType.SINGLE_HOUSEHOLD_COMMUNITY ||
    householdType === HouseholdType.SINGLE_PARENT_HOUSEHOLD_COMMUNITY;

  const childrenInHouseholdRequired =
    householdType === HouseholdType.SINGLE_PARENT ||
    householdType === HouseholdType.SINGLE_PARENT_HOUSEHOLD_COMMUNITY;

  const transportationCostsRequired =
    transportationType === TransportType.CAR || transportationType === TransportType.PUBLIC;
  const householdPartnerTransportationCostsRequired =
    householdPartnerTransportationType === TransportType.CAR ||
    transportationType === TransportType.PUBLIC;

  const questionnaireSteps = useMemo(() => {
    const steps: QuestionnaireSteps[] = [
      {
        comp: PersonalDetails,
        title: QuestionnaireRenderProps.PERSONAL_DETAILS
      },
      {
        comp: FinancialSituation,
        title: QuestionnaireRenderProps.FINANCIAL_SITUATION
      },
      {
        comp: HousingSituation,
        title: QuestionnaireRenderProps.HOUSING_SITUATION
      }
    ];
    if (householdPartnerRequired)
      steps.push(
        {
          comp: LifePartnerDetails,
          title: QuestionnaireRenderProps.LIFE_PARTNER
        },
        {
          comp: LifePartnerFinancialSituation,
          title: QuestionnaireRenderProps.LIFE_PARTNER_FINANCIAL_SITUATION
        }
      );

    return steps;
  }, [questionnaire]);

  const lastStep = activeStep === questionnaireSteps.length;

  useEffect(() => {
    const fetchQuestionnaire = async () => {
      if (!projectId) return;
      const res = await getQuestionnaire(projectId);
      res &&
        dispatch({
          type: QuestionnaireFormActionType.REPLACE_STATE,
          payload: res
        });
    };

    dispatch({
      type: QuestionnaireFormActionType.SET_PROJECT_ID,
      payload: projectId
    });

    fetchQuestionnaire();
  }, []);

  const questionnairePayload = (data: Partial<QuestionnaireType>) => {
    const payload = JSON.parse(JSON.stringify(data));

    if (!householdPartnerRequired) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { householdPartner, ...rest } = payload.creditCheckData;
      payload.creditCheckData = rest;
    }

    return payload;
  };

  useEffect(() => {
    if (
      (maritalStatus === MaritalStatus.MARRIED || maritalStatus === MaritalStatus.REGISTERED) &&
      householdType === HouseholdType.MARRIED_COUPLE
    ) {
      dispatch({
        type: QuestionnaireFormActionType.CHANGE_PARTNER_MARITAL_STATUS,
        payload: maritalStatus
      });
    }
  }, [householdType]);

  const onSave = (data: Partial<QuestionnaireType>) => {
    projectId && updateQuestionnaire(projectId, questionnairePayload(data));
  };

  const onNextStep = async () => {
    if (lastStep && !hasTruthyValues(completeQuestionnaireData)) {
      setIsDataMissing(true);
      toastUtil('error', t('creditCheck.allFieldsRequired'), { autoClose: false });
      return;
    }

    if (lastStep && projectId && hasTruthyValues(completeQuestionnaireData)) {
      const resp = await submitQuestionnaire(
        projectId,
        questionnairePayload(questionnaire) as QuestionnaireType
      );
      if (resp) {
        return goTo(`/kyc-start/${projectId}`, { state: { hideCrumbs: true } });
      }
    }

    window.scrollTo(0, 0);
    !lastStep && setActiveStep((prev) => ++prev);
  };

  const onBackHandler = () => {
    window.scrollTo(0, 0);
    if (activeStep === 1) {
      goTo('/my-profile/projects');
      return;
    }
    setActiveStep((prev) => --prev);
  };

  const isSaveDisabled = () => {
    const validations = [
      { value: iBAN, isInvalid: iBAN && !!validateIban(iBAN) },
      { value: phoneNumber, isInvalid: phoneNumber && !!phoneNumberValidation(phoneNumber) },
      { value: dateOfBirth, isInvalid: dateOfBirth && !isAtLeast18(dateOfBirth) }
    ];

    return validations.some(({ value, isInvalid }) => value && isInvalid);
  };

  const completeQuestionnaireData = {
    [QuestionnaireRenderProps.PERSONAL_DETAILS]: {
      gender: questionnaire.kycData?.gender,
      dateOfBirth: dateOfBirth && isAtLeast18(dateOfBirth),
      placeOfBirth: questionnaire.kycData?.placeOfBirth,
      nationality: questionnaire.kycData?.nationality,
      maritalStatus: questionnaire?.creditCheckData?.applicant?.maritalStatus,
      address: questionnaire.kycData?.address as Address,
      phoneNumber: !phoneNumberValidation(phoneNumber),
      ...(permitTypeRequired(nationality) && {
        permitType: questionnaire.creditCheckData.applicant?.permitType
      })
    },
    [QuestionnaireRenderProps.HOUSING_SITUATION]: {
      householdType: questionnaire.creditCheckData?.household?.householdType,
      residingSinceYear: questionnaire.creditCheckData?.applicant?.residingSinceYear,
      residingSinceMonth: questionnaire.creditCheckData?.applicant?.residingSinceMonth,
      residenceType: questionnaire.creditCheckData?.applicant?.residenceType,
      householdMonthlyInsuranceFee:
        questionnaire.creditCheckData.household?.householdMonthlyInsuranceFee,
      ...(householdType === HouseholdType.SINGLE_SUPPORTING_GROWNUP_IN_SAME_HOUSEHOLD && {
        householdNumberOfSupportedGrownUps:
          questionnaire.creditCheckData?.household?.householdNumberOfSupportedGrownUps
      }),
      ...(!householdPartnerRequired && {
        beneficialOwner: questionnaire.creditCheckData.applicant?.beneficialOwner
      }),
      ...((childrenInHouseholdRequired ||
        !!questionnaire.creditCheckData?.household?.childrenInHouseholdAge.length) && {
        childrenInHouseholdAge: questionnaire.creditCheckData.household?.childrenInHouseholdAge
      })
    },
    [QuestionnaireRenderProps.FINANCIAL_SITUATION]: {
      bankDetails: {
        ...questionnaire.kycData.bankDetails,
        iBAN: iBAN && !validateIban(iBAN)
      },
      employmentStatus: questionnaire.creditCheckData.applicant?.employmentStatus,
      monthlyIncomeGrossAmountMain:
        questionnaire.creditCheckData?.applicant?.financialData?.monthlyIncomeGrossAmountMain,
      monthlyEducationCosts:
        questionnaire.creditCheckData?.applicant?.financialData?.monthlyEducationCosts,
      workingDaysPerMonth:
        questionnaire.creditCheckData?.applicant?.financialData?.workingDaysPerMonth,
      monthlyExpensesAlimony:
        questionnaire.creditCheckData.applicant?.financialData?.monthlyExpensesAlimony,
      monthlyOneTimeExpenses:
        questionnaire.creditCheckData.applicant?.financialData?.monthlyOneTimeExpenses,
      monthlyOtherFixExpenses:
        questionnaire.creditCheckData.applicant?.financialData?.monthlyOtherFixExpenses,
      transportationType:
        questionnaire.creditCheckData?.applicant?.financialData?.transportationType,
      ...(transportationCostsRequired && {
        monthlyTransportationCosts:
          questionnaire.creditCheckData.applicant.financialData?.monthlyTransportationCosts
      })
    },
    ...(householdPartnerRequired && {
      [QuestionnaireRenderProps.LIFE_PARTNER]: {
        dateOfBirth: !!questionnaire.creditCheckData.householdPartner?.dateOfBirth,
        address: questionnaire.creditCheckData.householdPartner?.address,
        maritalStatus: questionnaire?.creditCheckData?.householdPartner?.maritalStatus
      },
      [QuestionnaireRenderProps.LIFE_PARTNER_FINANCIAL_SITUATION]: {
        employmentStatus: questionnaire.creditCheckData.householdPartner?.employmentStatus,
        monthlyIncomeGrossAmountMain:
          questionnaire.creditCheckData.householdPartner?.financialData
            ?.monthlyIncomeGrossAmountMain,
        workingDaysPerMonth:
          questionnaire.creditCheckData.householdPartner?.financialData?.workingDaysPerMonth,
        monthlyHousingCost:
          questionnaire.creditCheckData.householdPartner?.financialData?.monthlyHousingCost,
        monthlyEducationCosts:
          questionnaire.creditCheckData?.householdPartner?.financialData?.monthlyEducationCosts,
        monthlyExpensesAlimony:
          questionnaire.creditCheckData.householdPartner?.financialData?.monthlyExpensesAlimony,
        monthlyOneTimeExpenses:
          questionnaire.creditCheckData.householdPartner?.financialData?.monthlyOneTimeExpenses,
        monthlyOtherFixExpenses:
          questionnaire.creditCheckData.householdPartner?.financialData?.monthlyOtherFixExpenses,
        transportType:
          questionnaire.creditCheckData.householdPartner?.financialData?.transportationType,
        ...(householdPartnerRequired && {
          beneficialOwner: questionnaire.creditCheckData.applicant?.beneficialOwner
        }),
        ...(householdPartnerTransportationCostsRequired
          ? {
              monthlyTransportationCosts:
                questionnaire.creditCheckData.householdPartner?.financialData
                  ?.monthlyTransportationCosts
            }
          : undefined)
      }
    })
  };

  const completedSteps = (step: QuestionnaireSteps) => {
    let data;
    switch (step.title) {
      case QuestionnaireRenderProps.PERSONAL_DETAILS:
        data = completeQuestionnaireData[QuestionnaireRenderProps.PERSONAL_DETAILS];
        break;
      case QuestionnaireRenderProps.HOUSING_SITUATION:
        data = completeQuestionnaireData[QuestionnaireRenderProps.HOUSING_SITUATION];
        break;
      case QuestionnaireRenderProps.FINANCIAL_SITUATION:
        data = completeQuestionnaireData[QuestionnaireRenderProps.FINANCIAL_SITUATION];
        break;
      case QuestionnaireRenderProps.LIFE_PARTNER:
        data = completeQuestionnaireData[QuestionnaireRenderProps.LIFE_PARTNER];
        break;
      case QuestionnaireRenderProps.LIFE_PARTNER_FINANCIAL_SITUATION:
        data = completeQuestionnaireData[QuestionnaireRenderProps.LIFE_PARTNER_FINANCIAL_SITUATION];
        break;
      default:
        data = undefined;
    }
    return data ? hasTruthyValues(data) : false;
  };

  const generateQuestionnaireSteps = () =>
    questionnaireSteps.map((elem) => {
      return {
        label: t(`creditCheck.${elem.title}.title`),
        completed: completedSteps(elem)
      };
    });

  return (
    <div className="credit-check-wrapper">
      <Text as="h1" category="display">
        {t(`creditCheck.${questionnaireSteps[activeStep - 1].title}.title`)}
      </Text>
      <Wizard
        steps={generateQuestionnaireSteps()}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
      <div className="credit-check-form-wrapper">
        {questionnaireSteps.map((elem, index) => {
          return (
            ++index === activeStep && (
              <elem.comp key={index} onSave={onSave} isDataMissing={isDataMissing} />
            )
          );
        })}
        {lastStep && (
          <div className="beneficial-owner-wrapper">
            <Text as="label" category="headline">
              {t('creditCheck.financialSituation.correctnessOfInformation')}
            </Text>
            <Checkbox
              checked={questionnaire?.creditCheckData?.applicant?.beneficialOwner || false}
              onClick={() => {
                dispatch({
                  type: QuestionnaireFormActionType.CHANGE_BENEFICIAL_OWNER,
                  payload: !questionnaire.creditCheckData.applicant?.beneficialOwner
                });
              }}
              className={getHighlightClass(
                questionnaire.creditCheckData.applicant.beneficialOwner,
                isDataMissing
              )}
              label={t('creditCheck.financialSituation.confirmInformationIsCorrect')}
            />
            <Text size="medium">{t('creditCheck.financialSituation.confirmationText')}</Text>
            <Text size="medium">{t('creditCheck.financialSituation.beneficialOwnerText')}</Text>
            <Text size="medium">{t('creditCheck.financialSituation.correctInfoConfirmation')}</Text>
          </div>
        )}
        <QuestionnaireActions
          saveDisabled={isSaveDisabled()}
          lastStep={lastStep}
          firstStep={activeStep === 1}
          onBack={onBackHandler}
          onNextStep={onNextStep}
          onSave={() => {
            onSave(questionnaire);
          }}
        />
      </div>
      <PromptModal
        onConfirm={() => {
          onSave(questionnaire);
        }}
        ignoreRoutes={['questionnaire', 'kyc-start']}
      />
    </div>
  );
};

export default Questionnaire;
