import { useTranslation } from 'react-i18next';
import './CreditTerms.scss';
import { useEffect, useState } from 'react';
import { CreditTerm } from '../../../../store/creditTerms/creditTerms.types';
import {
  getAllCreditTerms,
  updateCreditTerms
} from '../../../../store/creditTerms/creditTerms.action';
import Text from '../../../../components/Text/Text';
import CurrencyLinkedValue from '../../../../components/Inputs/CurrencyLinkedValue/CurrencyLinkedValue';
import Button from '../../../../components/Button/Button';
import TableHead from '../../../../components/Tables/TableHead/TableHead';

const CreditTerms = () => {
  const { t } = useTranslation();

  const tableHeading = [t('value'), t('offers.description'), t('admin.projects.labels.lastChange')];

  const [creditTerms, setCreditTerms] = useState<CreditTerm[] | undefined>(undefined);

  useEffect(() => {
    getCreditTerms();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    setCreditTerms((prev) => {
      return prev?.map((elem: CreditTerm) => {
        if (name === elem.name) {
          return { ...elem, value: value };
        }
        return elem;
      });
    });
  };

  const getCreditTerms = async () => {
    const data = await getAllCreditTerms();
    data && setCreditTerms(data);
  };

  const update = async () => {
    const modifiedCreditTerms = creditTerms?.map((item: Pick<CreditTerm, 'id' | 'value'>) => ({
      id: item.id,
      value: item.value
    }));
    const resp = await updateCreditTerms(modifiedCreditTerms as Pick<CreditTerm, 'id' | 'value'>[]);
    resp && getCreditTerms();
  };

  return (
    <div className="table-wrapper">
      {creditTerms && (
        <TableHead columnWidths={[20, 65, 25]} values={tableHeading}>
          {creditTerms.map((elem: CreditTerm, index) => (
            <tr key={index} className="table-row-body">
              <Text as="td" category="label" size="large" className="table-data">
                {t(`creditTerms.${elem.name.toLowerCase()}.heading`)}
              </Text>
              <Text as="td" category="label" size="large" className="table-data">
                {t(`creditTerms.${elem.name.toLowerCase()}.description`, '')}
              </Text>
              <td>
                <CurrencyLinkedValue
                  currency={elem.unit}
                  name={elem.name}
                  onChange={handleChange}
                  value={elem.value || ''}
                  currencyPosition="left"
                />
              </td>
            </tr>
          ))}
        </TableHead>
      )}
      <Button
        variation="primary"
        size="large"
        dataTest=""
        disabled={creditTerms
          ?.map((elem: CreditTerm) => elem.value)
          .some((elem: string) => elem === '')}
        onClick={update}
        className="credit-terms-button">
        {t('saveChanges')}
      </Button>
    </div>
  );
};

export default CreditTerms;
