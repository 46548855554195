import { useContext, useMemo, useState } from 'react';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
import './Navigation.scss';
import Link from '../Link/Link';
import Icon from '../Icon/Icon';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../store/user/actions';
import { RootState } from '../../reducers/rootReducer';
import { User } from '../../store/user/types';
import { UserRoles } from '../../store/user/types';
import Text from '../Text/Text';
import { AuthContext } from '../../context/AuthContextProvider';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { navigateLink } from '../../utils/navigate';
import { hubspotLinks } from '../../utils/hubspotLinks';

interface NavLink {
  key: string;
  to: string;
  reload?: boolean;
}

const Navigation = () => {
  const { t, i18n } = useTranslation();

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const auth = useContext(AuthContext);

  const [open, setOpen] = useState(false);

  const userReducer = useSelector<RootState>((state) => state.userReducer) as User;

  const language = i18n.language;

  const navigationLinks = useMemo(() => {
    const navLinks: NavLink[] =
      pathname === '/'
        ? []
        : [
            // { key: 'calculateOffer', to: navigateLink('/solar-system') },
            { key: 'submitOffer', to: navigateLink('/upload-offer') }
          ];

    if (auth?.isAuthenticated)
      navLinks.push({ key: 'my_profile', to: navigateLink('/my-profile') });

    if (
      userReducer.role?.includes(UserRoles.ADMIN) ||
      userReducer.role?.includes(UserRoles.PARTNER)
    ) {
      return [];
    }

    return navLinks;
  }, [userReducer, pathname, language]);

  const getNavigationClassName = () => `navigation ${open ? 'open' : ''}`;

  const logoutLoggedUser = () => {
    auth?.keycloak
      .logout({
        redirectUri:
          process.env.REACT_APP_SHOW_HOMEPAGE?.toString() == 'show'
            ? window.location.origin
            : hubspotLinks.investerra
      })
      .then(() => {
        dispatch(logoutUser());
      });
  };

  return (
    <>
      <nav className={getNavigationClassName()}>
        <ul className="navigation-list">
          {navigationLinks.map((link: NavLink, index: number) => (
            <li key={index}>
              <Link
                to={link.to}
                variation="primary"
                dataTest={`homepage-${link.key}-link`}
                reload={link.reload}
                onClick={() => {
                  setOpen(false);
                }}>
                {t(`header.navigation.${link.key}`)}
              </Link>
            </li>
          ))}
          {userReducer.firstName && (
            <li className="logged-user-name">
              <Text
                as="span"
                category="body"
                size="medium">{`${userReducer.firstName} ${userReducer.lastName}`}</Text>
              <Icon name="person" />
            </li>
          )}
          <LanguageSelector />
          {auth?.isAuthenticated ? (
            <li>
              <Button
                variation="secondary"
                size="medium"
                onClick={logoutLoggedUser}
                className="logout-button"
                dataTest="logout-button">
                {t('auth.signout')}
              </Button>
            </li>
          ) : (
            <li>
              <Button
                variation="secondary"
                size="medium"
                className="login-button"
                onClick={() => {
                  auth?.keycloak.register({ locale: language });
                }}
                dataTest="homepage-signup-login-button">
                {`${t('auth.register')} / ${t('auth.login')}`}
              </Button>
            </li>
          )}
        </ul>
        <Icon
          name="burger-menu"
          className="burger-icon"
          onClick={() => {
            setOpen(true);
          }}
        />
        <Icon
          name="close"
          className="close-icon"
          onClick={() => {
            setOpen(false);
          }}
        />
      </nav>
    </>
  );
};

export default Navigation;
