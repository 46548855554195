import { Profile } from '../store/profile/profile.types';
import { toastUtil } from './toast.utils';
import { DropdownType } from '../components/Dropdown/Dropdown';
import { t } from 'i18next';
import axios from 'axios';
import { User, UserRoles } from '../store/user/types';
import { SwissCanton } from '../types/util.types';
import { NplProjectState, ProjectStatus } from '../store/project/project.types';
import { Address } from '../store/questionnaire/questionnaire.types';

export const SHOW_QUESTIONNAIRE =
  process.env.REACT_APP_FEATURE_FLAG_QUESTIONNAIRE_KYC_KKG?.toString() === 'show';
export const SHOW_NEW_UPLOAD_OFFER =
  process.env.REACT_APP_FEATURE_FLAG_INSTALLATION_OFFER?.toString() === 'show';
export const SHOW_CONTRACTS = process.env.REACT_APP_FEATURE_FLAG_CONTRACTS?.toString() === 'show';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const emptyStringIfNull = (value: any): string => {
  if (typeof value === 'number' && isNaN(value)) return '';
  return value === null || value === undefined ? '' : value;
};

export const parseFullName = (value: string): Pick<Profile, 'firstName' | 'lastName'> => {
  const arr = value.trim().split(/\s+/);

  return {
    firstName: arr[0],
    lastName: arr.slice(1).join(' ')
  };
};

export const parseName = (firstName?: string, lastName?: string): string => {
  return [firstName, lastName].join(' ').trim();
};

export const parseFilename = (value?: string): string | undefined => {
  if (value) return value.split('/').pop();
};

export const downloadFile = async (file: Blob, fileName: string) => {
  const blobUrl = URL.createObjectURL(new Blob([file], { type: 'application/octet-stream' }));
  const downloadLink = document.createElement('a');
  downloadLink.href = blobUrl;
  downloadLink.download = `${fileName}.pdf`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
};

export const previewFile = async (file: Blob) => {
  const blob = new Blob([file], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(blob);
  window.open(fileURL, '_blank');
};

export const copyToClipboard = (value: string) => {
  navigator.clipboard.writeText(value);
  toastUtil('success', 'In die Zwischenablage kopiert');
};

export const dialNumber = (phoneNumber: string) => {
  phoneNumber = `tel:${phoneNumber}`;
  window.location.href = phoneNumber;
};

export const getInitials = (firstName: string, lastName: string) => {
  return `${firstName.charAt(0)}${lastName.charAt(0)}`;
};

export const getCityAndCanton = async (postCode: string | number) => {
  try {
    const { data } = await axios.get(
      `https://api3.geo.admin.ch/rest/services/api/SearchServer?layers=ch.swisstopo-vd.ortschaftenverzeichnis_plz&returnGeometry=false&type=locations&searchText=${postCode}&origins=zipcode,address`
    );
    const municipality = data.results
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .find((elem: any) => elem.attrs.origin === 'zipcode')
      .attrs.label.replace(/<[^>]*>/g, '')
      .split('-')[1]
      .trim();

    const canton: SwissCanton = data.results
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .find((elem: any) => {
        return elem.attrs.origin === 'address' && elem.attrs.label.includes(municipality);
      })
      .attrs.detail.slice(-2)
      .toUpperCase();

    return { municipality, canton };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', t('genericErrors.entereValidPostcode'));
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasTruthyValues = (obj: any): boolean => {
  if (typeof obj === 'object' && obj !== null) {
    const values = Object.values(obj);
    if (values.length === 0) return false;
    return values.every((value) => {
      if (value === 0) return true;
      return Array.isArray(value)
        ? value.length > 0 && value.every(hasTruthyValues)
        : hasTruthyValues(value);
    });
  }
  return Boolean(obj);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const generateDropdownValues = (values: any, translationKey: string): DropdownType[] => {
  const elements = Array.isArray(values) ? values : Object.values(values);

  return elements.map((elem) => ({
    value: `${elem}`,
    displayValue: t(`${translationKey}.${elem}`)
  }));
};

export const userCreation = (data: Profile): User => {
  const { user, profile } = data;
  return {
    uid: user.id,
    id: profile.id,
    firstName: user.firstName as string,
    lastName: user.lastName as string,
    address: profile.address || {},
    kycStatus: profile.kycStatus,
    email: user.email as string,
    phoneNumber: profile?.phoneNumber as string,
    partnerId: profile.partnerId,
    bankName: profile.bankName,
    iBAN: profile.iBAN
  };
};

export const titleCaseFormat = (value: string): string => {
  return value
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const getInitialsFromFullName = (fullName: string) => {
  return fullName
    .split(' ')
    .map((word) => word.charAt(0))
    .join('');
};

export const formatFilePath = (filePath: string) => {
  // eslint-disable-next-line
  return filePath.split('/').pop()!;
};

export const getValueOrPlaceholder = (elem: string | number | undefined, defaultValue = '-') =>
  !!elem || elem === 0 ? elem : defaultValue;

export const generateYearArray = (endYear: number, startYear?: number) => {
  const currentYear = startYear || new Date().getFullYear();
  return Array.from({ length: currentYear - endYear + 1 }, (_, i) => (currentYear - i).toString());
};

export const isAtLeast18 = (dateOfBirth: Date): boolean => {
  const today = new Date();
  let age = today.getFullYear() - dateOfBirth.getFullYear();
  const monthDifference = today.getMonth() - dateOfBirth.getMonth();

  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dateOfBirth.getDate())) {
    age--;
  }

  return age >= 18;
};

export const getCorrectProjectStatus = (
  javaProjectStatus: ProjectStatus,
  kotlinProjectStatus: NplProjectState
) => {
  if (
    javaProjectStatus === ProjectStatus.OFFER_REQUESTED ||
    javaProjectStatus === ProjectStatus.OFFER_RECEIVED
  ) {
    return javaProjectStatus;
  } else {
    return kotlinProjectStatus;
  }
};

export const isValueMissing = (value: unknown) => {
  if (value == null || value === '') return true;
  if (Array.isArray(value)) return value.length === 0;
  if (typeof value === 'object') {
    return Object.values(value).some((val) => val == null || val === '');
  }

  return false;
};

export const isCompleteObject = <T>(obj: Partial<T> | undefined, keys: (keyof T)[]): boolean => {
  if (!obj) return false;

  return keys.every((key) => !!obj[key]);
};

export const addressKeys: (keyof Address)[] = [
  'street',
  'houseNumber',
  'postalCode',
  'municipality',
  'canton',
  'country'
];

export const isAddressComplete = (address: Address | undefined) => {
  if (!address) return false;
  if (!isCompleteObject(address, addressKeys)) return false;

  return Object.values(address).every((value) => value && value.toString().trim() !== '');
};

export const isBackOfficeUser = (user: User) => {
  const backofficeRoles = [UserRoles.ADMIN, UserRoles.REVIEWER, UserRoles.SUPER_ADMIN];

  return backofficeRoles.some((r) => user.role?.includes(r));
};
