import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Breadcrumbs.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../Text/Text';
import Button from '../Button/Button';
import backIcon from './../../assets/Icons/arrow-back.svg';
import i18n from '../../i18n/i18n';

const Breadcrumbs = () => {
  const { t } = useTranslation();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const language = i18n.language;
  const [crumbs, setCrumbs] = useState(['']);

  useEffect(() => {
    const crumbsWithoutRole = pathname
      .split('/')
      .filter((elem) => elem !== '' && elem !== 'partners' && elem !== 'admin');
    setCrumbs(crumbsWithoutRole);
  }, [pathname]);

  const defineUrlPath = (position: number) => {
    return crumbs
      .slice(0, position + 1)
      .toString()
      .replaceAll(',', '/');
  };

  const hideCrumbs =
    crumbs.length === 1 ||
    crumbs[1] === 'not-found' ||
    crumbs[1] === 'register' ||
    crumbs[1] === 'questionnaire' ||
    crumbs[1] === 'kyc' ||
    state?.hideCrumbs;

  const showBackButton =
    t(`breadCrumbs.${crumbs.slice(-1)}`) === '' ||
    t(`breadCrumbs.${crumbs.slice(-1)}`) === `breadCrumbs.${crumbs.slice(-1)}`;

  const skipLinkInCrumbs = (link: string) => {
    return (
      link === language ||
      !t(`breadCrumbs.${link}`) ||
      t(`breadCrumbs.${link}`) === `breadCrumbs.${link}`
    );
  };

  return (
    <>
      {!!crumbs.length && !hideCrumbs && (
        <nav className="breadcrumbs">
          {showBackButton ? (
            <Button
              type="button"
              icon={backIcon}
              iconPosition="left"
              variation="transparent"
              dataTest=""
              size="medium"
              onClick={() => {
                navigate(-1);
              }}>
              {t('back')}
            </Button>
          ) : (
            <>
              <Link to={`${language}`}>
                <Text as="span" category="label" size="large" variety={1}>
                  {t(`breadCrumbs.home`)}
                </Text>
              </Link>
              {crumbs.map((link: string, index: number) => {
                if (skipLinkInCrumbs(link)) return;
                return (
                  <Link to={defineUrlPath(index)} key={index}>
                    <Text as="span" category="label" size="large" variety={1}>
                      {t(`breadCrumbs.${link}`)}
                    </Text>
                  </Link>
                );
              })}
            </>
          )}
        </nav>
      )}
    </>
  );
};

export default Breadcrumbs;
