import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Text from '../Text/Text';
import ContractStatusField from '../ContractStatusField/ContractStatusField';
import ContractEventsLog from './ContractEventsLog/ContractEventsLog';
import ContractDocumentsList from './ContractDocumentsList/ContractDocumentsList';
import ContractCancellation from '../ContractCancellation/ContractCancellation';
import Snackbar from '../Snackbar/Snackbar';
import Button from '../Button/Button';

import { ContractGet, ContractStatus, ContractUpdate } from '../../generated';
import { RootState } from '../../reducers/rootReducer';
import { getContract } from '../../store/contracts/contract.actions';
import { User } from '../../store/user/types';

import { isBackOfficeUser } from '../../utils/general.utils';
import { formatDateWithDot } from '../../utils/formatting.utils';

import './ContractDetails.scss';

const ContractDetails = () => {
  const { t } = useTranslation();
  const { contractId } = useParams();
  const location = useLocation();

  const user = useSelector<RootState, User>((state) => state.userReducer);

  const [contract, setContract] = useState<ContractGet>();

  const [showSnackbar, setShowSnackbar] = useState<'CREATED' | 'CANCELED'>(location.state);

  const allowContractCancelation =
    contract?.status === ContractStatus.ContractSentToCustomer ||
    contract?.status === ContractStatus.ContractSignedRightOfWithdrawalActive;

  useEffect(() => {
    fetchContract();
  }, [contractId]);

  const fetchContract = async () => {
    if (contractId) {
      const res = await getContract(contractId);
      res && setContract(res);
    }
  };

  const cancelContractHandler = (data: ContractUpdate) => {
    //TODO: HANDLE PROPERLY AFTER updateContract and contractCancellation IS IMPLEMENTED && REMOVE console.info
    console.info(data);
    setShowSnackbar('CANCELED');
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="contract-details-wrapper">
        <div className="contract-details-header">
          <Text as="h2" category="display">
            {t('admin.projectDetails.contracts.LOAN')}
          </Text>
          <Button size="large" variation="primary" dataTest="edit-contract">
            {t(`admin.projectDetails.contracts.editContract`)}
          </Button>
        </div>
        {showSnackbar && (
          <Snackbar
            type={'success'}
            text={t(`admin.projectDetails.contracts.${showSnackbar.toLowerCase()}`)}
          />
        )}
        <div className="contract-details">
          <div className="contract-details-info">
            <Text as="h3" category="headline" size="large">
              {t(`admin.projectDetails.contracts.${contract?.type}`)}
            </Text>
            <ContractStatusField />
          </div>
          <div className="contract-details-main">
            <Text>{t('admin.projectDetails.contracts.contractType')}:</Text>
            <Text>{t(`admin.projectDetails.contracts.${contract?.type}`)}</Text>
            <Text>{t('admin.projectDetails.contracts.expirationDate')}:</Text>
            <Text>{formatDateWithDot(contract?.expirationDate)}</Text>
            <ContractDocumentsList documents={contract?.documents} contractId={contract?.id} />
            {isBackOfficeUser(user) && (
              <>
                <Text>{t('admin.projectDetails.contracts.internalComment')}:</Text>
                <Text dataTestId="admin-contract-comment">{contract?.comment}</Text>
              </>
            )}
          </div>
          <ContractEventsLog events={contract?.events} />
        </div>
      </div>
      {allowContractCancelation && (
        <>
          <Text as="h3" category="headline" size="large">
            {t(`admin.projectDetails.contracts.cancelContract`)}
          </Text>
          <ContractCancellation onSubmit={cancelContractHandler} />
        </>
      )}
    </>
  );
};

export default ContractDetails;
