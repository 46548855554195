import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Text from '../../../components/Text/Text';
import NumberInput from '../../../components/Inputs/NumberInput/NumberInput';
import Dropdown, { DropdownType } from '../../../components/Dropdown/Dropdown';

import { RootState } from '../../../reducers/rootReducer';

import {
  QuestionnaireFormActionType,
  HouseholdType,
  HousingReference,
  QuestionnaireType,
  MaritalStatus,
  QuestionnaireFormProps
} from '../../../store/questionnaire/questionnaire.types';
import { Month } from '../../../types/util.types';
import { generateDropdownValues, generateYearArray } from '../../../utils/general.utils';
import { validatePositiveNumber } from '../../../utils/validations.utils';
import { getHighlightClass } from '../../../utils/questionnaire.utils';

import '../Questionnaire.scss';

const HousingSituation = ({ isDataMissing }: QuestionnaireFormProps) => {
  const { t } = useTranslation();
  const questionnaire = useSelector<RootState>(
    (state) => state.questionnaireReducer
  ) as QuestionnaireType;
  const dispatch = useDispatch();

  const [numberOfChildren, setNumberOfChildren] = useState<number | undefined>(
    questionnaire.creditCheckData?.household?.childrenInHouseholdAge.length
  );

  const residingSinceMaxYear = questionnaire.kycData?.dateOfBirth
    ? new Date(questionnaire.kycData?.dateOfBirth).getFullYear()
    : 1950;
  const months = generateDropdownValues(Month, 'months');
  const years: DropdownType[] = generateYearArray(residingSinceMaxYear).map((year) => ({
    value: year,
    displayValue: year
  }));
  const housingReferences = generateDropdownValues(HousingReference, 'creditCheck.residenceType');
  const residingSinceYear = questionnaire.creditCheckData?.applicant?.residingSinceYear ?? '';
  const residingSinceMonth = questionnaire.creditCheckData?.applicant?.residingSinceMonth ?? '';
  const residenceType = questionnaire.creditCheckData.applicant?.residenceType ?? '';
  const numberOfSupportingGrownups =
    questionnaire.creditCheckData?.household.householdNumberOfSupportedGrownUps ?? '';
  const householdType = questionnaire.creditCheckData.household?.householdType ?? '';
  const childrenAge = questionnaire.creditCheckData.household?.childrenInHouseholdAge ?? [];
  const householdMonthlyInsuranceFee =
    questionnaire.creditCheckData?.household?.householdMonthlyInsuranceFee ?? '';

  const showMarriedCoupleHouseholdType =
    questionnaire.creditCheckData.applicant?.maritalStatus === MaritalStatus.MARRIED ||
    questionnaire.creditCheckData.applicant?.maritalStatus === MaritalStatus.REGISTERED;

  const singleSupportingGrownup =
    householdType === HouseholdType.SINGLE_SUPPORTING_GROWNUP_IN_SAME_HOUSEHOLD;

  useEffect(() => {
    if (!numberOfChildren) {
      dispatch({
        type: QuestionnaireFormActionType.CHANGE_CHILD_AGE,
        payload: []
      });
    }
  }, [numberOfChildren]);

  const residingSinceMonthInitialValue = (): DropdownType => {
    if (months[+residingSinceMonth - 1]) {
      return {
        value: months[+residingSinceMonth - 1].value,
        displayValue: months[+residingSinceMonth - 1].displayValue
      };
    } else {
      return {
        value: '',
        displayValue: ''
      };
    }
  };

  const householdValues = () => {
    if (showMarriedCoupleHouseholdType) {
      return generateDropdownValues(HouseholdType, 'creditCheck.householdType');
    } else {
      const modifiedValues = Object.values(HouseholdType).filter(
        (value) => value !== HouseholdType.MARRIED_COUPLE
      );
      return generateDropdownValues(modifiedValues, 'creditCheck.householdType');
    }
  };

  const changeChildAgeHandler = (age: number, elem: number) => {
    childrenAge[elem] = age;

    if (!age) childrenAge.splice(elem, 1);
    dispatch({
      type: QuestionnaireFormActionType.CHANGE_CHILD_AGE,
      payload: childrenAge
    });
  };

  const changeNumberOfSupportedGrownupsHandler = (num: number) => {
    if (num && (num <= 0 || num > 9)) return;
    dispatch({
      type: QuestionnaireFormActionType.CHANGE_HOUSEHOLD_MEMBERS,
      payload: num
    });
  };

  const changeMonthlyInsuranceFeeHandler = (fee: number) => {
    if (fee < 0) return;
    dispatch({
      type: QuestionnaireFormActionType.CHANGE_HOUSEHOLD_MONTHLY_INSURANCE_FEE,
      payload: fee
    });
  };

  const changeHouseholdTypeHandler = (value: string) => {
    dispatch({
      type: QuestionnaireFormActionType.CHANGE_HOUSEHOLD_TYPE,
      payload: value as HouseholdType
    });

    value !== HouseholdType.SINGLE_SUPPORTING_GROWNUP_IN_SAME_HOUSEHOLD &&
      numberOfSupportingGrownups &&
      dispatch({
        type: QuestionnaireFormActionType.REMOVE_HOUSEHOLD_MEMBERS
      });
  };

  return (
    <form className="credit-check-form">
      <div className="address-wrapper">
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.housingSituation.residingSince')}
        </Text>
        <div className="resident-wrapper">
          <Dropdown
            values={years}
            noValueText={t('year')}
            initialValue={{
              value: `${residingSinceYear}`,
              displayValue: `${residingSinceYear}`
            }}
            getValue={(value) => {
              dispatch({
                type: QuestionnaireFormActionType.CHANGE_RESIDING_SINCE_YEAR,
                payload: +value
              });
            }}
            required
            className={getHighlightClass(residingSinceYear, isDataMissing)}
          />
          <Dropdown
            values={months}
            noValueText={t('month')}
            initialValue={residingSinceMonthInitialValue()}
            getValue={(value) => {
              dispatch({
                type: QuestionnaireFormActionType.CHANGE_RESIDING_SINCE_MONTH,
                payload: months.findIndex((month) => month.value === value) + 1
              });
            }}
            required
            className={getHighlightClass(residingSinceMonth, isDataMissing)}
          />
        </div>
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.housingSituation.selectHousingReference')}
        </Text>
        <Dropdown
          values={housingReferences}
          noValueText={t('creditCheck.housingSituation.residenceTypePlaceholder')}
          initialValue={{
            value: residenceType,
            displayValue: t(`creditCheck.residenceType.${residenceType}`)
          }}
          getValue={(value) => {
            dispatch({
              type: QuestionnaireFormActionType.CHANGE_RESIDENCE_TYPE,
              payload: value as HousingReference
            });
          }}
          required
          className={getHighlightClass(residenceType, isDataMissing)}
        />
      </div>
      <div className="household-type-wrapper">
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.housingSituation.selectHouseholdType')}
        </Text>
        <Text as="span" category="body" size="large">
          {t('creditCheck.housingSituation.householdTypeText')}
        </Text>
        <Dropdown
          values={householdValues()}
          noValueText={t('creditCheck.housingSituation.householdPlaceholder')}
          initialValue={{
            value: householdType || '',
            displayValue: t(`creditCheck.householdType.${householdType}`)
          }}
          getValue={changeHouseholdTypeHandler}
          required
          className={getHighlightClass(householdType, isDataMissing)}
        />
      </div>
      {singleSupportingGrownup ? (
        <div className="household-member-wrapper">
          <Text as="span" category="headline" size="medium">
            {t('creditCheck.housingSituation.numberOfHouseholdMembers')}
          </Text>
          <Text size="large">{t('creditCheck.housingSituation.howManyAdultsInHousehold')}</Text>
          <NumberInput
            value={numberOfSupportingGrownups}
            name="householdMembers"
            placeholder={t('creditCheck.housingSituation.householdMembers')}
            validate={validatePositiveNumber}
            onChange={changeNumberOfSupportedGrownupsHandler}
            required
            className={getHighlightClass(numberOfSupportingGrownups, isDataMissing)}
          />
        </div>
      ) : (
        <div>
          <Text as="span" category="headline" size="medium">
            {t('creditCheck.housingSituation.howManyChildrenInHousehold')}
          </Text>
          <Dropdown
            values={Array.from({ length: 7 }, (_, index) => index).map((elem) => ({
              value: `${elem}`,
              displayValue: `${elem}`
            }))}
            noValueText={t('creditCheck.housingSituation.numberOfChildren')}
            initialValue={{
              value: numberOfChildren?.toString() || '',
              displayValue: numberOfChildren?.toString() as string
            }}
            getValue={(value) => {
              setNumberOfChildren(+value);
            }}
            required
          />
          {!!numberOfChildren &&
            Array.from({ length: numberOfChildren }, (_, index) => index).map((elem, i) => {
              return (
                <div key={i}>
                  <Text>
                    {t('creditCheck.housingSituation.childAge')} {elem + 1}
                  </Text>
                  <NumberInput
                    value={childrenAge[elem]}
                    maxValue={17}
                    name="childAge"
                    placeholder={t('creditCheck.housingSituation.childAge')}
                    validate={validatePositiveNumber}
                    onChange={(age) => {
                      changeChildAgeHandler(age, elem);
                    }}
                    className={getHighlightClass(childrenAge[elem], isDataMissing)}
                  />
                </div>
              );
            })}
        </div>
      )}
      <div>
        <Text as="h2" category="headline" size="medium">
          {t('creditCheck.housingSituation.householdInsuranceFee')}
        </Text>
        <Text as="span" category="body" size="large">
          {t('creditCheck.housingSituation.householdInsuranceFeeText')}
        </Text>
        <NumberInput
          value={householdMonthlyInsuranceFee}
          name="householdMonthlyInsuranceFee"
          placeholder={`${t('currency.chf')} / ${t('month')}`}
          validate={validatePositiveNumber}
          onChange={changeMonthlyInsuranceFeeHandler}
          required
          className={getHighlightClass(householdMonthlyInsuranceFee, isDataMissing)}
        />
      </div>
    </form>
  );
};
export default HousingSituation;
