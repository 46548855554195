import { FocusEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from '../../Text/Text';

import { toSwissNumberFormat } from '../../../utils/formatting.utils';

export interface NumberInputProps {
  name: string;
  value?: string | number;
  maxValue?: number;
  placeholder?: string;
  unit?: string;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  onChange?: (value: number) => void;
  onBlur?: (evt: FocusEvent<HTMLInputElement>) => void;
  validate?: (value: string) => string;
}

const NumberInput = ({
  value,
  maxValue,
  name,
  placeholder,
  unit,
  disabled,
  onChange,
  onBlur,
  validate = () => '',
  required,
  className = ''
}: NumberInputProps) => {
  const { t } = useTranslation();

  const [displayValue, setDisplayValue] = useState<string>('');
  const [validation, setValidation] = useState<string | undefined>();

  useEffect(() => {
    if (value || value === 0) {
      setDisplayValue(toSwissNumberFormat(value?.toString()));
    }
  }, []);

  const getClassName = () =>
    `input-container ${disabled ? 'disabled' : ''} ${validation ? 'error' : ''} ${className}`;

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const cleanedValue = parseFloat(value.replace(/'/g, ''));

    if (maxValue && cleanedValue > maxValue) {
      return;
    }

    setDisplayValue(toSwissNumberFormat(value));
    onChange && onChange(cleanedValue);
  };

  return (
    <div className={getClassName()}>
      <input
        className="input"
        placeholder=""
        id={name}
        name={name}
        value={displayValue}
        type="text"
        inputMode="decimal"
        onChange={onChangeHandler}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          validate && setValidation(validate(value as string));
          onBlur && onBlur(e);
        }}
        disabled={disabled}
        required={!!required}
      />
      {unit && (
        <Text as="p" category="label" size="large" className="unit">
          {unit}
        </Text>
      )}
      <Text
        as="label"
        category="label"
        size="large"
        variety={1}
        className="placeholder"
        htmlFor={name}>
        {placeholder}
        {required ? '*' : ''}
      </Text>
      {validation && (
        <Text as="p" category="body" size="small" className="error-text">
          {t(validation)}
        </Text>
      )}
    </div>
  );
};

export default NumberInput;
